import { PaymentLoadStatus } from './../../model/payments';
import { LoaderService } from "./../../model/loader/services/loader/loader.service";
import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ChangeDetectorRef
} from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../core/services/auth/auth.service";
import { Subject, Subscription } from "rxjs";
import { catchError, finalize, takeUntil } from "rxjs/operators";
import {
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration
} from "@azure/msal-angular";
import { environment } from "../../../environments/environment";
import { PropertyService } from "../../core/services/property/property.service";
import { HttpErrorResponse } from "@angular/common/http";
import { PaymentsService } from "../../core/services/payments/payments.service";
import { Property } from "../../model/property";
import { Payment } from "../../model/payments";
import { HttpService } from "../../http.service";
import { IdleService } from '../../core/services/idle/idle.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, OnDestroy {
  public title = "mro-portal";

  public isIframe = true;
  public properties!: Property[];
  public payments!: Payment[];
  public recentPayments!: Payment[];
  public isMock: boolean = environment.mock;

  public idleState = "NOT_STARTED";
  public countdown?: number = null;
  public lastPing?: Date = null;
  public resetRunning: boolean = false;
  public isLoggedIn: boolean = false;

  private readonly _destroying$ = new Subject<void>();
  private subscription: Subscription;
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private _auth: AuthService,
    private _router: Router,
    private propertyService: PropertyService,
    private paymentService: PaymentsService,
    private loaderService: LoaderService,
    private httpService: HttpService,
    private idleStateService: IdleService,
    private cdRef: ChangeDetectorRef,
    private jwtHelper: JwtHelperService
  ) {
    if (!this.jwtHelper.isTokenExpired(sessionStorage.getItem("token"))) {
      this.getIdleState();
      if (this.idleState === "NOT_STARTED") {
        this.startWatching();
      }
    }
    this.subscription = this._auth.token$.subscribe(token => {
      if (token) {
        if (sessionStorage.getItem("token")) {
          this.getIdleState();
          if (this.idleState === "NOT_STARTED") {
            this.startWatching();
          }
        }
      }
    });
  }

  ngOnInit(): void {
    if (environment.mock) {
      sessionStorage.setItem("mock", "true");
      this.isIframe = false;
    } else {
      // if (!sessionStorage.getItem("userData")) {
      //   this.clearMsalCache();
      // }
      if (sessionStorage.getItem("mock") === "true") {
        sessionStorage.clear();
      }
      this.isIframe = window !== window.parent && !window.opener;
      this.httpService.token$.subscribe((token) => {
        if (token) {
          this.initializeApp();
          if(token && this.jwtHelper.isTokenExpired(sessionStorage.getItem('token'))) {
            this.isLoggedIn = false;
          } else {
            this.isLoggedIn = true;
          }
        }
      });
    }

  }

  private startWatching() {
    this.idleStateService.startWatching();
  }

  private getIdleState() {
    this.idleStateService.idleState$.subscribe(state => {
      this.idleState = state;
    });
  }

  get displayData() {
    const display =
      this.isMock || this.properties != null || this.payments != null;
    return display;
  }

  public loggedIn() {
    const test = this._auth.loggedIn();
    return test;
  }

  private initializeApp() {
    this.getProperties();
    this.getPayments(3);
    this.getPayments(-1)
  }

  private getProperties() {
    this.loaderService.openLoader();
    this.propertyService
      .getMroProperties()
      .pipe(
        takeUntil(this._destroying$),
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              this._router.navigate(["/login"]);
            }
          }
          return [];
        }),
        finalize(() => {
          this.loaderService.closeLoader();
        })
      )
      .subscribe((data) => {
        this.properties = data;
        this.propertyService.setProperties(data);
      });
  }

  private getPayments(limit: number) {
    this.loaderService.openLoader();
    this.paymentService
      .getMroPayments(limit)
      .pipe(
        takeUntil(this._destroying$),
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              this._router.navigate(["/login"]);
            }
            if (error.status === 500) {
              this.paymentService.setPaymentLoadStatus(PaymentLoadStatus.FAILED);
            }
          }
          return [];
        }),
        finalize(() => {
          this.loaderService.closeLoader();
        })
      )
      .subscribe((data) => {
        if (limit == -1) {
          this.payments = data;
          this.paymentService.setPayments(data);
          this.paymentService.setPaymentLoadStatus(PaymentLoadStatus.COMPLETE);
          return;
        } else if (limit == 3) {
          this.recentPayments = data;
          this.paymentService.setRecentPayments(data);
          this.paymentService.setPayments(data);
          this.paymentService.setRecentPaymentLoadStatus(PaymentLoadStatus.COMPLETE);
        }
      });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
    this.subscription.unsubscribe();
  }
}
