import { Injectable } from '@angular/core';
import { HttpService } from '../../../http.service';
import { PaymentsService } from './payments.service';
import { Observable } from 'rxjs';
import { Payment } from '../../../model/payments';
import { PaymentSummary, PaymentSummaryByPeriodSeriesData, PaymentSummaryData, PaymentCount } from '../../../model/payment-summary';

@Injectable({
  providedIn: 'root'
})
export class HttpPaymentsService extends PaymentsService {

  constructor(
    private httpService: HttpService
  ) {
    super();
  }

  getMroPayments(limit: number): Observable<Payment[]> {
    const url = `/mro/getMroPayments/${limit}`;
    return this.httpService.get(url, true);
  }

  getMroPaymentSummaryByProperty(fromDate: string, toDate: string, propertyId: number, productId: number): Observable<PaymentSummaryData[]> {
    const url = `/mro/getMroPaymentSummaryByProperty?fromDate=${fromDate}&toDate=${toDate}&propertyId=${propertyId}&productId=${productId}`;
    return this.httpService.get(url, true);
  }

  getMroPaymentSummaryByProduct(fromDate: string, toDate: string, propertyId: number, productId: number): Observable<PaymentSummaryData[]> {
    const url = `/mro/getMroPaymentSummaryByProduct?fromDate=${fromDate}&toDate=${toDate}&propertyId=${propertyId}&productId=${productId}`;
    return this.httpService.get(url, true);
  }

  getMroPaymentSummaryByPeriod(fromDate: string, toDate: string, propertyId: number, productId: number, breakdown: string): Observable<PaymentSummary> {
    const url = `/mro/getMroPaymentSummaryByPeriod?fromDate=${fromDate}&toDate=${toDate}&propertyId=${propertyId}&productId=${productId}&breakdown=${breakdown}`;
    return this.httpService.get(url, true);
  }

  getMroPaymentSummaryByPeriodByProperty(fromDate: string, toDate: string, propertyId: number, productId: number, breakdown: string): Observable<PaymentSummaryByPeriodSeriesData[]> {
    const url = `/mro/getMroPaymentSummaryByPeriodByProperty?fromDate=${fromDate}&toDate=${toDate}&propertyId=${propertyId}&productId=${productId}&breakdown=${breakdown}`;
    return this.httpService.get(url, true);
  }

  getMroPaymentSummaryByPeriodByProduct(fromDate: string, toDate: string, propertyId: number, productId: number, breakdown: string): Observable<PaymentSummaryByPeriodSeriesData[]> {
    const url = `/mro/getMroPaymentSummaryByPeriodByProduct?fromDate=${fromDate}&toDate=${toDate}&propertyId=${propertyId}&productId=${productId}&breakdown=${breakdown}`;
    return this.httpService.get(url, true);
  }

  getMroPendingPaymentCount(): Observable<PaymentCount> {
    const url = `/mro/getMroPendingPaymentCount`;
    return this.httpService.get(url, true);
  }


}
