import { MarketLoadStatus } from './../../../model/market-price';
import { Component, OnInit } from '@angular/core';
import { MarketService } from '../../../core/services/market/market.service';
import { Subject, catchError, from, takeUntil } from 'rxjs';
import { ResourcePriceData } from '../../../model/resourcePriceData';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from '../../spinner/spinner.component';

@Component({
  selector: 'app-markets',
  standalone: true,
  imports: [
    CommonModule,
    SpinnerComponent
  ],
  templateUrl: './markets.component.html',
  styleUrls: ['./markets.component.css']
})
export class MarketsComponent implements OnInit {

  public crudeOilPriceDate: string;
  public crudeOilPrice: number;
  public crudeOilPriceChanged: number;
  public naturalGasPriceDate: string;
  public naturalGasPrice: number;
  public naturalGasPriceChanged: number;
  public Unsubscribe = new Subject();
  public resources = ['Crude Oil', 'Natural Gas'];
  public marketLoadStatusOil: MarketLoadStatus = MarketLoadStatus.LOADING;
  public marketLoadStatusGas: MarketLoadStatus = MarketLoadStatus.LOADING;

  constructor(private marketService: MarketService) { }

  ngOnInit() {
    this.getMarketPrices();
  }

  get oilLoading() {
    return this.marketLoadStatusOil === MarketLoadStatus.LOADING;
  }

  get gasLoading() {
    return this.marketLoadStatusGas === MarketLoadStatus.LOADING;
  }

  get oilComplete() {
    return this.marketLoadStatusOil === MarketLoadStatus.COMPLETE;
  }

  get gasComplete() {
    return this.marketLoadStatusGas === MarketLoadStatus.COMPLETE;
  }

  get oilFailed() {
    return this.marketLoadStatusOil === MarketLoadStatus.FAILED;
  }

  get gasFailed() {
    return this.marketLoadStatusGas === MarketLoadStatus.FAILED;
  }

  public retryOil() {
    this.marketLoadStatusOil = MarketLoadStatus.LOADING;
    this.resources = ['Crude Oil'];
    this.getMarketPrices();
  }

  public retryGas() {
    this.marketLoadStatusGas = MarketLoadStatus.LOADING;
    this.resources = ['Natural Gas'];
    this.getMarketPrices();
  }

  public getMarketPrices() {

    for (const resource of this.resources) {
      if (resource === 'Crude Oil') {
        this.marketLoadStatusOil = MarketLoadStatus.LOADING;
      } else if (resource === 'Natural Gas') {
        this.marketLoadStatusGas = MarketLoadStatus.LOADING;
      }
      from(this.marketService.getMarketPrice(resource))
        .pipe(
          takeUntil(this.Unsubscribe),
          catchError((error) => {
            if (error.status.toString() === '500') {
              if (resource === 'Crude Oil') {
                this.marketLoadStatusOil = MarketLoadStatus.FAILED;
              }
              if (resource === 'Natural Gas') {
                this.marketLoadStatusGas = MarketLoadStatus.FAILED;
              }
              console.error('getMarketPrice:Error: ' + JSON.stringify(error));
              return error;
            }
            console.error('getMarketPrice:Error: ' + JSON.stringify(error));
            return error;
          }
          )
        )
        .subscribe(
          (response: ResourcePriceData) => {
            if (resource === 'Crude Oil') {
              this.marketLoadStatusOil = MarketLoadStatus.COMPLETE;
              this.crudeOilPriceDate = response.readingDateTime;
              this.crudeOilPrice = response.price;
              this.crudeOilPriceChanged = response.difference;
            } else if (resource === 'Natural Gas') {
              this.marketLoadStatusGas = MarketLoadStatus.COMPLETE;
              this.naturalGasPriceDate = response.readingDateTime;
              this.naturalGasPrice = response.price;
              this.naturalGasPriceChanged = response.difference;
            }
          }
        );
    }
  }
}
