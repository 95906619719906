import { PropertyPerformance } from "../model/performance";
import { products } from "./products";

export const propertyPerformance1Data1: PropertyPerformance = {
  productId: products[0].id,
  productName: products[0].name,
  salesDate: '2020-01-01',
  salesAmount: 10
}

export const propertyPerformance1Data2: PropertyPerformance = {
  productId: products[0].id,
  productName: products[0].name,
  salesDate: '2020-02-01',
  salesAmount: 15
}

export const propertyPerformance1Data3: PropertyPerformance = {
  productId: products[0].id,
  productName: products[0].name,
  salesDate: '2020-03-01',
  salesAmount: 5
}

export const propertyPerformance1Data4: PropertyPerformance = {
  productId: products[0].id,
  productName: products[0].name,
  salesDate: '2020-04-01',
  salesAmount: 9
}

export const propertyPerformance1Data5: PropertyPerformance = {
  productId: products[1].id,
  productName: products[1].name,
  salesDate: '2020-01-01',
  salesAmount: 16
}

export const propertyPerformance1Data6: PropertyPerformance = {
  productId: products[1].id,
  productName: products[1].name,
  salesDate: '2020-02-01',
  salesAmount: 8
}

export const propertyPerformance1Data7: PropertyPerformance = {
  productId: products[1].id,
  productName: products[1].name,
  salesDate: '2020-03-01',
  salesAmount: 19
}

export const propertyPerformance1Data8: PropertyPerformance = {
  productId: products[1].id,
  productName: products[1].name,
  salesDate: '2020-04-01',
  salesAmount: 22
}

export const propertyPerformance1Data9: PropertyPerformance = {
  productId: products[2].id,
  productName: products[2].name,
  salesDate: '2020-01-01',
  salesAmount: 8
}

export const propertyPerformance1Data10: PropertyPerformance = {
  productId: products[2].id,
  productName: products[2].name,
  salesDate: '2020-02-01',
  salesAmount: 12
}

export const propertyPerformance1Data11: PropertyPerformance = {
  productId: products[2].id,
  productName: products[2].name,
  salesDate: '2020-03-01',
  salesAmount: 12
}

export const propertyPerformance1Data12: PropertyPerformance = {
  productId: products[2].id,
  productName: products[2].name,
  salesDate: '2020-04-01',
  salesAmount: 18
}

export const propertyPerformance1Data13: PropertyPerformance = {
  productId: products[3].id,
  productName: products[3].name,
  salesDate: '2020-01-01',
  salesAmount: 15
}

export const propertyPerformance1Data14: PropertyPerformance = {
  productId: products[3].id,
  productName: products[3].name,
  salesDate: '2020-02-01',
  salesAmount: 12
}

export const propertyPerformance1Data15: PropertyPerformance = {
  productId: products[3].id,
  productName: products[3].name,
  salesDate: '2020-03-01',
  salesAmount: 20
}

export const propertyPerformance1Data16: PropertyPerformance = {
  productId: products[3].id,
  productName: products[3].name,
  salesDate: '2020-04-01',
  salesAmount: 25
}

export const propertyPerformanceData: PropertyPerformance[] = [
  propertyPerformance1Data1,
  propertyPerformance1Data2,
  propertyPerformance1Data3,
  propertyPerformance1Data4,
  propertyPerformance1Data5,
  propertyPerformance1Data6,
  propertyPerformance1Data7,
  propertyPerformance1Data8,
  propertyPerformance1Data9,
  propertyPerformance1Data10,
  propertyPerformance1Data11,
  propertyPerformance1Data12,
  propertyPerformance1Data13,
  propertyPerformance1Data14,
  propertyPerformance1Data15,
  propertyPerformance1Data16
]