import { User } from './../model/user';
import { bank1, bank2 } from './banks';
import { randomDateMinOrMax } from './helpers/randomDateHelper';
import { operator1, operator2, operator3, operator4 } from './operators';

export  const user1: User = {
  id: 1,
  fname: 'John',
  lname: 'Doe',
  tel: '123-456-7890',
  email: 'johnDoe@test.lan',
  password: 'password',
  entityId: 1,
  roleId: 1,
  operator: operator1,
  bank: bank2,
  status: 1,
  verificationCode: '123456',
  insertDate: randomDateMinOrMax(2022, 1999, true),
  insertBy: 1,
  modifiedDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
  modifiedBy: 1,
  registrationStatus: 1
};

export  const user2: User = {
  id: 2,
  fname: 'Jane',
  lname: 'Doe',
  tel: '123-456-7890',
  email: 'janeDoe@test.lan',
  password: 'password',
  entityId: 1,
  roleId: 1,
  operator: operator2,
  bank: bank1,
  status: 1,
  verificationCode: '123456',
  insertDate: randomDateMinOrMax(2022, 1999, true),
  insertBy: 1,
  modifiedDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
  modifiedBy: 1,
  registrationStatus: 1
};

export  const user3: User = {
  id: 3,
  fname: 'John',
  lname: 'Smith',
  tel: '123-456-7890',
  email: 'johnsmith@test.lan',
  password: 'password',
  entityId: 1,
  roleId: 1,
  operator: operator3,
  bank: bank1,
  status: 1,
  verificationCode: '123456',
  insertDate: randomDateMinOrMax(2022, 1999, true),
  insertBy: 1,
  modifiedDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
  modifiedBy: 1,
  registrationStatus: 1
};

export  const user4: User = {
  id: 4,
  fname: 'Jane',
  lname: 'Smith',
  tel: '123-456-7890',
  email: 'janesmith@test.lan',
  password: 'password',
  entityId: 1,
  roleId: 1,
  operator: operator4,
  bank: bank2,
  status: 1,
  verificationCode: '123456',
  insertDate: randomDateMinOrMax(2022, 1999, true),
  insertBy: 1,
  modifiedDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
  modifiedBy: 1,
  registrationStatus: 1
};

export const users: User[] = [
  user1,
  user2,
  user3,
  user4
];


