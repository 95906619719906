import { Title } from './../model/title';
import { accounts } from './accounts';
import { properties } from './properties';

export const title1: Title = {
  id: 1,
  assetNumber: '1234567890',
  status: '',
  gisUrl: '',
  property: properties[0],
  account: accounts[0],
  insertBy: '1',
  insertDate: undefined,
  modifyBy: '1',
  modifyDate: undefined
};

export const title2: Title = {
  id: 2,
  assetNumber: '1234567890',
  status: '',
  gisUrl: '',
  property: properties[1],
  account: accounts[0],
  insertBy: '',
  insertDate: undefined,
  modifyBy: '',
  modifyDate: undefined
};

export const title3: Title = {
  id: 3,
  assetNumber: '1234567890',
  status: '',
  gisUrl: '',
  property: properties[2],
  account: accounts[0],
  insertBy: '',
  insertDate: undefined,
  modifyBy: '',
  modifyDate: undefined
};

export const title4: Title = {
  id: 4,
  assetNumber: '1234567890',
  status: '',
  gisUrl: '',
  property: properties[3],
  account: accounts[1],
  insertBy: '',
  insertDate: undefined,
  modifyBy: '',
  modifyDate: undefined
};

export const title5: Title = {
  id: 5,
  assetNumber: '1234567890',
  status: '',
  gisUrl: '',
  property: properties[4],
  account: accounts[1],
  insertBy: '',
  insertDate: undefined,
  modifyBy: '',
  modifyDate: undefined
};

export const title6: Title = {
  id: 6,
  assetNumber: '1234567890',
  status: '',
  gisUrl: '',
  property: properties[5],
  account: accounts[2],
  insertBy: '',
  insertDate: undefined,
  modifyBy: '',
  modifyDate: undefined
};

export const title7: Title = {
  id: 7,
  assetNumber: '1234567890',
  status: '',
  gisUrl: '',
  property: properties[6],
  account: accounts[2],
  insertBy: '',
  insertDate: undefined,
  modifyBy: '',
  modifyDate: undefined
};

export const title8: Title = {
  id: 8,
  assetNumber: '1234567890',
  status: '',
  gisUrl: '',
  property: properties[7],
  account: accounts[3],
  insertBy: '',
  insertDate: undefined,
  modifyBy: '',
  modifyDate: undefined
};

export const title9: Title = {
  id: 9,
  assetNumber: '1234567890',
  status: '',
  gisUrl: '',
  property: properties[8],
  account: accounts[3],
  insertBy: '',
  insertDate: undefined,
  modifyBy: '',
  modifyDate: undefined
};

export const title10: Title = {
  id: 10,
  assetNumber: '1234567890',
  status: '',
  gisUrl: '',
  property: properties[9],
  account: accounts[0],
  insertBy: '',
  insertDate: undefined,
  modifyBy: '',
  modifyDate: undefined
};

export const title11: Title = {
  id: 11,
  assetNumber: '123456789',
  status: '',
  gisUrl: '',
  property: properties[10],
  account: accounts[0],
  insertBy: '',
  insertDate: undefined,
  modifyBy: '',
  modifyDate: undefined
};

export const title12: Title = {
  id: 12,
  assetNumber: '123456789',
  status: '',
  gisUrl: '',
  property: properties[11],
  account: accounts[0],
  insertBy: '',
  insertDate: undefined,
  modifyBy: '',
  modifyDate: undefined
};

export const title13: Title = {
  id: 13,
  assetNumber: '123456789',
  status: '',
  gisUrl: '',
  property: properties[12],
  account: accounts[0],
  insertBy: '',
  insertDate: undefined,
  modifyBy: '',
  modifyDate: undefined
};

export const title14: Title = {
  id: 14,
  assetNumber: '123456789',
  status: '',
  gisUrl: '',
  property: properties[13],
  account: accounts[0],
  insertBy: '',
  insertDate: undefined,
  modifyBy: '',
  modifyDate: undefined
};

export const title15: Title = {
  id: 15,
  assetNumber: '123456789',
  status: '',
  gisUrl: '',
  property: properties[14],
  account: accounts[1],
  insertBy: '',
  insertDate: undefined,
  modifyBy: '',
  modifyDate: undefined
};

export const title16: Title = {
  id: 16,
  assetNumber: '123456789',
  status: '',
  gisUrl: '',
  property: properties[15],
  account: accounts[1],
  insertBy: '',
  insertDate: undefined,
  modifyBy: '',
  modifyDate: undefined
};

export const title17: Title = {
  id: 17,
  assetNumber: '123456789',
  status: '',
  gisUrl: '',
  property: properties[16],
  account: accounts[1],
  insertBy: '',
  insertDate: undefined,
  modifyBy: '',
  modifyDate: undefined
};

export const title18: Title = {
  id: 18,
  assetNumber: '123456789',
  status: '',
  gisUrl: '',
  property: properties[17],
  account: accounts[2],
  insertBy: '',
  insertDate: undefined,
  modifyBy: '',
  modifyDate: undefined
};

export const title19: Title = {
  id: 19,
  assetNumber: '123456789',
  status: '',
  gisUrl: '',
  property: properties[18],
  account: accounts[3] ,
  insertBy: '',
  insertDate: undefined,
  modifyBy: '',
  modifyDate: undefined
};

export const title20: Title = {
  id: 20,
  assetNumber: '123456789',
  status: '',
  gisUrl: '',
  property: properties[19],
  account: accounts[0],
  insertBy: '',
  insertDate: undefined,
  modifyBy: '',
  modifyDate: undefined
};


export const titles: Title[] = [
  title1,
  title2,
  title3,
  title4,
  title5,
  title6,
  title7,
  title8,
  title9,
  title10,
  title11,
  title12,
  title13,
  title14,
  title15,
  title16,
  title17,
  title18,
  title19,
  title20
];


