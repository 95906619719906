<div id="mro_content">
  <div class="mobile_container">
    <div id="mro_recent_payments_inner">
      <div class="mro_header_icon_container">
        <div class="mro_header_icon"><span class="icon_sdo"></span></div>
        <div class="mro_header_icon_inner">
          <span class="icon_sdo_inner"></span>
        </div>
        <div class="mro_header_icon_shadow">
          <span class="icon_sdo_shadow"></span>
        </div>
      </div>

      <h1 class="mro_title">Smart Division Order&trade;</h1>

      <div class="mro_content_inner">
        <div class="sdo_header">
          <div class="sdo_operator"><h3>Corporate Oil Inc</h3></div>
          <div class="sdo_bc_d">
            <a href="javascript:void(0)"
              ><span class="icon_blockchain"></span> DL37865#</a
            >
          </div>
        </div>

        <div class="data_row compact inline">
          <div class="data_label">Property No:</div>
          <div class="data_value text_right">#45667873</div>
        </div>
        <div class="data_row compact inline">
          <div class="data_label">Owner ID:</div>
          <div class="data_value text_right">
            <a href="javascipt:void(0)">#987872872</a>
          </div>
        </div>
        <div class="data_row compact inline">
          <div class="data_label">Term Start:</div>
          <div class="data_value text_right">1 Apr 2019</div>
        </div>
        <div class="data_row compact inline">
          <div class="data_label">Term End:</div>
          <div class="data_value text_right">31 Mar 2024</div>
        </div>
        <div class="data_row compact inline">
          <div class="data_label">Royalty Interest:</div>
          <div class="data_value text_right">0.1250000</div>
        </div>
        <div class="data_row compact inline">
          <div class="data_label">Allowable Deductions:</div>
          <div class="data_value text_right">
            <button
              type="button"
              class="btn_normal btn_no_padding btn_no_border btn_table"
              onclick="toggleLink(this); toggleContainer('deductions')"
            >
              <span class="btn_text"
                ><span class="btn_status hidden_text">Show</span> 4</span
              >
              <span class="btn_helper"
                ><span class="icon_chevron_down"></span
              ></span>
            </button>
          </div>
        </div>

        <div class="" id="deductions" style="display: none">
          deductions content
        </div>
      </div>

      <div class="space_divider extra_padding"></div>

      <div class="mro_actions full">
        <div class="mro_actions_left">
          <ul class="options_list">
            <li>
              <button
                type="button"
                class="btn_normal btn_purple btn_big btn_wide"
                (click)="gotoDashboard()"
              >
                <span class="btn_text">Done</span>
              </button>
            </li>

            <li></li>
          </ul>
        </div>
        <div class="mro_actions_right">
          <ul class="options_list">
            <li>
              <button
                type="button"
                class="btn_normal btn_purple_link btn_big btn_wide"
                onclick="window.location='faq_mro.html'"
              >
                <span class="btn_text">Ask a Question</span>
              </button>
            </li>
          </ul>
        </div>
      </div>

      <div class="space_divider extra_padding"></div>

      <!-- Info Component -->
      <div class="info_component no_border align_center">
        <div class="main_icon_container">
          <div class="main_icon"><span class="icon_helpdesk"></span></div>
          <div class="main_icon_shadow">
            <span class="icon_helpdesk_shadow"></span>
          </div>
        </div>

        <div class="fine_print">
          If you think any of the information in this Division Order is
          incorrect, please contact your TrustBank Trust Manager on 555 012-3933
        </div>
      </div>
    </div>
  </div>
</div>
