import { HttpService } from '../../../http.service';
import { Injectable } from '@angular/core';
import { PerformanceService } from './performance.service';
import { Observable } from 'rxjs';
import { MroPerformance, PropertyPerformance } from '../../../model/performance';

@Injectable({
  providedIn: 'root'
})
export class HttpPerformanceService extends PerformanceService {

  constructor(
    private httpService: HttpService
  ) {
    super();
   }

  getMROPerformance(periodStart: Date, periodEnd: Date): Observable<MroPerformance[]>{
    const url = `/mro/getMroPerformance?startDate=${periodStart.toISOString()}&endDate=${periodEnd.toISOString()}`;
    return this.httpService.get(url, true);
  }

  getPropertyPerformance(propertyId: number): Observable<PropertyPerformance[]> {
    const url = `/getPropertyPerformance?propertyId=${propertyId}`;
    return this.httpService.get(url, true);
  }
}
