/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  @Output() httpError = new EventEmitter<HttpErrorResponse>();
  private _token = new BehaviorSubject(sessionStorage.getItem('token'));
  token$ = this._token.asObservable();


  private userToken: string = '';
  private userTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private http: HttpClient
  ) { }

  setUserToken(token: string): void {
    sessionStorage.setItem('token', token);
    this.userToken = token;
    this.updateToken();
  }

  updateToken() {
    this._token.next(sessionStorage.getItem('token'));
  }

  getUserToken(): Observable<string> {
    return this.userTokenSubject.asObservable();
  }
  get(url: string, authorized: boolean, params?: HttpParams | (Record<string, unknown>), headers?: Record<string, string | string[]>): Observable<any> {
    return this.http.get(`${environment.apiUrl}${url}`, {
      headers: this.generateHeaders(authorized, headers),
      params: this.generateHttpParams(params)
    });
  }

  post(url: string, data: Record<string, any>, authorized: boolean, headers?: Record<string, string | string[]> ): Observable<any> {
    return this.http.post(`${environment.apiUrl}${url}`, data, { headers: this.generateHeaders(authorized, headers) });
  }

  patch(url: string, data: Record<string, unknown>, authorized: boolean, headers?: Record<string, string | string[]>): Observable<any> {
    return this.http.patch(`${environment.apiUrl}${url}`, data, { headers: this.generateHeaders(authorized, headers) });
  }

  put(url: string, data: Record<string, unknown>, authorized: boolean, headers?: Record<string, string | string[]>): Observable<any> {
    return this.http.put(`${environment.apiUrl}${url}`, data, { headers: this.generateHeaders(authorized, headers) });
  }

  delete(url: string, authorized: boolean, headers?: Record<string, string | string[]>): Observable<any> {
    return this.http.delete(`${environment.apiUrl}${url}`, { headers: this.generateHeaders(authorized, headers) });
  }

  handleHttpError(error: HttpErrorResponse): void {
    this.httpError.emit(error);
  }

  private generateHeaders(authorized: boolean, headers?: Record<string, string | string[]>): HttpHeaders {
    const httpHeaders: HttpHeaders = headers ? new HttpHeaders(headers) : new HttpHeaders();
    if (authorized) {
      return httpHeaders.set('Authorization', `Bearer ${this.userToken}`);
    }
    return httpHeaders;
  }

  private generateHttpParams(params?: HttpParams | (Record<string, unknown>)): HttpParams {
    if (params) {
      if (params instanceof HttpParams) {
        return params
      }
      return new HttpParams({ fromObject: params as { [param: string]: string | number | boolean | readonly (string | number | boolean)[] } });
    }
    return new HttpParams();
  }
}
