import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Partner } from '../../../model/partner';
import { mockPartners } from '../../../mockDB/partner';
import { PartnerService } from './partner.service';

@Injectable({
  providedIn: 'root'
})
export class MockPartnerService extends PartnerService {

  constructor() {
    super();
  }

  getPartner(): Observable<Partner> {
    return of(mockPartners[0]);
  }
}
