import { Environment } from "../app/model/environment";

export const environment: Environment = {
  production: false,
  mock: false,
  demo: true,
  pilot: false,
  dev: false,

  // apiUrl: 'http://localhost:8080/pipelinedl/webapi'
  apiUrl: 'https://ong-mock-api.pipelinedl.com',
  // apiUrl: 'https://www.pipelinedl.com:9443/pipelinedl-app-server/webapi',
  // apiUrl: '/pipelinedl-app-server/webapi',
  // eslint-disable-next-line max-len
  blockchainExplorerUrl: 'https://b1cf68b304374c5f96c310febcf3a3a9-optools.uss02.blockchain.cloud.ibm.com/peer/Peer1Pipel_0/channel/pipelinechannel/block/',
  // blockchainExplorerUrl: 'http://139.59.193.133:8080/#/transactions',
  // eslint-disable-next-line max-len
  // 'Nuarca,444445911338471#B0TuvSd7YDW6Z4VUl6bUhWQsFETh3ENKp7KM3SU4BVYQt6c8E7Rq34N9Q5aZRVWXBlcONUYvgnb5sWc7gVYjF6aFVTYw3yTGZFRhRHSwlzb4R6bXpWRHlHa8dzdR3mR7V6LxYHeWRHdLZVWPtGb4RkSodzKnR6V5MUYl3iVDhWY8h7NmRDSXFmU4VTeGBHM4N6UPBzTzYjZxh5QFdTewRFb8M7T9EXd0NXN5JkaqdVVPBjZBVWSsB5U98kYEVlVWVjM7NUcC3UezR6bllTYpV6YsJXNsJEb4oERwJHOyQHM8okN4kHTiojITJCLiYDM6cTQFRkI0ICSiwiNwkDN5kDOzcTM0IicfJye&Qf35VfikkR9IkI0IyQiwiIyEjL6ByUKBCZhVmcwNlI0IiTis7W0ICZyBlIsICNzATNxADI4AjNwkTMwIjI0ICdyNkIsICNwcDM9EDMyIiOiAHeFJCLiE6YyFWdOJiOiEmTDJCLiEzN4gzMzETM9UDN4QDN4IiOiQWSiwSflNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPBlHNTdTTu9Ee636ZkF7Q93UMJtCUTNVaWRmSINEV6wWUt56YyxkNjRWWDtyNpdmVrcEd5d7Q5AlQy4kQr5WYQ9WWz3mawMzdjVkWIBTMW9EVsJUah3Wdit4Tl3iUmFzc7omYnZjdSl4QDZESiZDDVzU',
  spreadLicense:
    // eslint-disable-next-line max-len
    'Empyrean Solutions,445182643533491#B0ATeV5Y5oke9lGNH3WM7JnVQF7Vzx6Zq3EZoJkQoN5RiF5VplVW8wEVCNmSvhVM5t6KlJnVjVncOpkS7U6YQZWbEBXUwcjZGpXMXZ4UaZWezITN6kUMwtUOLtSM7s4NxBDMohGeTRkR7xUMI9EOTRmQGhlVKRVRUFkbGZTOPRndzMXdpJ5bEFUahFlcBhzaOBDT9czUOZFV094byZmW7QzbFpmNVF4YmVDTvpWR5YUesN5LTVDbExmRzdEUtRDTXtWaSx6QpVTSRdlW7QGWCtiV9dUe63GbPpndwEGS4R4b5N6ZiojITJCLigTMBJ4QyEENiojIIJCLwkzMyUjNzYDN0IicfJye#4Xfd5nIJZUOCJiOiMkIsIiMx8idgMlSgQWYlJHcTJiOi8kI1tlOiQmcQJCLiUjM6ITNwASMwcDM9EDMyIiOiQncDJCLiMnbvlGd5x6bTBibhVmc9BXbFJiOiEmTDJCLiETO4MzM5MDN6IDOxUDN4IiOiQWSiwSflNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRVXOklzbOdncn9mRmZzRuh4VIFFWJhFaINTbwpmbNNmbR5UQkFWVkNzNJRVYv5GS8UmVTlEaVlVR7J4bTh7bRdzb9dGcDNkNqpVWQVjZHRTVlFnbxYVWrgGWah6d724QI36T4l6K64UTyhFUKJ4KGJrZ3l',

  gridHighlight: '#EDF4FC',
  gridBackground: 'white',
  gridCellChanged: '#FFE6A2',
  gridCellBorder: '#E0E0E0',
  buttonEnabled: '#468EE5',
  buttonEnabledText: 'white',
  buttonDisabled: 'rgba(0,0,0,0.05)',
  buttonDisabledText: '#CCCCCC',

  // FOR MARKET PRICE API
  marketPriceBaseUrl: 'https://www.quandl.com/api/v3/datasets',
  crudeOilPriceUrl: 'CHRIS/CME_CL1/data.json',
  naturalGasPriceUrl: 'CHRIS/CME_NG1/data.json',
  marketPriceApiKey: 'cryisiVxyu_UhP__you6',
  oilPriceJsonPath: '$.dataset_data.data[:2][0,6]',
  naturalGasPriceJsonPath: '$.dataset_data.data[:2][0,6]',

  // DEFINE USER ROLES CONSTANTS
  ROLE_ADMIN: -1,
  ROLE_PUBLIC: 0,
  ROLE_REGULATOR: 1,
  ROLE_MRO: 2,
  ROLE_MRO_PROXY: 4,
  ROLE_MRO_PROXY_ADMIN: 8,
  ROLE_BANK: 16,
  ROLE_BANK_ADMIN: 32,
  ROLE_OPERATOR: 64,
  ROLE_OPERATOR_ADMIN: 128,

   // FOR ADB2C CONFIGURATION
   B2C_POLICY_NAME_SIGN_IN: 'b2c_1_sign_in',
   B2C_POLICY_NAME_RESET_PASSWORD: 'B2C_1_reset_password',
   B2C_AUTHORITY_URL_SIGN_IN: 'https://oilngasqa.b2clogin.com/oilngasqa.onmicrosoft.com/b2c_1_sign_in',
   B2C_AUTHORITY_URL_RESET_PASSWORD: 'https://oilngasqa.b2clogin.com/oilngasqa.onmicrosoft.com/B2C_1_reset_password',
   B2C_AUTHORITY_DOMAIN_URL: 'oilngasqa.b2clogin.com',
   B2C_CLIENT_ID: 'c82598f3-4ff8-4c6a-afbb-dfd028a3e0fd'
};
