import { Injectable, Injector, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import * as msal from '@azure/msal-browser';
import { AzureAuthModule } from '../../azure-auth.module';
import { takeUntil, tap } from 'rxjs/operators';
import { AzureAuthConfig, AZURE_AUTH_CONFIG } from '../../models/config';

@Injectable({
  providedIn: AzureAuthModule
})
export class AzureAuthService implements OnInit {
  private unsubscribe = new Subject<void>();
  private retries: number = 3;
  private retryDelay: number = 1000;

  private config!: AzureAuthConfig;
  constructor(
    private msalService: MsalService,
    private injector: Injector
  ) {
    this.config = this.injector.get(AZURE_AUTH_CONFIG);
  }

  ngOnInit(): void {

  }

  logInPopUp(): Observable<AuthenticationResult> {
    if (!sessionStorage.getItem('user')) {
      sessionStorage.clear();
    }
    return this.msalService.loginPopup().pipe(
      takeUntil(this.unsubscribe),
      tap((response) => {
        this.msalService.instance.setActiveAccount(response.account)
      })
    );
  }

  logInRedirect(redirectStartPage?: string): Observable<void | AuthenticationResult> {
    return this.msalService.loginRedirect({ scopes: this.config.scopes, redirectStartPage }).pipe(
      takeUntil(this.unsubscribe),
      tap((response) => {
        if (response) {
          this.msalService.instance.setActiveAccount(response.account);
        }
      })
    );
  }

  getActiveAccount(): Observable<AuthenticationResult | null> {
    sessionStorage.clear
    const account = this.msalService.instance.getActiveAccount();
    if (!account) {
      const accounts = this.msalService.instance.getAllAccounts();
      if (accounts.length > 0) {
        this.msalService.instance.setActiveAccount(accounts[0]);
      }
    }
    const accountInfo = this.msalService.instance.getActiveAccount();
    if (accountInfo) {
      return this.msalService.acquireTokenSilent({
        scopes: this.config.scopes,
        account: accountInfo
      }).pipe(
        takeUntil(this.unsubscribe),
        tap(
        () => { },
        () => this.logOut()
      ))
    }
    return this.handleRedirectPromise();
  }

  async logOut() {
    this.msalService.instance.clearCache();
    this.msalService.instance.addEventCallback((event) => {
      if (event.eventType === msal.EventType.LOGOUT_SUCCESS) {
        console.info('Logout successful');
      }
    });
    try {
      await this.msalService.logoutRedirect({
        postLogoutRedirectUri: '/login'
      });
    } catch (error) {
      console.error('Logout failed', error);
      if (this.retries > 0) {
        console.info(`Retrying logout... Attempts left: ${this.retries}`);
        setTimeout(() => {this.retries - 1,this.logOut()}, this.retryDelay);
      } else {
        console.error('Max logout attempts reached. Unable to logout.');
      }
    }
  }

  private handleRedirectPromise(): Observable<AuthenticationResult | null> {
    return new Observable((observer) => {
      this.msalService.instance.handleRedirectPromise()
        .then(authResult => {
          observer.next(authResult ?? null);
          observer.complete();
        }).catch(() => {
          observer.next(null);
          observer.complete();
        });
    })
  }

}
