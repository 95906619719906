import { SalesDeduction } from "../model/sales-deduction";
import { deductionTypes } from "./deductionTypes";

export const salesDeduction1: SalesDeduction = {
  deductionType: deductionTypes[0],
  amount: 100
};

export const salesDeduction2: SalesDeduction = {
  deductionType: deductionTypes[1],
  amount: 200
};

export const salesDeduction3: SalesDeduction = {
  deductionType: deductionTypes[2],
  amount: 300
};

export const salesDeduction4: SalesDeduction = {
  deductionType: deductionTypes[3],
  amount: 400
};

export const salesDeduction5: SalesDeduction = {
  deductionType: deductionTypes[4],
  amount: 500
};

export const salesDeduction6: SalesDeduction = {
  deductionType: deductionTypes[5],
  amount: 600
};

export const salesDeduction7: SalesDeduction = {
  deductionType: deductionTypes[6],
  amount: 700
};

export const salesDeduction8: SalesDeduction = {
  deductionType: deductionTypes[7],
  amount: 800
};

export const salesDeduction9: SalesDeduction = {
  deductionType: deductionTypes[8],
  amount: 900
};

export const salesDeduction10: SalesDeduction = {
  deductionType: deductionTypes[9],
  amount: 1000
};

export const salesDeduction11: SalesDeduction = {
  deductionType: deductionTypes[10],
  amount: 1100
};

export const salesDeduction12: SalesDeduction = {
  deductionType: deductionTypes[11],
  amount: 1200
};

export const salesDeduction13: SalesDeduction = {
  deductionType: deductionTypes[12],
  amount: 1300
};

export const salesDeduction14: SalesDeduction = {
  deductionType: deductionTypes[13],
  amount: 1400
};

export const salesDeduction15: SalesDeduction = {
  deductionType: deductionTypes[14],
  amount: 1500
};

export const salesDeduction16: SalesDeduction = {
  deductionType: deductionTypes[15],
  amount: 1600
};

export const salesDeduction17: SalesDeduction = {
  deductionType: deductionTypes[16],
  amount: 1700
};

export const salesDeduction18: SalesDeduction = {
  deductionType: deductionTypes[17],
  amount: 1800
};

export const salesDeduction19: SalesDeduction = {
  deductionType: deductionTypes[18],
  amount: 1900
};

export const salesDeduction20: SalesDeduction = {
  deductionType: deductionTypes[19],
  amount: 2000
};

export const salesDeduction21: SalesDeduction = {
  deductionType: deductionTypes[20],
  amount: 2100
};

export const salesDeduction22: SalesDeduction = {
  deductionType: deductionTypes[21],
  amount: 2200
};

export const salesDeduction23: SalesDeduction = {
  deductionType: deductionTypes[22],
  amount: 2300
};

export const salesDeduction24: SalesDeduction = {
  deductionType: deductionTypes[23],
  amount: 2400
};

export const salesDeductions: SalesDeduction[] = [
  salesDeduction1,
  salesDeduction2,
  salesDeduction3,
  salesDeduction4,
  salesDeduction5,
  salesDeduction6,
  salesDeduction7,
  salesDeduction8,
  salesDeduction9,
  salesDeduction10,
  salesDeduction11,
  salesDeduction12,
  salesDeduction13,
  salesDeduction14,
  salesDeduction15,
  salesDeduction16,
  salesDeduction17,
  salesDeduction18,
  salesDeduction19,
  salesDeduction20,
  salesDeduction21,
  salesDeduction22,
  salesDeduction23,
  salesDeduction24
];
