<div class="widget_container half" id="widget_performance">
  <div class="widget_inner">
    <div class="widget_header">
      <div class="widget_title">
        <h3>Performance</h3>
      </div>
      <div class="widget_actions">
    </div>
    <div class="widget_content">
      <div class="chart_container">
        <ngx-charts-line-chart
          [view]="isMobile ? [400, 300] : [500, 300]"
          [results]="chartData"
          [xAxis]="xAxis"
          [yAxis]="yAxis"
          [legend]="legend"
          [legendPosition]="isMobile ? 'below' : 'right'"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="xAxisLabel"
          [yAxisLabel]="yAxisLabel"
          [autoScale]="autoScale"></ngx-charts-line-chart>
      </div>
    </div>
  </div>
</div>
