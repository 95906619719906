<div id="dummyImage">
  <div id="carouselExampleIndicatorsDesktop" class="carousel slide mx-auto" data-ride="carousel">
    <!-- <ol class="carousel-indicators">
      <li data-target="#carouselExampleIndicatorsDesktop" data-slide-to="0" class="active"></li>
      <li data-target="#carouselExampleIndicatorsDesktop" data-slide-to="1"></li>
      <li data-target="#carouselExampleIndicatorsDesktop" data-slide-to="2"></li>
      <li data-target="#carouselExampleIndicatorsDesktop" data-slide-to="3"></li>
    </ol> -->
    <div class="carousel-inner">
      <!-- <div class="carousel-item active">
        <img class="d-block w-100" src="./assets/images/dummy/dashboard-01-monthly.png" alt="First slide">
      </div>
      <div class="carousel-item">
        <img class="d-block w-100" src="./assets/images/dummy/dashboard-02-monthly.png" alt="Second slide">
      </div>
      <div class="carousel-item">
        <img class="d-block w-100" src="./assets/images/dummy/dashboard-01-all.png" alt="Third slide">
      </div>
      <div class="carousel-item">
        <img class="d-block w-100" src="./assets/images/dummy/dashboard-02-all.png" alt="Forth slide">
      </div> -->
    </div>
    <!-- <a class="carousel-control-prev" href="#carouselExampleIndicatorsDesktop" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicatorsDesktop" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a> -->
  </div>

  <div id="carouselExampleIndicatorsMobile" class="carousel slide mx-auto" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#carouselExampleIndicatorsMobile" data-slide-to="0" class="active"></li>
      <li data-target="#carouselExampleIndicatorsMobile" data-slide-to="1"></li>
      <li data-target="#carouselExampleIndicatorsMobile" data-slide-to="2"></li>
      <li data-target="#carouselExampleIndicatorsMobile" data-slide-to="3"></li>

      <li data-target="#carouselExampleIndicatorsMobile" data-slide-to="4"></li>
      <li data-target="#carouselExampleIndicatorsMobile" data-slide-to="5"></li>
      <li data-target="#carouselExampleIndicatorsMobile" data-slide-to="6"></li>
      <li data-target="#carouselExampleIndicatorsMobile" data-slide-to="7"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img class="d-block w-100" src="./assets/images/dummy/mobile/GX_MRO_Dashboard_Mobile_Chart_01@2x.png" alt="First slide">
      </div>
      <div class="carousel-item">
        <img class="d-block w-100" src="./assets/images/dummy/mobile/GX_MRO_Dashboard_Mobile_Chart_02@2x.png" alt="Second slide">
      </div>
      <div class="carousel-item">
        <img class="d-block w-100" src="./assets/images/dummy/mobile/GX_MRO_Dashboard_Mobile_Chart_03@2x.png" alt="Third slide">
      </div>
      <div class="carousel-item">
        <img class="d-block w-100" src="./assets/images/dummy/mobile/GX_MRO_Dashboard_Mobile_Chart_04@2x.png" alt="Forth slide">
      </div>


      <div class="carousel-item">
        <img class="d-block w-100" src="./assets/images/dummy/mobile/GX_MRO_Dashboard_Mobile_05_Chart_Annual@2x.png" alt="Fifth slide">
      </div>
      <div class="carousel-item">
        <img class="d-block w-100" src="./assets/images/dummy/mobile/GX_MRO_Dashboard_Mobile_06_Chart_Annual@2x.png" alt="Sixth slide">
      </div>
      <div class="carousel-item">
        <img class="d-block w-100" src="./assets/images/dummy/mobile/GX_MRO_Dashboard_Mobile_07_Chart_Annual@2x.png" alt="Seventh slide">
      </div>
      <div class="carousel-item">
        <img class="d-block w-100" src="./assets/images/dummy/mobile/GX_MRO_Dashboard_Mobile_08_Chart_Annual@2x.png" alt="Eighth slide">
      </div>
    </div>
    <!-- <a class="carousel-control-prev" href="#carouselExampleIndicatorsMobile" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicatorsMobile" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a> -->
  </div>

</div>
