import { getRandomInt } from "./randomNumbersHelper";


let dateYear : number = null;

export const randomDateMinOrMax = (year: number, boundaryYear: number, max: boolean) => {
  if (max) {
    dateYear = randomiseYearUpTo(year, boundaryYear);
  } else {
    dateYear = randomiseYearDownTo(year, boundaryYear);
  }
  return new Date(randomDate() + randomTime());
}

export const randomiseYearUpTo = (year: number, boundaryYear: number) => {
  return getRandomInt(year, boundaryYear);
}

export const randomiseYearDownTo = (year: number, boundaryYear: number) => {
  return getRandomInt(boundaryYear, year);
}

export const randomTime = () => {
  return getRandomInt(0, 23) + ':' + getRandomInt(0, 59) + ':' + getRandomInt(0, 59);
}

export const randomDate = () => {
  return dateYear + '-' + getRandomInt(1, 12) + '-' + getRandomInt(1, 31) + ' ';
}
