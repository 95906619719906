import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalAnimation, VerticalAlign } from '../../models/modal';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() animation: ModalAnimation = '';
  @Input() verticalAlign: VerticalAlign = 'align-middle';

  ngOnInit(): void {
    this.hideBodyOverflow();
  }

  ngOnDestroy(): void {
    this.clearBodyOverflow();
  }

  private hideBodyOverflow(): void {
    const body = document.getElementsByTagName('body')[0];
    if (body) {
      body.style.overflow = 'hidden';
    }
  }

  private clearBodyOverflow(): void {
    const body = document.getElementsByTagName('body')[0];
    if (body) {
      body.style.removeProperty('overflow');
    }
  }

}
