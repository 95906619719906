import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-dummy-image',
  templateUrl: './dummy-image.component.html',
  styleUrls: ['./dummy-image.component.css']
})
export class DummyImageComponent implements AfterViewInit {
  @ViewChild('carousel', { static: true }) carousel: ElementRef;

  // ngOnInit() {
  //   this.carousel.nativeElement.pause();
  // }
  ngAfterViewInit() {
    // Ensure that carousel.nativeElement exists before accessing it
    if (this.carousel && this.carousel.nativeElement) {
      this.carousel.nativeElement.pause();
    }
  }
}
