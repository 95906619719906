import { operators } from './operators';
import { Well } from './../model/well';
import { products } from './products';
import { counties, lovingTX } from './counties';

export const well1: Well = {
  apiNo: '42-301-33605',
  name: 'WHEAT TRUST C27-4 LOV 2H',
  status: {
    id: 1,
    name: 'Active'
  },
  operator: operators[1],
  spudDate: new Date('2023-01-01'),
  completionDate: new Date('2023-01-01'),
  firstProductionDate: new Date('2023-01-01'),
  totalVerticalDepth: 11464,
  latitude: 45.0,
  longitude: 0,
  gisUrl: 'https://www.google.com/maps',
  products: [products[1]],
  geographicalData: {
    county: lovingTX,
    section: '4',
    sectionNo: '4',
    township: '',
    range: '',
    surveyName: 'PUBLIC SCHOOL LANDS SURVEY',
    abstract: 'A-771',
    grossAcreage: 640,
    netAcreage: 2.99077,
    insertBy: 1,
    insertDate: new Date(),
    modifyBy: 1,
    modifyDate: new Date(),
    block: 'C-27',
  },
  insertBy: '1',
  insertDate: new Date(),
  modifyBy: '1',
  modifyDate: new Date(),

};

export const well2: Well = {
  apiNo: '42-301-33605',
  name: 'WHEAT TRUST C27-4 LOV 2H',
  status: {
    id: 1,
    name: 'Active'
  },
  operator: operators[1],
  spudDate: new Date('2023-01-01'),
  completionDate: new Date('2023-01-01'),
  firstProductionDate: new Date('2023-01-01'),
  totalVerticalDepth: 11464,
  latitude: 45.0,
  longitude: 0,
  gisUrl: 'https://www.google.com/maps',
  products: [products[1]],
  geographicalData: {
    county: lovingTX,
    section: '4',
    sectionNo: '4',
    township: '',
    range: '',
    surveyName: 'PUBLIC SCHOOL LANDS SURVEY',
    abstract: 'A-771',
    grossAcreage: 640,
    netAcreage: 2.99077,
    insertBy: 1,
    insertDate: new Date(),
    modifyBy: 1,
    modifyDate: new Date(),
    block: 'C-27',
  },
  insertBy: '1',
  insertDate: new Date(),
  modifyBy: '1',
  modifyDate: new Date(),

};

export const well3: Well = {
  apiNo: '201682',
  name: 'RULISON FEDERAL 33D-17 CO GARFIELD',
  status: {
    id: 1,
    name: 'Active'
  },
  operator: operators[2],
  spudDate: new Date('2023-01-01'),
  completionDate: new Date('2023-01-01'),
  firstProductionDate: new Date('2023-01-01'),
  totalVerticalDepth: 1000,
  latitude: 97.0,
  longitude: -100.0,
  gisUrl: 'https://www.google.com/maps',
  products: [products[2]],
  geographicalData: {
    county: counties[2],
    section: '1',
    sectionNo: '1',
    township: '1',
    range: '1',
    surveyName: 'PUBLIC SCHOOL LANDS',
    abstract: 'A-65',
    grossAcreage: 1000,
    netAcreage: 800,
    insertBy: 1,
    insertDate: new Date(),
    modifyBy: 1,
    modifyDate: new Date(),
    block: 'Block',
  },
  insertBy: '1',
  insertDate: new Date(),
  modifyBy: '1',
  modifyDate: new Date(),
};

export const well4: Well = {
  apiNo: 'D668792-205',
  name: 'FAIRWAY JAMES LIME UNIT TR 817',
  status: {
    id: 1,
    name: 'Active'
  },
  operator: operators[3],
  spudDate: new Date('2023-01-01'),
  completionDate: new Date('2023-01-01'),
  firstProductionDate: new Date('2023-01-01'),
  totalVerticalDepth: 1000,
  latitude: 35.0,
  longitude: -200.0,
  gisUrl: 'https://www.google.com/maps',
  products: [products[3]],
  geographicalData: {
    county: counties[3],
    section: '1',
    sectionNo: '1',
    township: '1',
    range: '1',
    surveyName: 'J&k CO',
    abstract: 'A-71',
    grossAcreage: 1000,
    netAcreage: 500,
    insertBy: 1,
    insertDate: new Date(),
    modifyBy: 1,
    modifyDate: new Date(),
    block: 'Block',
  },
  insertBy: '1',
  insertDate: new Date(),
  modifyBy: '1',
  modifyDate: new Date(),
};

export const well5: Well = {
  apiNo: '42-443-10017',
  name: 'BANNER HOGE 3 (STR/DEV)',
  status: {
    id: 1,
    name: 'Active'
  },
  operator: operators[4],
  spudDate: new Date('2023-01-01'),
  completionDate: new Date('2023-01-01'),
  firstProductionDate: new Date('2023-01-01'),
  totalVerticalDepth: 1000,
  latitude: 35.0,
  longitude: -100.0,
  gisUrl: 'https://www.google.com/maps',
  products: [products[4]],
  geographicalData: {
    county: counties[4],
    section: '1',
    sectionNo: '1',
    township: '1',
    range: '1',
    surveyName: 'TRP Survey',
    abstract: 'A-333',
    grossAcreage: 1500,
    netAcreage: 990,
    insertBy: 1,
    insertDate: new Date(),
    modifyBy: 1,
    modifyDate: new Date(),
    block: 'Block',
  },
  insertBy: '1',
  insertDate: new Date(),
  modifyBy: '1',
  modifyDate: new Date(),
};

export const well6: Well = {
  apiNo: '6',
  name: 'Well 6',
  status: {
    id: 1,
    name: 'Active'
  },
  operator: operators[5],
  spudDate: new Date('2023-01-01'),
  completionDate: new Date('2023-01-01'),
  firstProductionDate: new Date('2023-01-01'),
  totalVerticalDepth: 1000,
  latitude: 35.0,
  longitude: -100.0,
  gisUrl: 'https://www.google.com/maps',
  products: [products[5]],
  geographicalData: {
    county: counties[5],
    section: '1',
    sectionNo: '1',
    township: '1',
    range: '1',
    surveyName: 'Survey Name',
    abstract: 'Abstract',
    grossAcreage: 1000,
    netAcreage: 800,
    insertBy: 1,
    insertDate: new Date(),
    modifyBy: 1,
    modifyDate: new Date(),
    block: 'Block',
  },
  insertBy: '1',
  insertDate: new Date(),
  modifyBy: '1',
  modifyDate: new Date(),
};

export const well7: Well = {
  apiNo: '7',
  name: 'Well 7',
  status: {
    id: 1,
    name: 'Active'
  },
  operator: operators[6],
  spudDate: new Date('2023-01-01'),
  completionDate: new Date('2023-01-01'),
  firstProductionDate: new Date('2023-01-01'),
  totalVerticalDepth: 1000,
  latitude: 35.0,
  longitude: -100.0,
  gisUrl: 'https://www.google.com/maps',
  products: [products[6]],
  geographicalData: {
    county: counties[6],
    section: '1',
    sectionNo: '1',
    township: '1',
    range: '1',
    surveyName: 'Survey Name',
    abstract: 'Abstract',
    grossAcreage: 1000,
    netAcreage: 800,
    insertBy: 1,
    insertDate: new Date(),
    modifyBy: 1,
    modifyDate: new Date(),
    block: 'Block',
  },
  insertBy: '1',
  insertDate: new Date(),
  modifyBy: '1',
  modifyDate: new Date(),
};

export const well8: Well = {
  apiNo: '8',
  name: 'Well 8',
  status: {
    id: 1,
    name: 'Active'
  },
  operator: operators[7],
  spudDate: new Date('2023-01-01'),
  completionDate: new Date('2023-01-01'),
  firstProductionDate: new Date('2023-01-01'),
  totalVerticalDepth: 1000,
  latitude: 35.0,
  longitude: -100.0,
  gisUrl: 'https://www.google.com/maps',
  products: [products[7]],
  geographicalData: {
    county: counties[7],
    section: '1',
    sectionNo: '1',
    township: '1',
    range: '1',
    surveyName: 'Survey Name',
    abstract: 'Abstract',
    grossAcreage: 1000,
    netAcreage: 800,
    insertBy: 1,
    insertDate: new Date(),
    modifyBy: 1,
    modifyDate: new Date(),
    block: 'Block',
  },
  insertBy: '1',
  insertDate: new Date(),
  modifyBy: '1',
  modifyDate: new Date(),
};

export const well9: Well = {
  apiNo: '9',
  name: 'Well 9',
  status: {
    id: 1,
    name: 'Active'
  },
  operator: operators[8],
  spudDate: new Date('2023-01-01'),
  completionDate: new Date('2023-01-01'),
  firstProductionDate: new Date('2023-01-01'),
  totalVerticalDepth: 1000,
  latitude: 35.0,
  longitude: -100.0,
  gisUrl: 'https://www.google.com/maps',
  products: [products[8]],
  geographicalData: {
    county: counties[8],
    section: '1',
    sectionNo: '1',
    township: '1',
    range: '1',
    surveyName: 'Survey Name',
    abstract: 'Abstract',
    grossAcreage: 1000,
    netAcreage: 800,
    insertBy: 1,
    insertDate: new Date(),
    modifyBy: 1,
    modifyDate: new Date(),
    block: 'Block',
  },
  insertBy: '1',
  insertDate: new Date(),
  modifyBy: '1',
  modifyDate: new Date(),
};

export const wells: Well[] = [
  well1,
  well2,
  well3,
  well4,
  well5,
  well6,
  well7,
  well8,
  well9
];
