import { Account } from "../model/account";
import { accountManagers } from "./accountManagers";
import { mineralRightsOwners } from "./mineralRightsOwners";

export const account1: Account = {
  id: 1,
  visionAccountId: '123456',
  name: 'FACTORY POND MINERALS, LLC - HAVERFORD',
  status: 'Active',
  number: '1607756001',
  MineralRightsOwner: mineralRightsOwners[0],
  accountAccess: accountManagers[0]
};

export const account2: Account = {
  id: 2,
  visionAccountId: '654321',
  name: 'FACTORY POND MINERALS, LLC - HAVERFORD',
  status: 'Active',
  number: '462136',
  MineralRightsOwner: mineralRightsOwners[1],
  accountAccess: accountManagers[1]
};

export const account3: Account = {
  id: 3,
  visionAccountId: '123456',
  name: 'FACTORY POND MINERALS, LLC - HAVERFORD',
  status: 'Active',
  number: '2021226',
  MineralRightsOwner: mineralRightsOwners[2],
  accountAccess: accountManagers[2]
};

export const account4: Account = {
  id: 4,
  visionAccountId: '654321',
  name: 'FACTORY POND MINERALS, LLC - HAVERFORD',
  status: 'Active',
  number: '654321',
  MineralRightsOwner: mineralRightsOwners[3],
  accountAccess: accountManagers[3]
};

export const accounts = [
  account1, 
  account2,
  account3,
  account4 
];
