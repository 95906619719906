import { Component } from '@angular/core';
import { PaymentsComponent } from './payments/payments.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MarketsComponent } from './markets/markets.component';
import { RecentActivityComponent } from './recent-activity/recent-activity.component';
import { DashboardSummaryComponent } from './dashboard-summary/dashboard-summary';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    PaymentsComponent,
    MarketsComponent,
    RecentActivityComponent,
    DashboardSummaryComponent
  ],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {

}
