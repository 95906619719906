import { PaymentStatus } from "../model/payments";

export const payment_status1: PaymentStatus = {
  id: 1,
  description: 'Paid',
  name: 'Paid',
  operatorId: 1,
  primaryId: 1
};

export const payment_status2: PaymentStatus = {
  id: 2,
  description: 'Unpaid',
  name: 'Unpaid',
  operatorId: 2,
  primaryId: 2
};

export const payment_status3: PaymentStatus = {
  id: 3,
  description: 'Pending',
  name: 'Pending',
  operatorId: 3,
  primaryId: 3
};

export const payment_statuses: PaymentStatus[] = [
  payment_status1,
  payment_status2,
  payment_status3
];