import { Injectable } from '@angular/core';
import { ProfileService } from './profile.service';
import { HttpService } from '../../../http.service';


@Injectable({
  providedIn: 'root'
})
export class HttpProfileService extends ProfileService {
  constructor(
    private httpService: HttpService
  ) {
    super();
  }

  getOwner() {
    const url = `/mro`;
    return this.httpService.get(url, false);
  }

}
