import { getRandomInt } from './helpers/randomNumbersHelper';
import { Payment } from "../model/payments";
import { randomDateMinOrMax } from "./helpers/randomDateHelper";
import { operator1, operator2, operator3, operator4, operator5, operator6, operator7,} from './operators';
import { mineralRightsOwners } from './mineralRightsOwners';
import { payment_types } from './payment_types';
import { payment_statuses } from './payment_status';
import { users } from './users';
import { royaltyPayments } from './royaltyPayments';

export  const payment1: Payment = {
  id: 1,
  operator: operator1,
  paymentDate: new Date('2023-01-01'),
  mineralRightsOwner: mineralRightsOwners[getRandomInt(0, mineralRightsOwners.length - 1)],
  paymentType: payment_types[getRandomInt(0, payment_types.length - 1)],
  paymentStatus: payment_statuses[getRandomInt(0, payment_statuses.length - 1)],
  checkNo: '1548221',
  checkAmount: 125.85,
  royaltyPayments: [
    royaltyPayments[0], royaltyPayments[1], royaltyPayments[2]
  ],
  insertBy: users[getRandomInt(0, users.length - 1)].fname,
  insertDate: randomDateMinOrMax(2023, 2022, true),
  modifyBy: users[getRandomInt(0, users.length - 1)].fname,
  modifyDate: randomDateMinOrMax(2023, 2022, true)
};

export  const payment2: Payment = {
  id: 2,
  operator: operator2,
  paymentDate: new Date('2023-02-01'),
  mineralRightsOwner: mineralRightsOwners[getRandomInt(0, mineralRightsOwners.length - 1)],
  paymentType: payment_types[getRandomInt(0, payment_types.length - 1)],
  paymentStatus: payment_statuses[getRandomInt(0, payment_statuses.length - 1)],
  checkNo: getRandomInt(1, 1000000).toString(),
  checkAmount: 47.53,
  royaltyPayments: [
    royaltyPayments[3], royaltyPayments[4], royaltyPayments[5]
  ],
  insertBy: users[getRandomInt(0, users.length - 1)].fname,
  insertDate: randomDateMinOrMax(2023, 2022, true),
  modifyBy: users[getRandomInt(0, users.length - 1)].fname,
  modifyDate: randomDateMinOrMax(2023, 2022, true)
};

export  const payment3: Payment = {
  id: 3,
  operator: operator3,
  paymentDate: new Date('2023-03-01'),
  mineralRightsOwner: mineralRightsOwners[getRandomInt(0, mineralRightsOwners.length - 1)],
  paymentType: payment_types[getRandomInt(0, payment_types.length - 1)],
  paymentStatus: payment_statuses[getRandomInt(0, payment_statuses.length - 1)],
  checkNo: getRandomInt(1, 1000000).toString(),
  checkAmount: getRandomInt(1, 1000000),
  royaltyPayments: [
    royaltyPayments[6], royaltyPayments[7], royaltyPayments[8]
  ],
  insertBy: users[getRandomInt(0, users.length - 1)].fname,
  insertDate: randomDateMinOrMax(2023, 2022, true),
  modifyBy: users[getRandomInt(0, users.length - 1)].fname,
  modifyDate: randomDateMinOrMax(2023, 2022, true)
};

export  const payment4: Payment = {
  id: 4,
  operator: operator4,
  paymentDate: new Date('2023-04-01'),
  mineralRightsOwner: mineralRightsOwners[getRandomInt(0, mineralRightsOwners.length - 1)],
  paymentType: payment_types[getRandomInt(0, payment_types.length - 1)],
  paymentStatus: payment_statuses[getRandomInt(0, payment_statuses.length - 1)],
  checkNo: getRandomInt(1, 1000000).toString(),
  checkAmount: getRandomInt(1, 1000000),
  royaltyPayments: [
    royaltyPayments[9], royaltyPayments[10], royaltyPayments[11]
  ],
  insertBy: users[getRandomInt(0, users.length - 1)].fname,
  insertDate: randomDateMinOrMax(2023, 2022, true),
  modifyBy: users[getRandomInt(0, users.length - 1)].fname,
  modifyDate: randomDateMinOrMax(2023, 2022, true)
};

export  const payment5: Payment = {
  id: 5,
  operator: operator5,
  paymentDate: new Date('2023-05-01'),
  mineralRightsOwner: mineralRightsOwners[getRandomInt(0, mineralRightsOwners.length - 1)],
  paymentType: payment_types[getRandomInt(0, payment_types.length - 1)],
  paymentStatus: payment_statuses[getRandomInt(0, payment_statuses.length - 1)],
  checkNo: getRandomInt(1, 1000000).toString(),
  checkAmount: getRandomInt(1, 1000000),
  royaltyPayments: [
    royaltyPayments[12], royaltyPayments[13], royaltyPayments[14]
  ],
  insertBy: users[getRandomInt(0, users.length - 1)].fname,
  insertDate: randomDateMinOrMax(2023, 2022, true),
  modifyBy: users[getRandomInt(0, users.length - 1)].fname,
  modifyDate: randomDateMinOrMax(2023, 2022, true)
};

export  const payment6: Payment = {
  id: 6,
  operator: operator6,
  paymentDate: new Date('2023-06-01'),
  mineralRightsOwner: mineralRightsOwners[getRandomInt(0, mineralRightsOwners.length - 1)],
  paymentType: payment_types[getRandomInt(0, payment_types.length - 1)],
  paymentStatus: payment_statuses[getRandomInt(0, payment_statuses.length - 1)],
  checkNo: getRandomInt(1, 1000000).toString(),
  checkAmount: getRandomInt(1, 1000000),
  royaltyPayments: [
    royaltyPayments[15], royaltyPayments[16], royaltyPayments[17]
  ],
  insertBy: users[getRandomInt(0, users.length - 1)].fname,
  insertDate: randomDateMinOrMax(2023, 2022, true),
  modifyBy: users[getRandomInt(0, users.length - 1)].fname,
  modifyDate: randomDateMinOrMax(2023, 2022, true)
};

export  const payment7: Payment = {
  id: 7,
  operator: operator7,
  paymentDate: new Date('2023-07-01'),
  mineralRightsOwner: mineralRightsOwners[getRandomInt(0, mineralRightsOwners.length - 1)],
  paymentType: payment_types[getRandomInt(0, payment_types.length - 1)],
  paymentStatus: payment_statuses[getRandomInt(0, payment_statuses.length - 1)],
  checkNo: getRandomInt(1, 1000000).toString(),
  checkAmount: getRandomInt(1, 1000000),
  royaltyPayments: [
    royaltyPayments[18], royaltyPayments[19], royaltyPayments[20]
  ],
  insertBy: users[getRandomInt(0, users.length - 1)].fname,
  insertDate: randomDateMinOrMax(2023, 2022, true),
  modifyBy: users[getRandomInt(0, users.length - 1)].fname,
  modifyDate: randomDateMinOrMax(2023, 2022, true)
};

export  const payment8: Payment = {
  id: 8,
  operator: operator1,
  paymentDate: new Date('2023-08-01'),
  mineralRightsOwner: mineralRightsOwners[getRandomInt(0, mineralRightsOwners.length - 1)],
  paymentType: payment_types[getRandomInt(0, payment_types.length - 1)],
  paymentStatus: payment_statuses[getRandomInt(0, payment_statuses.length - 1)],
  checkNo: getRandomInt(1, 1000000).toString(),
  checkAmount: getRandomInt(1, 1000000),
  royaltyPayments: [
    royaltyPayments[21], royaltyPayments[22], royaltyPayments[23]
  ],
  insertBy: users[getRandomInt(0, users.length - 1)].fname,
  insertDate: randomDateMinOrMax(2023, 2022, true),
  modifyBy: users[getRandomInt(0, users.length - 1)].fname,
  modifyDate: randomDateMinOrMax(2023, 2022, true)
};

export  const payment9: Payment = {
  id: 9,
  operator: operator1,
  paymentDate: new Date('2023-09-01'),
  mineralRightsOwner: mineralRightsOwners[getRandomInt(0, mineralRightsOwners.length - 1)],
  paymentType: payment_types[getRandomInt(0, payment_types.length - 1)],
  paymentStatus: payment_statuses[getRandomInt(0, payment_statuses.length - 1)],
  checkNo: getRandomInt(1, 1000000).toString(),
  checkAmount: getRandomInt(1, 1000000),
  royaltyPayments: [
    royaltyPayments[24], royaltyPayments[25], royaltyPayments[26]
  ],
  insertBy: users[getRandomInt(0, users.length - 1)].fname,
  insertDate: randomDateMinOrMax(2023, 2022, true),
  modifyBy: users[getRandomInt(0, users.length - 1)].fname,
  modifyDate: randomDateMinOrMax(2023, 2022, true)
};

export  const payment10: Payment = {
  id: 10,
  operator: operator7,
  paymentDate: new Date('2023-10-01'),
  mineralRightsOwner: mineralRightsOwners[getRandomInt(0, mineralRightsOwners.length - 1)],
  paymentType: payment_types[getRandomInt(0, payment_types.length - 1)],
  paymentStatus: payment_statuses[getRandomInt(0, payment_statuses.length - 1)],
  checkNo: getRandomInt(1, 1000000).toString(),
  checkAmount: getRandomInt(1, 1000000),
  royaltyPayments: [
    royaltyPayments[27], royaltyPayments[28], royaltyPayments[29]
  ],
  insertBy: users[getRandomInt(0, users.length - 1)].fname,
  insertDate: randomDateMinOrMax(2023, 2022, true),
  modifyBy: users[getRandomInt(0, users.length - 1)].fname,
  modifyDate: randomDateMinOrMax(2023, 2022, true)
};

export const payment11: Payment = {
  id: 11,
  operator: operator2,
  paymentDate: new Date('2023-10-01'),
  mineralRightsOwner: mineralRightsOwners[getRandomInt(0, mineralRightsOwners.length - 1)],
  paymentType: payment_types[getRandomInt(0, payment_types.length - 1)],
  paymentStatus: payment_statuses[getRandomInt(0, payment_statuses.length - 1)],
  checkNo: getRandomInt(1, 1000000).toString(),
  checkAmount: getRandomInt(1, 1000000),
  royaltyPayments: [
    royaltyPayments[30], royaltyPayments[31], royaltyPayments[32]
  ],
  insertBy: users[getRandomInt(0, users.length - 1)].fname,
  insertDate: randomDateMinOrMax(2023, 2022, true),
  modifyBy: users[getRandomInt(0, users.length - 1)].fname,
  modifyDate: randomDateMinOrMax(2023, 2022, true)
};

export const payments = [
  payment1,
  payment2,
  payment3,
  payment4,
  payment5,
  payment6,
  payment7,
  payment8,
  payment9,
  payment10,
  payment11
];
