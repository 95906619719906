import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Product } from '../../../model/product';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { PropertyPerformance } from '../../../model/performance';
import { trigger, transition, style, animate } from '@angular/animations';
import { PerformanceService } from '../../../core/services/performance/performance.service';
import { Property } from '../../../model/property';

@Component({
  selector: 'app-performance-widget',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgxChartsModule
  ],
  templateUrl: './performance-widget.component.html',
  styleUrl: './performance-widget.component.css',
  animations: [
    trigger('animationState', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('500ms', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class PerformanceWidgetComponent implements OnInit {
  @Input() property: Property;
  public legend: boolean = true;
  public showLabels: boolean = true;
  public animations: boolean = true;
  public xAxis: boolean = true;
  public yAxis: boolean = true;
  public showXAxisLabel: boolean = false;
  public showYAxisLabel: boolean = true;
  public timeline: boolean = true;
  public autoScale: boolean = true;
  public products: Product[];
  public selectedWell: number = -1;
  public selectedProduct: number = -1;
  public chartPeriod: number = 0;
  public sectionType: number = 0;
  public performanceStart: Date = new Date();
  public performanceEnd: Date = new Date();
  public chartData: unknown[] = [];
  public xAxisLabel: string = 'Date';
  public yAxisLabel: string = 'Sales Volume $';

  public performanceValues: PropertyPerformance[] = [];

  constructor(
    private performanceService: PerformanceService,
  ) { }

  get isMobile() {
    return window.innerWidth <= 768;
  }

  ngOnInit(): void {
    this.getPropertyPerformance(this.property?.id);
  }

  convertToNgxChartData(performances: PropertyPerformance[]): unknown[] {
    const groupedPerformances = performances.reduce((groups, performance) => {
      if (!groups[performance.productName]) {
        groups[performance.productName] = [];
      }
      groups[performance.productName].push(performance);
      return groups;
    }, {});

    const ngxChartData = Object.entries(groupedPerformances).map(([productName, performances]) => ({
      name: productName,
      series: (performances as PropertyPerformance[]).map((performance: PropertyPerformance) => ({
        name: new Date(performance.salesDate),
        value: performance.salesAmount,
      })),
    }));

    return ngxChartData;
  }

  private getPropertyPerformance(propertyId: number): void {
    this.performanceService.getPropertyPerformance(propertyId).subscribe((performances: PropertyPerformance[]) => {
      this.performanceValues = performances;
      this.chartData = this.convertToNgxChartData(performances);
    });
  }
}
