import { JwtHelperService } from "@auth0/angular-jwt";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { AzureAuthService } from "../../modules/azure-auth/services/azure-auth/azure-auth.service";
import { AuthService } from "../../core/services/auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AzureAuthService,
    private router: Router,
    private jwtHelper: JwtHelperService,
    private _auth: AuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise((resolve) => {
      setTimeout(
        () => {
          if (this.jwtHelper.isTokenExpired(sessionStorage.getItem("token"))) {
            console.info("Token is expired");
            sessionStorage.clear();
            resolve(false);
          } else {
            console.info("Token is valid");
            resolve(true);
          }
        },
        state.url === "/dashboard" ? 0 : 0
      );
    });
  }
}

export class MockAuthGuard implements CanActivate {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(
  ): Promise<boolean> {
    console.info("MockAuthGuard called");
    return Promise.resolve(true);
  }
}
