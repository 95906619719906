import { MroPerformance } from '../model/performance';
import { randomDateMinOrMax } from './helpers/randomDateHelper';
import { products } from './products';

export const performance1: MroPerformance = {
  productId: products[0].id,
  productName: products[0].name,
  paymentAmount: 100,
  volume: '1000',
  average: '100',
  latestPaymentDate: randomDateMinOrMax(2023, 2022, true).toString()
};

export const performance2: MroPerformance = {
  productId: products[1].id,
  productName: products[1].name,
  paymentAmount: 200,
  volume: '2000',
  average: '200',
  latestPaymentDate: randomDateMinOrMax(2023, 2022, true).toString()
};

export const performance3: MroPerformance = {
  productId: products[2].id,
  productName: products[2].name,
  paymentAmount: 300,
  volume: '3000',
  average: '300',
  latestPaymentDate: randomDateMinOrMax(2023, 2022, true).toString()
};

export const performance4: MroPerformance = {
  productId: products[0].id,
  productName: products[0].name,
  paymentAmount: 400,
  volume: '4000',
  average: '400',
  latestPaymentDate: randomDateMinOrMax(2023, 2022, true).toString()
};

export const performance5: MroPerformance = {
  productId: products[2].id,
  productName: products[2].name,
  paymentAmount: 500,
  volume: '5000',
  average: '500',
  latestPaymentDate: randomDateMinOrMax(2023, 2022, true).toString()
};

export const performance6: MroPerformance = {
  productId: products[1].id,
  productName: products[1].name,
  paymentAmount: 600,
  volume: '6000',
  average: '600',
  latestPaymentDate: randomDateMinOrMax(2023, 2022, true).toString()
};

export const performance7: MroPerformance = {
  productId: products[0].id,
  productName: products[0].name,
  paymentAmount: 700,
  volume: '7000',
  average: '700',
  latestPaymentDate: randomDateMinOrMax(2023, 2022, true).toString()
};

export const performance8: MroPerformance = {
  productId: products[2].id,
  productName: products[2].name,
  paymentAmount: 800,
  volume: '8000',
  average: '800',
  latestPaymentDate: randomDateMinOrMax(2023, 2022, true).toString()
};

export const performance9: MroPerformance = {
  productId: products[1].id,
  productName: products[1].name,
  paymentAmount: 900,
  volume: '9000',
  average: '900',
  latestPaymentDate: randomDateMinOrMax(2023, 2022, true).toString()
};

export const performance10: MroPerformance = {
  productId: products[0].id,
  productName: products[0].name,
  paymentAmount: 1000,
  volume: '10000',
  average: '1000',
  latestPaymentDate: randomDateMinOrMax(2023, 2022, true).toString()
};

export const performance11: MroPerformance = {
  productId: products[2].id,
  productName: products[2].name,
  paymentAmount: 1100,
  volume: '11000',
  average: '1100',
  latestPaymentDate: randomDateMinOrMax(2023, 2022, true).toString()
};

export const performance12: MroPerformance = {
  productId: products[1].id,
  productName: products[1].name,
  paymentAmount: 1200,
  volume: '12000',
  average: '1200',
  latestPaymentDate: randomDateMinOrMax(2023, 2022, true).toString()
};

export const performance13: MroPerformance = {
  productId: products[0].id,
  productName: products[0].name,
  paymentAmount: 1300,
  volume: '13000',
  average: '1300',
  latestPaymentDate: randomDateMinOrMax(2023, 2022, true).toString()
};

export const performance14: MroPerformance = {
  productId: products[2].id,
  productName: products[2].name,
  paymentAmount: 1400,
  volume: '14000',
  average: '1400',
  latestPaymentDate: randomDateMinOrMax(2023, 2022, true).toString()
};

export const performance15: MroPerformance = {
  productId: products[1].id,
  productName: products[1].name,
  paymentAmount: 1500,
  volume: '15000',
  average: '1500',
  latestPaymentDate: randomDateMinOrMax(2023, 2022, true).toString()
};

export const performance16: MroPerformance = {
  productId: products[0].id,
  productName: products[0].name,
  paymentAmount: 1600,
  volume: '16000',
  average: '1600',
  latestPaymentDate: randomDateMinOrMax(2023, 2022, true).toString()
};

export const performance17: MroPerformance = {
  productId: products[2].id,
  productName: products[2].name,
  paymentAmount: 1700,
  volume: '17000',
  average: '1700',
  latestPaymentDate: randomDateMinOrMax(2023, 2022, true).toString()
};

export const performance18: MroPerformance = {
  productId: products[1].id,
  productName: products[1].name,
  paymentAmount: 1800,
  volume: '18000',
  average: '1800',
  latestPaymentDate: randomDateMinOrMax(2023, 2022, true).toString()
};

export const performance19: MroPerformance = {
  productId: products[0].id,
  productName: products[0].name,
  paymentAmount: 1900,
  volume: '19000',
  average: '1900',
  latestPaymentDate: randomDateMinOrMax(2023, 2022, true).toString()
};

export const performance20: MroPerformance = {
  productId: products[2].id,
  productName: products[2].name,
  paymentAmount: 2000,
  volume: '20000',
  average: '2000',
  latestPaymentDate: randomDateMinOrMax(2023, 2022, true).toString()
};

export const performance21: MroPerformance = {
  productId: products[1].id,
  productName: products[1].name,
  paymentAmount: 2100,
  volume: '21000',
  average: '2100',
  latestPaymentDate: randomDateMinOrMax(2023, 2022, true).toString()
};

export const performance22: MroPerformance = {
  productId: products[0].id,
  productName: products[0].name,
  paymentAmount: 2200,
  volume: '22000',
  average: '2200',
  latestPaymentDate: randomDateMinOrMax(2023, 2022, true).toString()
};

export const performance23: MroPerformance = {
  productId: products[2].id,
  productName: products[2].name,
  paymentAmount: 2300,
  volume: '23000',
  average: '2300',
  latestPaymentDate: randomDateMinOrMax(2023, 2022, true).toString()
};

export const performance24: MroPerformance = {
  productId: products[1].id,
  productName: products[1].name,
  paymentAmount: 2400,
  volume: '24000',
  average: '2400',
  latestPaymentDate: randomDateMinOrMax(2023, 2022, true).toString()
};

export const performance25: MroPerformance = {
  productId: products[0].id,
  productName: products[0].name,
  paymentAmount: 2500,
  volume: '25000',
  average: '2500',
  latestPaymentDate: randomDateMinOrMax(2023, 2022, true).toString()
};

export const performance26: MroPerformance = {
  productId: products[2].id,
  productName: products[2].name,
  paymentAmount: 2600,
  volume: '26000',
  average: '2600',
  latestPaymentDate: randomDateMinOrMax(2023, 2022, true).toString()
};

export const performance27: MroPerformance = {
  productId: products[1].id,
  productName: products[1].name,
  paymentAmount: 2700,
  volume: '27000',
  average: '2700',
  latestPaymentDate: randomDateMinOrMax(2023, 2022, true).toString()
};

export const performance28: MroPerformance = {
  productId: products[0].id,
  productName: products[0].name,
  paymentAmount: 2800,
  volume: '28000',
  average: '2800',
  latestPaymentDate: randomDateMinOrMax(2023, 2022, true).toString()
};

export const performance29: MroPerformance = {
  productId: products[2].id,
  productName: products[2].name,
  paymentAmount: 2900,
  volume: '29000',
  average: '2900',
  latestPaymentDate: randomDateMinOrMax(2023, 2022, true).toString()
};

export const performance30: MroPerformance = {
  productId: products[1].id,
  productName: products[1].name,
  paymentAmount: 3000,
  volume: '30000',
  average: '3000',
  latestPaymentDate: randomDateMinOrMax(2023, 2022, true).toString()
};

export const performances: MroPerformance[] = [
  performance1,
  performance2,
  performance3,
  performance4,
  performance5,
  performance6,
  performance7,
  performance8,
  performance9,
  performance10,
  performance11,
  performance12,
  performance13,
  performance14,
  performance15,
  performance16,
  performance17,
  performance18,
  performance19,
  performance20,
  performance21,
  performance22,
  performance23,
  performance24,
  performance25,
  performance26,
  performance27,
  performance28,
  performance29,
  performance30
];
