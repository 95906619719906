import { AccountManager } from "../model/account";
import { multiAccountEntities } from "./multiAccountEntities";


export const accountManager1: AccountManager = {
  id: 1,
  multiAccountEntity: multiAccountEntities[0],
  name: 'Account Manager 1',
  email: '',
  tel: '1234567890',
  fax: '1234567890',
  cell: '1234567890',
  status: 'Active',
  insertBy: 'Admin',
  insertDate: new Date(),
  modifyBy: 'Admin',
  modifyDate: new Date()
};

export const accountManager2: AccountManager = {
  id: 2,
  multiAccountEntity: multiAccountEntities[1],
  name: 'Account Manager 2',
  email: '',
  tel: '1234567890',
  fax: '1234567890',
  cell: '1234567890',
  status: 'Active',
  insertBy: 'Admin',
  insertDate: new Date(),
  modifyBy: 'Admin',
  modifyDate: new Date()
};

export const accountManager3: AccountManager = {
  id: 3,
  multiAccountEntity: multiAccountEntities[2],
  name: 'Account Manager 3',
  email: '',
  tel: '1234567890',
  fax: '1234567890',
  cell: '1234567890',
  status: 'Active',
  insertBy: 'Admin',
  insertDate: new Date(),
  modifyBy: 'Admin',
  modifyDate: new Date()
};

export const accountManager4: AccountManager = {
  id: 4,
  multiAccountEntity: multiAccountEntities[3],
  name: 'Account Manager 4',
  email: '',
  tel: '1234567890',
  fax: '1234567890',
  cell: '1234567890',
  status: 'Active',
  insertBy: 'Admin',
  insertDate: new Date(),
  modifyBy: 'Admin',
  modifyDate: new Date()
};

export const accountManagers = [accountManager1, accountManager2, accountManager3, accountManager4];
