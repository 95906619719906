import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-divisionorder-details',
  templateUrl: './divisionorder-details.component.html',
  styleUrls: ['./divisionorder-details.component.css']
})
export class DivisionorderDetailsComponent {

  constructor(
    private router: Router
  ) { }

  public gotoDashboard() {
    this.router.navigate(['/dashboard']);
  }
}
