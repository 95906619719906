<div id="content">
  <div class="page_container">
    <div id="content_inner">
      <!-- Content Header -->
      <div class="content_header">
        <div class="content_history">
          <!-- <button type="button" class="btn_normal btn_big btn_icon_only" id="btn_history_back" onclick="window.location='properties.html'">
					<span class="btn_icon"><span class="icon_back"></span></span>
					<span class="btn_text">Back</span>
				</button> -->
        </div>
        <div class="content_title">
          <h1>Property Summary</h1>
        </div>
        <div class="content_timestamp">
          <div class="timestamp">Last updated: {{ lastUpdated | date:'d MMM yyyy' }}</div>
        </div>
      </div>

      <!-- Body Content -->
      <div class="content_body">
        <!-- Property Header -->
        <div class="item_header">
          <div class="item_icon">
            <div class="item_icon_main">
              <span class="icon_property"></span>
            </div>
            <div class="item_icon_shadow">
              <span class="icon_property_shadow"></span>
            </div>
          </div>
          <div class="item_details">
            <div id="item_name">{{ property?.name }}</div>
            <div id="item_address">
              {{ property?.geographicalData?.county?.name ? property.geographicalData.county.name.toUpperCase() : '' }},
              {{ property?.geographicalData?.county?.state?.name }}
            </div>
          </div>
        </div>

        <div class="widget_group">
          <app-property-detail-widget
            [property]="property"
          ></app-property-detail-widget>
          <app-performance-widget
            [property]="property"
          ></app-performance-widget>
        </div>
        <div *ngFor="let well of property?.wells; let i = index">
          <div class="widget_group">
              <app-well-widget
                [well]="well"
                [wellIndex]="i + 1"
                [property]="property"
              ></app-well-widget>
        </div>
      </div>
      <div class="link_options pos_right">
        <ul>
          <li>
            <button type="button" class="btn_normal btn_purple btn_back" (click)="onBack()" >
              <mat-icon class="mobile-icon">keyboard_arrow_left</mat-icon>
              <span class="btn_icon"></span>
              <span class="btn_text">Close</span>
            </button>
          </li>
        </ul>
      </div>
</div>
