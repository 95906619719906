import { divisionOrders } from './division-orders';
import { RoyaltyPayment } from "../model/payments";
import { sales } from './sales';
import { paymentDeductions } from './payment-deductions';
import { deductionObjects } from './deductionObjects';
import { wells } from './wells';

export const royaltyPayment1: RoyaltyPayment = {
  id: 1,
  operatorPaymentCode: '1',
  sale: sales[0],
  divisionOrder: divisionOrders[0],
  paymentDate: '1/1/2021',
  mroVolume: 1,
  mroGrossValue: 1000,
  mroDeductions: 10,
  deductions: [
    paymentDeductions[0],
    paymentDeductions[3]
  ],
  deductionObjects: [
    deductionObjects[0],
    deductionObjects[3]
  ],
  mroNetValue: 970,
  severance: 20,
  well: wells[0]
};

export const royaltyPayment2: RoyaltyPayment = {
  id: 2,
  operatorPaymentCode: '2',
  sale: sales[1],
  divisionOrder: divisionOrders[1],
  paymentDate: '2/2/2022',
  mroVolume: 2,
  mroGrossValue: 2000,
  mroDeductions: 20,
  deductions: [
    paymentDeductions[0],
    paymentDeductions[2]
  ],
  deductionObjects: [
    deductionObjects[0],
    deductionObjects[2]
  ],
  mroNetValue: 2000,
  severance: 20,
  well: wells[1]

};

export const royaltyPayment3: RoyaltyPayment = {
  id: 3,
  operatorPaymentCode: '3',
  sale: sales[2],
  divisionOrder: divisionOrders[2],
  paymentDate: '3/3/2023',
  mroVolume: 3,
  mroGrossValue: 3000,
  mroDeductions: 3,
  deductions: [
    paymentDeductions[0],
    paymentDeductions[2]
  ],
  deductionObjects: [
    deductionObjects[0],
    deductionObjects[2]
  ],
  mroNetValue: 3000,
  severance: 0,
  well: wells[2]
};

export const royaltyPayment4: RoyaltyPayment = {
  id: 4,
  operatorPaymentCode: '4',
  sale: sales[3],
  divisionOrder: divisionOrders[3],
  paymentDate: '4/4/2009',
  mroVolume: 4,
  mroGrossValue: 40,
  mroDeductions: 4,
  deductions: [
    paymentDeductions[2],
    paymentDeductions[0]
  ],
  deductionObjects: [
    deductionObjects[2],
    deductionObjects[0]
  ],
  mroNetValue: 4000,
  severance: 0,
  well: wells[3]
};

export const royaltyPayment5: RoyaltyPayment = {
  id: 5,
  operatorPaymentCode: '5',
  sale: sales[4],
  divisionOrder: divisionOrders[4],
  paymentDate: '5/5/2010',
  mroVolume: 5,
  mroGrossValue: 500,
  mroDeductions: 5,
  deductions: [
    paymentDeductions[2],
    paymentDeductions[0]
  ],
  deductionObjects: [
    deductionObjects[2],
    deductionObjects[0]
  ],
  mroNetValue: 500,
  severance: 0,
  well: wells[4]
};

export const royaltyPayment6: RoyaltyPayment = {
  id: 6,
  operatorPaymentCode: '6',
  sale: sales[5],
  divisionOrder: divisionOrders[5],
  paymentDate: '6/6/2015',
  mroVolume: 6,
  mroGrossValue: 600,
  mroDeductions: 6,
  deductions: [
    paymentDeductions[2],
    paymentDeductions[1]
  ],
  deductionObjects: [
    deductionObjects[2],
    deductionObjects[1]
  ],
  mroNetValue: 600,
  severance: 0,
  well: wells[5]
};

export const royaltyPayment7: RoyaltyPayment = {
  id: 7,
  operatorPaymentCode: '7',
  sale: sales[6],
  divisionOrder: divisionOrders[6],
  paymentDate: '7/7/2023',
  mroVolume: 7,
  mroGrossValue: 700,
  mroDeductions: 7,
  deductions: [
    paymentDeductions[1],
    paymentDeductions[2]
  ],
  deductionObjects: [
    deductionObjects[1],
    deductionObjects[2]
  ],
  mroNetValue: 700,
  severance: 0,
  well: wells[6]
};

export const royaltyPayment8: RoyaltyPayment = {
  id: 8,
  operatorPaymentCode: '8',
  sale: sales[7],
  divisionOrder: divisionOrders[7],
  paymentDate: '8/8/2022',
  mroVolume: 8,
  mroGrossValue: 800,
  mroDeductions: 8,
  deductions: [
    paymentDeductions[1]
  ],
  deductionObjects: [
    deductionObjects[1]
  ],
  mroNetValue: 800,
  severance: 0,
  well: wells[7]
};

export const royaltyPayment9: RoyaltyPayment = {
  id: 9,
  operatorPaymentCode: '9',
  sale: sales[8],
  divisionOrder: divisionOrders[8],
  paymentDate: '9/9/2023',
  mroVolume: 9,
  mroGrossValue: 900,
  mroDeductions: 9,
  deductions: [
    paymentDeductions[1]
  ],
  deductionObjects: [
    deductionObjects[1]
  ],
  mroNetValue: 900,
  severance: 0,
  well: wells[8]
};

export const royaltyPayment10: RoyaltyPayment = {
  id: 10,
  operatorPaymentCode: '10',
  sale: sales[9],
  divisionOrder: divisionOrders[9],
  paymentDate: '10/10/2020',
  mroVolume: 10,
  mroGrossValue: 10,
  mroDeductions: 10,
  deductions: [
    paymentDeductions[0]
  ],
  deductionObjects: [
    deductionObjects[0]
  ],
  mroNetValue: 6541,
  severance: 0,
  well: wells[0]
};

export const royaltyPayment11: RoyaltyPayment = {
  id: 11,
  operatorPaymentCode: '11',
  sale: sales[10],
  divisionOrder: divisionOrders[10],
  paymentDate: '11/11/2021',
  mroVolume: 11,
  mroGrossValue: 1100,
  mroDeductions: 11,
  deductions: [
    paymentDeductions[0]
  ],
  deductionObjects: [
    deductionObjects[0]
  ],
  mroNetValue: 1100,
  severance: 0,
  well: wells[1]
};

export const royaltyPayment12: RoyaltyPayment = {
  id: 12,
  operatorPaymentCode: '12',
  sale: sales[11],
  divisionOrder: divisionOrders[11],
  paymentDate: '12/12/2017',
  mroVolume: 12,
  mroGrossValue: 1200,
  mroDeductions: 12,
  deductions: [
    paymentDeductions[0]
  ],
  deductionObjects: [
    deductionObjects[0]
  ],
  mroNetValue: 1200,
  severance: 0,
  well: wells[2]
};

export const royaltyPayment13: RoyaltyPayment = {
  id: 13,
  operatorPaymentCode: '13',
  sale: sales[12],
  divisionOrder: divisionOrders[12],
  paymentDate: '01/13/2018',
  mroVolume: 13,
  mroGrossValue: 1300,
  mroDeductions: 13,
  deductions: [
    paymentDeductions[2]
  ],
  deductionObjects: [
    deductionObjects[2]
  ],
  mroNetValue: 1300,
  severance: 0,
  well: wells[3]
};

export const royaltyPayment14: RoyaltyPayment = {
  id: 14,
  operatorPaymentCode: '14',
  sale: sales[13],
  divisionOrder: divisionOrders[13],
  paymentDate: '02/14/2012',
  mroVolume: 14,
  mroGrossValue: 14,
  mroDeductions: 14,
  deductions: [
    paymentDeductions[2]
  ],
  deductionObjects: [
    deductionObjects[2]
  ],
  mroNetValue: 1002,
  severance: 0,
  well: wells[4]
};

export const royaltyPayment15: RoyaltyPayment = {
  id: 15,
  operatorPaymentCode: '15',
  sale: sales[14],
  divisionOrder: divisionOrders[14],
  paymentDate: '03/15/2013',
  mroVolume: 15,
  mroGrossValue: 15,
  mroDeductions: 15,
  deductions: [
    paymentDeductions[2]
  ],
  deductionObjects: [
    deductionObjects[2]
  ],
  mroNetValue: 2002,
  severance: 0,
  well: wells[5]
};

export const royaltyPayment16: RoyaltyPayment = {
  id: 16,
  operatorPaymentCode: '16',
  sale: sales[15],
  divisionOrder: divisionOrders[15],
  paymentDate: '04/16/2019',
  mroVolume: 16,
  mroGrossValue: 16,
  mroDeductions: 16,
  deductions: [
    paymentDeductions[3]
  ],
  deductionObjects: [
    deductionObjects[3]
  ],
  mroNetValue: 6002,
  severance: 0,
  well: wells[6]
};

export const royaltyPayment17: RoyaltyPayment = {
  id: 17,
  operatorPaymentCode: '17',
  sale: sales[16],
  divisionOrder: divisionOrders[16],
  paymentDate: '05/17/2021',
  mroVolume: 17,
  mroGrossValue: 17,
  mroDeductions: 17,
  deductions: [
    paymentDeductions[3]
  ],
  deductionObjects: [
    deductionObjects[3]
  ],
  mroNetValue: 1500,
  severance: 0,
  well: wells[7]
};

export const royaltyPayment18: RoyaltyPayment = {
  id: 18,
  operatorPaymentCode: '18',
  sale: sales[17],
  divisionOrder: divisionOrders[17],
  paymentDate: '06/18/2022',
  mroVolume: 18,
  mroGrossValue: 18,
  mroDeductions: 18,
  deductions: [
    paymentDeductions[3]
  ],
  deductionObjects: [
    deductionObjects[3]
  ],
  mroNetValue: 600,
  severance: 0,
  well: wells[8]
};

export const royaltyPayment19: RoyaltyPayment = {
  id: 19,
  operatorPaymentCode: '19',
  sale: sales[18],
  divisionOrder: divisionOrders[18],
  paymentDate: '07/19/2023',
  mroVolume: 19,
  mroGrossValue: 19,
  mroDeductions: 19,
  deductions: [
    paymentDeductions[3]
  ],
  deductionObjects: [
    deductionObjects[3]
  ],
  mroNetValue: 8777,
  severance: 0,
  well: wells[0]
};

export const royaltyPayment20: RoyaltyPayment = {
  id: 20,
  operatorPaymentCode: '20',
  sale: sales[19],
  divisionOrder: divisionOrders[19],
  paymentDate: '08/20/2023',
  mroVolume: 20,
  mroGrossValue: 20,
  mroDeductions: 20,
  deductions: [
    paymentDeductions[3]
  ],
  deductionObjects: [
    deductionObjects[3]
  ],
  mroNetValue: 2200,
  severance: 0,
  well: wells[1]
}

export const royaltyPayment21: RoyaltyPayment = {
  id: 21,
  operatorPaymentCode: '21',
  sale: sales[20],
  divisionOrder: divisionOrders[10],
  paymentDate: '09/21/2016',
  mroVolume: 21,
  mroGrossValue: 21,
  mroDeductions: 21,
  deductions: [
    paymentDeductions[3]
  ],
  deductionObjects: [
    deductionObjects[3]
  ],
  mroNetValue: 6544,
  severance: 0,
  well: wells[2]
}

export const royaltyPayment22: RoyaltyPayment = {
  id: 22,
  operatorPaymentCode: '22',
  sale: sales[21],
  divisionOrder: divisionOrders[11],
  paymentDate: '10/22/2008',
  mroVolume: 22,
  mroGrossValue: 22,
  mroDeductions: 22,
  deductions: [
    paymentDeductions[0]
  ],
  deductionObjects: [
    deductionObjects[0]
  ],
  mroNetValue: 12000,
  severance: 0,
  well: wells[3]
}

export const royaltyPayment23: RoyaltyPayment = {
  id: 23,
  operatorPaymentCode: '23',
  sale: sales[22],
  divisionOrder: divisionOrders[12],
  paymentDate: '11/23/2014',
  mroVolume: 23,
  mroGrossValue: 23,
  mroDeductions: 23,
  deductions: [
    paymentDeductions[0]
  ],
  deductionObjects: [
    deductionObjects[0]
  ],
  mroNetValue: 800,
  severance: 0,
  well: wells[4]
}

export const royaltyPayment24: RoyaltyPayment = {
  id: 24,
  operatorPaymentCode: '24',
  sale: sales[23],
  divisionOrder: divisionOrders[13],
  paymentDate: '12/24/2018',
  mroVolume: 24,
  mroGrossValue: 24,
  mroDeductions: 24,
  deductions: [
    paymentDeductions[0]
  ],
  deductionObjects: [
    deductionObjects[0]
  ],
  mroNetValue: 1500,
  severance: 0,
  well: wells[5]
}

export const royaltyPayment25: RoyaltyPayment = {
  id: 25,
  operatorPaymentCode: '25',
  sale: sales[24],
  divisionOrder: divisionOrders[14],
  paymentDate: '01/25/2022',
  mroVolume: 25,
  mroGrossValue: 25,
  mroDeductions: 25,
  deductions: [
    paymentDeductions[1]
  ],
  deductionObjects: [
    deductionObjects[1]
  ],
  mroNetValue: 3625,
  severance: 0,
  well: wells[6]
}

export const royaltyPayment26: RoyaltyPayment = {
  id: 26,
  operatorPaymentCode: '26',
  sale: sales[25],
  divisionOrder: divisionOrders[15],
  paymentDate: '02/26/2046',
  mroVolume: 26,
  mroGrossValue: 26,
  mroDeductions: 26,
  deductions: [
    paymentDeductions[1]
  ],
  deductionObjects: [
    deductionObjects[1]
  ],
  mroNetValue: 5896,
  severance: 0,
  well: wells[7]
}

export const royaltyPayment27: RoyaltyPayment = {
  id: 27,
  operatorPaymentCode: '27',
  sale: sales[26],
  divisionOrder: divisionOrders[16],
  paymentDate: '03/27/2047',
  mroVolume: 27,
  mroGrossValue: 27,
  mroDeductions: 27,
  deductions: [
    paymentDeductions[1]
  ],
  deductionObjects: [
    deductionObjects[1]
  ],
  mroNetValue: 5874,
  severance: 0,
  well: wells[8]
}

export const royaltyPayment28: RoyaltyPayment = {
  id: 28,
  operatorPaymentCode: '28',
  sale: sales[27],
  divisionOrder: divisionOrders[17],
  paymentDate: '04/28/2048',
  mroVolume: 28,
  mroGrossValue: 28,
  mroDeductions: 28,
  deductions: [
    paymentDeductions[2]
  ],
  deductionObjects: [
    deductionObjects[2]
  ],
  mroNetValue: 5621,
  severance: 0,
  well: wells[0]
}

export const royaltyPayment29: RoyaltyPayment = {
  id: 29,
  operatorPaymentCode: '29',
  sale: sales[28],
  divisionOrder: divisionOrders[18],
  paymentDate: '05/29/2049',
  mroVolume: 29,
  mroGrossValue: 29,
  mroDeductions: 29,
  deductions: [
    paymentDeductions[2]
  ],
  deductionObjects: [
    deductionObjects[2]
  ],
  mroNetValue: 6322,
  severance: 0,
  well: wells[1]
}

export const royaltyPayment30: RoyaltyPayment = {
  id: 30,
  operatorPaymentCode: '30',
  sale: sales[29],
  divisionOrder: divisionOrders[19],
  paymentDate: '/30/2050',
  mroVolume: 30,
  mroGrossValue: 30,
  mroDeductions: 30,
  deductions: [
    paymentDeductions[2]
  ],
  deductionObjects: [
    deductionObjects[2]
  ],
  mroNetValue: 8566,
  severance: 0,
  well: wells[2]
}

export const royaltyPayment31: RoyaltyPayment = {
  id: 31,
  operatorPaymentCode: '31',
  sale: sales[30],
  divisionOrder: divisionOrders[20],
  paymentDate: '06/31/2051',
  mroVolume: 31,
  mroGrossValue: 31,
  mroDeductions: 31,
  deductions: [
    paymentDeductions[2]
  ],
  deductionObjects: [
    deductionObjects[2]
  ],
  mroNetValue: 6322,
  severance: 0,
  well: wells[3]
}

export const royaltyPayment32: RoyaltyPayment = {
  id: 32,
  operatorPaymentCode: '32',
  sale: sales[31],
  divisionOrder: divisionOrders[21],
  paymentDate: '07/32/2052',
  mroVolume: 32,
  mroGrossValue: 32,
  mroDeductions: 32,
  deductions: [
    paymentDeductions[2]
  ],
  deductionObjects: [
    deductionObjects[2]
  ],
  mroNetValue: 6322,
  severance: 0,
  well: wells[4]
}

export const royaltyPayment33: RoyaltyPayment = {
  id: 33,
  operatorPaymentCode: '33',
  sale: sales[32],
  divisionOrder: divisionOrders[22],
  paymentDate: '08/33/2053',
  mroVolume: 33,
  mroGrossValue: 33,
  mroDeductions: 33,
  deductions: [
    paymentDeductions[2]
  ],
  deductionObjects: [
    deductionObjects[2]
  ],
  mroNetValue: 6322,
  severance: 0,
  well: wells[5]
}


export const royaltyPayments: RoyaltyPayment[] = [
  royaltyPayment1,
  royaltyPayment2,
  royaltyPayment3,
  royaltyPayment4,
  royaltyPayment5,
  royaltyPayment6,
  royaltyPayment7,
  royaltyPayment8,
  royaltyPayment9,
  royaltyPayment10,
  royaltyPayment11,
  royaltyPayment12,
  royaltyPayment13,
  royaltyPayment14,
  royaltyPayment15,
  royaltyPayment16,
  royaltyPayment17,
  royaltyPayment18,
  royaltyPayment19,
  royaltyPayment20,
  royaltyPayment21,
  royaltyPayment22,
  royaltyPayment23,
  royaltyPayment24,
  royaltyPayment25,
  royaltyPayment26,
  royaltyPayment27,
  royaltyPayment28,
  royaltyPayment29,
  royaltyPayment30,
  royaltyPayment31,
  royaltyPayment32,
  royaltyPayment33
];
