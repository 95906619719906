import { ResourcePriceData } from "../model/resourcePriceData";

export const naturalGasPrices1 = {
  date: new Date('2020-01-01'),
  price: 2.2,
  lastPrice: 2.1
};

export const naturalGasPrices2 = {
  date: new Date('2020-01-02'),
  price: 2.3,
  lastPrice: 2.2
};

export const naturalGasPrices3 = {
  date: new Date('2020-01-03'),
  price: 2.4,
  lastPrice: 2.3
};

export const naturalGasPrices4 = {
  date: new Date('2020-01-04'),
  price: 2.5,
  lastPrice: 2.4
};

export const naturalGasPrices = [naturalGasPrices1, naturalGasPrices2, naturalGasPrices3, naturalGasPrices4];

const transformPricesToResourcePriceData = (prices) => {
  return prices.map((priceData, index) => ({
    id: index + 1,
    readingDateTime: priceData.date.toISOString(),
    commodity: 'Natural Gas',
    price: priceData.price,
    difference: priceData.price - priceData.lastPrice
  }));
};

export const resourcePriceData: ResourcePriceData[] = transformPricesToResourcePriceData(naturalGasPrices);
