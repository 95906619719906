import { Injectable } from '@angular/core';
import { PartnerService } from './partner.service';
import { Observable } from 'rxjs';
import { Partner } from '../../../model/partner';
import { HttpService } from '../../../http.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpPartnerService extends PartnerService {
  private url: string = `${environment.apiUrl}`;

  constructor(private httpService: HttpService) {
    super();
  }

  getPartner(): Observable<Partner> {
    return this.httpService.get(`/partner`, true);
  }
}
