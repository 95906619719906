import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicClientApplication, IPublicClientApplication } from '@azure/msal-browser';
import { AzureAuthConfig, AZURE_AUTH_CONFIG } from './models/config';
import { MsalModule, MsalService, MSAL_INSTANCE} from '@azure/msal-angular';

export function MSALInstanceFactory(config: AzureAuthConfig): IPublicClientApplication {
  return new PublicClientApplication(config.configuration);
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MsalModule
  ],
  providers: [
    MsalService
  ],
})
export class AzureAuthModule {
  static forRoot(config: AzureAuthConfig): ModuleWithProviders<AzureAuthModule> {
    return {
      ngModule: AzureAuthModule,
      providers: [
        { provide: AZURE_AUTH_CONFIG, useValue: config },
        {
          provide: MSAL_INSTANCE,
          useFactory: MSALInstanceFactory,
          deps: [AZURE_AUTH_CONFIG]
        },
      ]
    };
  }
}
