<app-header></app-header>

<div id="mro_content">
  <div class="mobile_container">
    <div class="card">
      <div class="card-header">
        <h3>Register User</h3>
      </div>
      <div class="card-body">
        <form (ngSubmit)="registerUser()">
          <div class="form-group">
            <label for="email">Email:</label>
            <input type="email" id="email" name="email" class="form-control" [(ngModel)]="user.email" required>
          </div>

          <div class="form-group">
            <label>Roles:</label>
            <div>
              <label for="mineralRights">Mineral Rights Owner</label>
              <input type="checkbox" id="mineralRights" name="mineralRights" [(ngModel)]="user.mineralRights">
            </div>
            <div>
              <label for="multiAccountManager">Multi Account Manager</label>
              <input type="checkbox" id="multiAccountManager" name="multiAccountManager" [(ngModel)]="user.multiAccountManager" (change)="clearMultiAccount()">
              <ng-container *ngIf="user.multiAccountManager">
                <label for="multiAccountDropdown">Select Accounts:</label>
                <select id="multiAccountDropdown" name="multiAccountDropdown" class="form-control" multiple [(ngModel)]="user.selectedAccounts">
                  <option value="1">Account 1</option>
                  <option value="2">Account 2</option>
                </select>
              </ng-container>
            </div>
            <div>
              <label for="operator">Operator</label>
              <input type="checkbox" id="operator" name="operator" [(ngModel)]="user.operator">
              <ng-container *ngIf="user.operator">
                <label for="operatorDropdown">Select Operator:</label>
                <select id="operatorDropdown" name="operatorDropdown" class="form-control" [(ngModel)]="user.selectedOperator" (change)="clearOperator()">
                  <option value="operator1">Operator 1</option>
                  <option value="operator2">Operator 2</option>
                </select>
              </ng-container>
            </div>
          </div>

          <button type="submit" class="btn btn-primary">Submit</button>
        </form>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
