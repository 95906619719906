<div id="mro_markets">
  <div class="mobile_container">
    <div id="mro_markets_inner">
      <h1 class="mro_title">Markets</h1>

      <div class="mro_content_inner">
        <div class="col_group centered">
          <div class="col_item">
            <app-spinner *ngIf="oilLoading" [sectionType]="'marketOil'"></app-spinner>
            <div class="market_object" id="market_oil" *ngIf="!oilLoading && oilComplete">
              <div class="market_icon">
                <div class="market_icon_main">
                  <span class="icon_production"></span>
                </div>
                <div class="market_icon_shadow">
                  <span class="icon_production_shadow"></span>
                </div>
              </div>
              <div class="market_price">${{crudeOilPrice}}</div>
              <div class="market_change">
                <span class="change_value {{crudeOilPriceChanged < 0 ? 'down' : 'up'}}">
                  {{ ( crudeOilPriceChanged < 0 ? crudeOilPriceChanged*(-1) : crudeOilPriceChanged )
                    | number: "1.2-2":"en-US" }}c
                </span>
              </div>
              <div class="market_title">Crude Oil</div>
              <span class="font-italic small">({{crudeOilPriceDate | date: 'dd MMM yyyy'}})</span>
            </div>
          </div>



          <div *ngIf="!oilFailed">
            <div class="error_container">
              <div class="align_center">
                <app-retry [parentType]="'oil'" (retryOil)="retryOil()"></app-retry>
              </div>
            </div>
          </div>

          <div class="col_item">
            <app-spinner *ngIf="gasLoading" [sectionType]="'marketGas'"></app-spinner>
            <div class="market_object" id="market_gas" *ngIf="!gasLoading && gasComplete">
              <div class="market_icon">
                <div class="market_icon_main">
                  <span class="icon_gas"></span>
                </div>
                <div class="market_icon_shadow">
                  <span class="icon_gas_shadow"></span>
                </div>
              </div>
              <div class="market_price">${{naturalGasPrice | number: "1.2-2":"en-US"}}</div>
              <div class="market_change">
                <span class="change_value {{naturalGasPriceChanged < 0 ? 'down' : 'up'}}">
                  {{ ( naturalGasPriceChanged < 0 ? naturalGasPriceChanged*(-1) : naturalGasPriceChanged )
                    | number: "1.2-2":"en-US" }}c
                </span>
              </div>
              <div class="market_title">Gas</div>
              <span class="font-italic small">({{naturalGasPriceDate | date: 'dd MMM yyyy'}})</span>
            </div>
          </div>

          <div *ngIf="!oilFailed">
            <div class="error_container">
              <div class="align_center">
                <app-retry [parentType]="'gas'" (retryGas)="retryGas()"></app-retry>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
