import { Component, Input } from '@angular/core';
import { StorageService } from '../../../../core/services/storage/storage.service';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from '../../../spinner/spinner.component';

@Component({
  selector: 'app-data-card',
  standalone: true,
  imports: [
    CommonModule,
    SpinnerComponent
  ],
  templateUrl: './data-card.component.html',
  styleUrl: './data-card.component.css'
})
export class DataCardComponent {
  @Input() sectionType?: string;
  @Input() period?: string;
  @Input() isDateRange?: boolean;

  constructor(
    private storageService: StorageService
  ) { }

  get isMobile() {
    return window.innerWidth <= 768;
  }

  get chartData() {
    switch (this.sectionType) {
      case 'payment':
        return this.storageService.getMroPaymentSummary();
      case 'barchart':
        return this.storageService.getMroPaymentSummaryByPeriod();
    }

  }

  get loading() {
    return !this.chartData
  }


}
