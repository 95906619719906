import { Partner } from "../model/partner";

// URLs for the royalty-free logos
const logo1 = 'assets/images/factory-illustration-industrial-svgrepo-com.svg';
const logo2 = 'assets/images/business-factory-illustration-svgrepo-com.svg';
const logo3 = 'assets/images/cooling-factory-illustration-svgrepo-com.svg';

export const partner1: Partner = {
  abbr: 'P1',
  name: 'Partner 1',
  logo: logo1, 
  primaryColor: '#ffffff', // Example primary color
  secondaryColor: '#000000' // Example secondary color
};

export const partner2: Partner = {
  abbr: 'P2',
  name: 'Partner 2',
  logo: logo2, 
  primaryColor: '#ff0000', 
  secondaryColor: '#00ff00' 
};

export const partner3: Partner = {
  abbr: 'P3',
  name: 'Partner 3',
  logo: logo3, 
  primaryColor: '#0000ff', 
  secondaryColor: '#ffff00' 
};

export const mockPartners: Partner[] = [
  partner1,
  partner2,
  partner3
];
