import { Operator } from "../model/operator";
import { getRandomInt } from "./helpers/randomNumbersHelper";
import { counties, lovingTX } from './counties';

export  const operator1: Operator = {
  id: 1,
  propertyNo: '775008 O0001',
  name: 'CONOCOPHILLIPS Test',
  description: '1/10TH OF1/2 OF AN UNDIVIDED 20.25/650TH INTEREST IN ALL OF THE OIL, GAS, AND OTHER MINERALS IN, ON, UNDER OR THAT MAY BE PRODUCED FROM ALL OF SECTION 4, BLOCK C-27, PUBLIC SCHOOL LAND SURVEY, ABSTRACT 771, IN LOVING COUNTY, TEXAS.  ',
  geographicalData: {
    county: lovingTX,
    section: 'Section 4',
    sectionNo: '4',
    block: 'C-27',
    township: '',
    range: '',
    surveyName: 'PUBLIC SCHOOL LAND SURVEY',
    abstract: 'ABSTRACT 771',
    grossAcreage: 640,
    netAcreage: 2.99077,
    insertBy: getRandomInt(1, 1000000),
    insertDate: new Date(),
    modifyBy: getRandomInt(1, 1000000),
    modifyDate: new Date()
  },
  efectiveFrom: new Date()
};

export  const operator2: Operator = {
  id: 2,
  propertyNo: '775008 O0001',
  name: 'CONOCOPHILLIPS',
  description: '1/10TH OF1/2 OF AN UNDIVIDED 20.25/650TH INTEREST IN ALL OF THE OIL, GAS, AND OTHER MINERALS IN, ON, UNDER OR THAT MAY BE PRODUCED FROM ALL OF SECTION 4, BLOCK C-27, PUBLIC SCHOOL LAND SURVEY, ABSTRACT 771, IN LOVING COUNTY, TEXAS.  ',
  geographicalData: {
    county: lovingTX,
    section: 'Section 4',
    sectionNo: '4',
    block: 'C-27',
    township: '',
    range: '',
    surveyName: 'PUBLIC SCHOOL LAND SURVEY',
    abstract: 'ABSTRACT 771',
    grossAcreage: 640,
    netAcreage: 2.99077,
    insertBy: getRandomInt(1, 1000000),
    insertDate: new Date(),
    modifyBy: getRandomInt(1, 1000000),
    modifyDate: new Date()
  },
  efectiveFrom: new Date()
};

export  const operator3: Operator = {
  id: 3,
  propertyNo: 'Property No 3',
  name: 'Laramie Energy Company II',
  description: 'Description 3',
  geographicalData: {
    county: counties[getRandomInt(0, counties.length - 1)],
    section: 'Section 3',
    sectionNo: 'Section No 3',
    block: 'Block 3',
    township: 'Township 3',
    range: 'Range 3',
    surveyName: 'Survey Name 3',
    abstract: 'Abstract 3',
    grossAcreage: getRandomInt(1, 1000000),
    netAcreage: getRandomInt(1, 1000000),
    insertBy: getRandomInt(1, 1000000),
    insertDate: new Date(),
    modifyBy: getRandomInt(1, 1000000),
    modifyDate: new Date()
  },
  efectiveFrom: new Date()
};

export  const operator4: Operator = {
  id: 4,
  propertyNo: 'Property No 4',
  name: 'North Shale Energy Company',
  description: 'Description 4',
  geographicalData: {
    county: counties[getRandomInt(0, counties.length - 1)],
    section: 'Section 4',
    sectionNo: 'Section No 4',
    block: 'Block 4',
    township: 'Township 4',
    range: 'Range 4',
    surveyName: 'Survey Name 4',
    abstract: 'Abstract 4',
    grossAcreage: getRandomInt(1, 1000000),
    netAcreage: getRandomInt(1, 1000000),
    insertBy: getRandomInt(1, 1000000),
    insertDate: new Date(),
    modifyBy: getRandomInt(1, 1000000),
    modifyDate: new Date()
  },
  efectiveFrom: new Date()
};

export  const operator5: Operator = {
  id: 5,
  propertyNo: 'Property No 5',
  name: 'Peterson Energy Company',
  description: 'Description 5',
  geographicalData: {
    county: counties[getRandomInt(0, counties.length - 1)],
    section: 'Section 5',
    sectionNo: 'Section No 5',
    block: 'Block 5',
    township: 'Township 5',
    range: 'Range 5',
    surveyName: 'Survey Name 5',
    abstract: 'Abstract 5',
    grossAcreage: getRandomInt(1, 1000000),
    netAcreage: getRandomInt(1, 1000000),
    insertBy: getRandomInt(1, 1000000),
    insertDate: new Date(),
    modifyBy: getRandomInt(1, 1000000),
    modifyDate: new Date()
  },
  efectiveFrom: new Date()
};

export const operator6: Operator = {
  id: 6,
  propertyNo: 'Property No 6',
  name: 'Wildcat Energy Company',
  description: 'Description 6',
  geographicalData: {
    county: counties[getRandomInt(0, counties.length - 1)],
    section: 'Section 6',
    sectionNo: 'Section No 6',
    block: 'Block 6',
    township: 'Township 6',
    range: 'Range 6',
    surveyName: 'Survey Name 6',
    abstract: 'Abstract 6',
    grossAcreage: getRandomInt(1, 1000000),
    netAcreage: getRandomInt(1, 1000000),
    insertBy: getRandomInt(1, 1000000),
    insertDate: new Date(),
    modifyBy: getRandomInt(1, 1000000),
    modifyDate: new Date()
  },
  efectiveFrom: new Date()
};

export const operator7: Operator = {
  id: 7,
  propertyNo: 'Property No 7',
  name: 'Petro Energy Company',
  description: 'Description 7',
  geographicalData: {
    county: counties[getRandomInt(0, counties.length - 1)],
    section: 'Section 7',
    sectionNo: 'Section No 7',
    block: 'Block 7',
    township: 'Township 7',
    range: 'Range 7',
    surveyName: 'Survey Name 7',
    abstract: 'Abstract 7',
    grossAcreage: getRandomInt(1, 1000000),
    netAcreage: getRandomInt(1, 1000000),
    insertBy: getRandomInt(1, 1000000),
    insertDate: new Date(),
    modifyBy: getRandomInt(1, 1000000),
    modifyDate: new Date()
  },
  efectiveFrom: new Date()
};

export const operators: Operator[] = [
  operator1,
  operator2,
  operator3,
  operator4,
  operator5,
  operator6,
  operator7
];
