import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Operator } from '../../model/operator';
import { StatementSearch } from '../../model/statement_search';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-operator-dropdown',
  templateUrl: './operator-dropdown.component.html',
  styleUrl: './operator-dropdown.component.css'
})
export class OperatorDropdownComponent {
  @Input() operators: Operator[];
  @Input() statementSearch: StatementSearch;
  @Input() statementForm: FormGroup;
  @Output() filterStatements: EventEmitter<string> = new EventEmitter<string>();

  public onOperatorChange() {
    this.statementSearch.operatorId = this.statementForm.get('operatorId').value === '' ? 0 : this.statementForm.get('operatorId').value;
    this.filterStatements.emit('filter');
  }

}
