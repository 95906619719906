import { DataVerificationService } from './../dataVerification/data-verification.service';
import { Injectable } from '@angular/core';
import { PaymentSummary, PaymentSummaryByPeriodSeriesData, PaymentSummaryData } from '../../../model/payment-summary';
import { PieChartData } from '../../../model/chart-data';
import { ChartDataProcessingService } from '../chartDataProcessing/chart-data-processing.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private mroPaymentSummaryByMonth: PaymentSummary[] = [];
  private mroPaymentSummaryByYear: PaymentSummary[] = [];
  private mroPaymentSummaryByQuarter: PaymentSummary[] = [];
  private isMroPaymentSummaryByMonthValid: boolean = false;
  private isMroPaymentSummaryByYearValid: boolean = false;
  private isMroPaymentSummaryByQuarterValid: boolean = false;

  private mroPaymentSummaryByProperty: PaymentSummaryData[] = [];
  private isMroPaymentSummaryByPropertyValid: boolean = false;

  private mroPaymentSummaryByProduct: PaymentSummaryData[] = [];
  private isMroPaymentSummaryByProductValid: boolean = false;

  private mroPaymentSummaryByPeriod: PaymentSummary;
  private mroPaymentSummaryByPeriodDataObjects: PieChartData[] = [];
  private isMroPaymentSummaryByPeriodValid: boolean = false;

  private mroPaymentSummaryByPeriodByProperty: PaymentSummaryByPeriodSeriesData[] = [];
  private isMroPaymentSummaryByPeriodByProperty: boolean = false;

  private mroPaymentSummaryByPeriodByProduct: PaymentSummaryByPeriodSeriesData[] = [];
  private isMroPaymentSummaryByPeriodByProduct: boolean = false;

  private mroPaymentSummaryByPeriodByPropertyByMonthOnly: PaymentSummaryByPeriodSeriesData[] = [];
  private isMroPaymentSummaryByPeriodByPropertyByMonthOnlyValid: boolean = false;

  private mroPaymentSummaryByPeriodByProductByMonthOnly: PaymentSummaryByPeriodSeriesData[] = [];
  private isMroPaymentSummaryByPeriodByProductByMonthOnlyValid: boolean = false;

  private mroPaymentSummary: PaymentSummary;
  private isMroPaymentSummaryValid: boolean = false;
  private mroPaymentSummaryDataObjects: PieChartData[] = [];


  constructor(
    private chartDataService: ChartDataProcessingService,
    private dataVerificationService: DataVerificationService
  ) { }

  setMroPaymentSummary(paymentSummary: PaymentSummary): void {
    this.mroPaymentSummaryDataObjects = this.chartDataService.getPaymentSummaryDataObjects(paymentSummary);
    this.isMroPaymentSummaryValid = this.dataVerificationService.validatePieChartData(this.mroPaymentSummaryDataObjects);
    this.mroPaymentSummary = paymentSummary;
  }

  getMroPaymentSummary(): PaymentSummary {
    return this.mroPaymentSummary;
  }

  getIsMroPaymentSummaryValid(): boolean {
    return this.isMroPaymentSummaryValid;
  }

  getMroPaymentSummaryDataObjects(): PieChartData[] {
    return this.mroPaymentSummaryDataObjects;
  }

  setMroPaymentSummaryByPeriodByPropertyByMonthOnly(paymentSummary: PaymentSummaryByPeriodSeriesData[]): void {
    this.isMroPaymentSummaryByPeriodByPropertyByMonthOnlyValid = this.dataVerificationService.validateSeriesData(paymentSummary);
    this.mroPaymentSummaryByPeriodByPropertyByMonthOnly = paymentSummary;
  }

  getMroPaymentSummaryByPeriodByPropertyByMonthOnly(): PaymentSummaryByPeriodSeriesData[] {
    return this.mroPaymentSummaryByPeriodByPropertyByMonthOnly;
  }

  getIsMroPaymentSummaryByPeriodByPropertyByMonthOnlyValid(): boolean {
    return this.isMroPaymentSummaryByPeriodByPropertyByMonthOnlyValid;
  }

  setMroPaymentSummaryByPeriodByProductByMonthOnly(paymentSummary: PaymentSummaryByPeriodSeriesData[]): void {
    this.isMroPaymentSummaryByPeriodByProductByMonthOnlyValid = this.dataVerificationService.validateSeriesData(paymentSummary);
    this.mroPaymentSummaryByPeriodByProductByMonthOnly = paymentSummary;
  }

  getMroPaymentSummaryByPeriodByProductByMonthOnly(): PaymentSummaryByPeriodSeriesData[] {
    return this.mroPaymentSummaryByPeriodByProductByMonthOnly;
  }

  getIsMroPaymentSummaryByPeriodByProductByMonthOnlyValid(): boolean {
    return this.isMroPaymentSummaryByPeriodByProductByMonthOnlyValid;
  }

  setMroPaymentSummaryByPeriod(paymentSummary: PaymentSummary): void {
    this.isMroPaymentSummaryByPeriodValid = this.dataVerificationService.validatePieChartData(paymentSummary.data);
    this.mroPaymentSummaryByPeriod = paymentSummary;
    this.mroPaymentSummaryByPeriodDataObjects = this.chartDataService.getPaymentSummaryDataObjects(this.mroPaymentSummaryByPeriod);
  }

  getMroPaymentSummaryByPeriod(): PaymentSummary {
    return this.mroPaymentSummaryByPeriod;
  }

  getIsMroPaymentSummaryByPeriodValid(): boolean {
    return this.isMroPaymentSummaryByPeriodValid;
  }

  getMroPaymentSummaryByPeriodDataObjects(): PieChartData[] {
     {
      return this.mroPaymentSummaryByPeriodDataObjects;
    }
  }

  setMroPaymentSummaryByPeriodByProperty(paymentSummary: PaymentSummaryByPeriodSeriesData[]): void {
    this.isMroPaymentSummaryByPeriodByProperty = this.dataVerificationService.validateSeriesData(paymentSummary);
    this.mroPaymentSummaryByPeriodByProperty = paymentSummary;
  }

  getMroPaymentSummaryByPeriodByProperty(): PaymentSummaryByPeriodSeriesData[] {
    {
        return this.mroPaymentSummaryByPeriodByProperty;
    }
  }

  getIsMroPaymentSummaryByPeriodByPropertyValid(): boolean {
    return this.isMroPaymentSummaryByPeriodByProperty;
  }

  setMroPaymentSummaryByPeriodByProduct(paymentSummary: PaymentSummaryByPeriodSeriesData[]): void {
    this.isMroPaymentSummaryByPeriodByProduct = this.dataVerificationService.validateSeriesData(paymentSummary);
    this.mroPaymentSummaryByPeriodByProduct = paymentSummary;
  }

  getMroPaymentSummaryByPeriodByProduct(): PaymentSummaryByPeriodSeriesData[] {
    {

        return this.mroPaymentSummaryByPeriodByProduct;
    }
  }

  getIsMroPaymentSummaryByPeriodByProductValid(): boolean {
    return this.isMroPaymentSummaryByPeriodByProduct;
  }

    getMroPaymentSummaryByPeriodByProduct2(): PaymentSummaryByPeriodSeriesData[] {
    return this.mroPaymentSummaryByPeriodByProduct;
  }

  setMroPaymentSummaryByMonth(paymentSummary: PaymentSummary[]): void {
    this.isMroPaymentSummaryByMonthValid = this.dataVerificationService.validatePieChartData(paymentSummary as unknown as PieChartData[]);
    this.mroPaymentSummaryByMonth = paymentSummary;
  }

  getMroPaymentSummaryByMonth(): PaymentSummary[] {
    return this.mroPaymentSummaryByMonth;
  }

  getIsMroPaymentSummaryByMonthValid(): boolean {
    return this.isMroPaymentSummaryByMonthValid;
  }

  setMroPaymentSummaryByYear(paymentSummary: PaymentSummary[]): void {
    this.isMroPaymentSummaryByYearValid = this.dataVerificationService.validatePieChartData(paymentSummary as unknown as PieChartData[]);
    this.mroPaymentSummaryByYear = paymentSummary;
  }

  getMroPaymentSummaryByYear(): PaymentSummary[] {
    return this.mroPaymentSummaryByYear;
  }

  getIsMroPaymentSummaryByYearValid(): boolean {
    return this.isMroPaymentSummaryByYearValid;
  }

  setMroPaymentSummaryByQuarter(paymentSummary: PaymentSummary[]): void {
    this.isMroPaymentSummaryByQuarterValid = this.dataVerificationService.validatePieChartData(paymentSummary as unknown as PieChartData[]);
    this.mroPaymentSummaryByQuarter = paymentSummary;
  }

  getMroPaymentSummaryByQuarter(): PaymentSummary[] {
    return this.mroPaymentSummaryByQuarter;
  }

  getIsMroPaymentSummaryByQuarterValid(): boolean {
    return this.isMroPaymentSummaryByQuarterValid;
  }

  // getPaymentSummaryMonthDataObjects(): PieChartData[] {
  //   return this.chartDataService.getPaymentSummaryDataObjects(this.mroPaymentSummaryByMonth);
  // }

  // getPaymentSummaryYearDataObjects(): PieChartData[] {
  //   return this.chartDataService.getPaymentSummaryDataObjects(this.mroPaymentSummaryByYear);
  // }

  // getPaymentSummaryQuarterlyDataObjects(): PieChartData[] {
  //   return this.chartDataService.getPaymentSummaryDataObjects(this.mroPaymentSummaryByQuarter);
  // }

  setMroPaymentSummaryByProperty(paymentSummary: PaymentSummaryData[]): void {
    this.isMroPaymentSummaryByPropertyValid = this.dataVerificationService.validatePieChartData(paymentSummary);
    this.mroPaymentSummaryByProperty = paymentSummary;
  }

  getMroPaymentSummaryByProperty(): PaymentSummaryData[] {
    {

      return this.mroPaymentSummaryByProperty;
    }
  }

  getIsMroPaymentSummaryByPropertyValid(): boolean {
    return this.isMroPaymentSummaryByPropertyValid;
  }

  setMroPaymentSummaryByProduct(paymentSummary: PaymentSummaryData[]): void {
    this.isMroPaymentSummaryByProductValid = this.dataVerificationService.validatePieChartData(paymentSummary);
    this.mroPaymentSummaryByProduct = paymentSummary;
  }

  getMroPaymentSummaryByProduct(): PaymentSummaryData[] {
   {
      return this.mroPaymentSummaryByProduct;
    }
  }

  getIsMroPaymentSummaryByProductValid(): boolean {
    return this.isMroPaymentSummaryByProductValid;
  }

  getPaymentSummaryByPeriodDataObjects(): PieChartData[] {
    return this.chartDataService.getPaymentSummaryDataObjects(this.mroPaymentSummaryByPeriod);
  }

}
