import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Product } from '../../../model/product';
import { products } from '../../../mockDB/products';

@Injectable({
  providedIn: 'root'
})
export class MockProductService {

  constructor() { }

  getProducts(): Observable<Product[]> {
    return of(products);
  }
}
