import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../core/services/auth/auth.service';
import { User } from '../../model/user';
import { Observable, Subject, catchError, finalize, of, takeUntil } from 'rxjs';
import { HttpService } from '../../http.service';
import { AuthenticationResult } from '@azure/msal-browser';
import { AzureAuthService } from '../../modules/azure-auth/services/azure-auth/azure-auth.service';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  private unsubscribe$ = new Subject<void>();
  private isMock = environment.mock;

  public errorMessage = '';
  public loginUserData: User = {
    email: '',
    fname: '',
    lname: '',
  }

  private unsubscribe = new Subject();

  constructor(
    private _auth: AuthService,
    private azureAuthService: AzureAuthService,
    private httpService: HttpService,
    private msalService: MsalService,
    private _router: Router) { }

    ngOnInit(): void {
      this.msalService.initialize().toPromise().catch((error: unknown) => {
        console.error("Error initializing MSAL:", error);
      });
    }

    loginUser() {
      this.loginUserData.roleId = environment.ROLE_BANK;
        const authResult = JSON.parse(sessionStorage.getItem("userData")) as AuthenticationResult;
        this._auth.loginUser(this.loginUserData.email, authResult.idToken ).pipe(
          takeUntil(this.unsubscribe$),
          finalize(() => {
            this._router.navigate(['/dashboard']);
          })
        )
          .subscribe(
            (user: User) => {
              this._auth.setUser(user);
              sessionStorage.setItem('user', JSON.stringify(user));
              this.httpService.setUserToken(user.token);
              this._auth.setToken(user.token);
            }
          );
    }

    userAuth(): void {
      if (this.isMock) {
        sessionStorage.setItem('user', 'user' );
        sessionStorage.setItem('token', 'token');
        this._router.navigate(['/dashboard']);
      } else {
        this.callMsalLogin();
      }
    }


  private callMsalLogin() {
    this.azureAuthService.logInPopUp().pipe(
      takeUntil(this.unsubscribe$),
      catchError((error: Observable<User>) => {
        this.errorMessage = 'Invalid login credentials';
        console.error('Error logging in:', error);
        return of(null);
      })
    )
      .subscribe(
        (authResult: AuthenticationResult) => {
          if (authResult) {
            this._auth.setToken(authResult.idToken);
            this.loginUserData.email = authResult.account.username;
            sessionStorage.setItem("userData", JSON.stringify(authResult));
            this.loginUser();
          }
        }

      );
  }
}
