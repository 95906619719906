/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uniqueOperators'
})
export class UniqueOperatorsPipe implements PipeTransform {
  transform(wells: any[]): any[] {
    const uniqueOperators = wells
      .map(well => well.operator)
      .filter((operator, index, array) => array.findIndex(op => op.name === operator.name) === index);
      console.log(uniqueOperators);
    return uniqueOperators;
  }
}
