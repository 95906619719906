import {Component, OnInit} from '@angular/core';
import {Router, RouterModule} from '@angular/router';
import {Payment, PaymentLoadStatus} from '../../../model/payments';
import {PaymentsService} from '../../../core/services/payments/payments.service';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from '../../spinner/spinner.component';
import { RetryComponent } from '../retry/retry.component';
import moment from 'moment';

@Component({
  selector: 'app-payments',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SpinnerComponent,
    RetryComponent
  ],
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css']
})
export class PaymentsComponent implements OnInit {
  public payments: Payment[];

  constructor(
    private router: Router,
    private paymentService: PaymentsService,
  ) { }

  ngOnInit() {

  }

  onPaymentClick(payment: Payment) {
    this.paymentService.setCurrentPayment(payment);
  }

  public viewAllPayments() {
    this.router.navigate(['/payments']);
  }
  public getUtcDateString(date: Date): string {
    return moment(date).utc().format('MMM DD, YYYY');
  }

  get recentPayments() {
    let recentPayments: Payment[];
    this.payments = this.paymentService.getRecentPayments();
    if (this.payments.length > 0 ) {
      this.payments.sort((a, b) =>
        new Date(b.paymentDate).getTime() - new Date(a.paymentDate).getTime()
      );
      recentPayments = this.payments.slice(0, 3);
    }
    return recentPayments
  }

  get loading() {
    if (this.paymentService.getRecentPaymentLoadStatus() === PaymentLoadStatus.COMPLETE || this.paymentService.getPaymentLoadStatus() === PaymentLoadStatus.FAILED){
      return false;
    } else if (this.paymentService.getRecentPaymentLoadStatus() === PaymentLoadStatus.NONE) {
      return true;
    }
  }

  get failed() {
    if (this.paymentService.getRecentPaymentLoadStatus() === PaymentLoadStatus.FAILED){
      return true;
    } else {
      return false;
    }
  }

}
