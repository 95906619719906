import { Injectable } from '@angular/core';
import { PropertyService } from './property.service';
import { HttpService } from '../../../http.service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { Property } from '../../../model/property';

@Injectable({
  providedIn: 'root'
})
export class HttpPropertyService extends PropertyService {

  private url: string = environment.apiUrl;

  constructor(
    private httpService: HttpService
  ) {
    super();
  }

  getMroProperties(): Observable<Property[]> {
    return this.httpService.get(`/mro/getMroProperties`, true);
  }
}
