<div>

  <!-- Dashboard Summary -->
  <app-dashboard-summary></app-dashboard-summary>

  <!-- MRO Payments -->
  <app-payments></app-payments>

  <!-- MRO Recent Activity -->
  <app-recent-activity></app-recent-activity>

  <!-- MRO Markets -->
  <app-markets></app-markets>

</div>
