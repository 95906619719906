import { MineralRightsOwner, MroStatus } from "../model/mineralRightsOwner";
import { randomDateMinOrMax } from "./helpers/randomDateHelper";
import { getRandomInt } from "./helpers/randomNumbersHelper";
import { us_states } from "./states";

export const mineralRightsOwner1: MineralRightsOwner =
  {
    id: 1,
    ownername: 'Michael Wilderman',
    fname: 'Michael',
    lname: 'Wilderman',
    suffix: 'suffix1',
    taxNumber: '0555-833-4211',
    address1: '123 Main St.',
    address2: 'Apt. 1',
    city: 'Karnes',
    stateName: us_states[getRandomInt(0, us_states.length - 1)].name,
    stateId: us_states[getRandomInt(0, us_states.length - 1)].id,
    zipCode: '1265',
    tel: '0555-833-4211',
    fax: '0556-605-7645',
    cell: '0790-737-6672',
    websiteUrl: 'websiteUrl1',
    status: MroStatus.Initialized,
    operatorMappings: [],
    insertBy: 1,
    insertDate: randomDateMinOrMax(2019, 2020, false),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2020, 2023, false),
  }

export const mineralRightsOwner2: MineralRightsOwner =
  {
    id: 2,
    ownername: 'Nathan Lee',
    fname: 'Nathan',
    lname: 'Lee',
    suffix: 'suffix2',
    taxNumber: '0555-124-6227',
    address1: '123 Main St.',
    address2: 'Apt. 2',
    city: 'Lavaca',
    stateName: us_states[getRandomInt(0, us_states.length - 1)].name,
    stateId: us_states[getRandomInt(0, us_states.length - 1)].id,
    zipCode: '1254',
    tel: '0555-124-6227',
    fax: '0556-738-8259',
    cell: '0799-088-7009',
    websiteUrl: 'websiteUrl2',
    status: MroStatus.Initialized,
    operatorMappings: [],
    insertBy: 2,
    insertDate: randomDateMinOrMax(2019, 2020, false),
    modifyBy: 2,
    modifyDate: randomDateMinOrMax(2020, 2023, false),
  }

export const mineralRightsOwner3: MineralRightsOwner =
  {
    id: 3,
    ownername: 'Mathew Curtis',
    fname: 'Mathew',
    lname: 'Curtis',
    suffix: 'suffix3',
    taxNumber: '0555-240-0999',
    address1: '123 Middle Square',
    address2: 'Apt. 3',
    city: 'Midland',
    stateName: us_states[getRandomInt(0, us_states.length - 1)].name,
    stateId: us_states[getRandomInt(0, us_states.length - 1)].id,
    zipCode: '1239',
    tel: '0555-240-0999',
    fax: '0556-778-5904',
    cell: '0796-319-7422',
    websiteUrl: 'websiteUrl3',
    status: MroStatus.Initialized,
    operatorMappings: [],
    insertBy: 3,
    insertDate: randomDateMinOrMax(2019, 2020, false),
    modifyBy: 3,
    modifyDate: randomDateMinOrMax(2020, 2023, false),
  }

export const mineralRightsOwners: MineralRightsOwner[] = [
  mineralRightsOwner1,
  mineralRightsOwner2,
  mineralRightsOwner3
];
