import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResourcePriceData } from '../../../model/resourcePriceData';

@Injectable({
  providedIn: 'root'
})
export abstract class MarketService {

  abstract getMarketPrice(resource: string): Observable<ResourcePriceData>;

}
