import { DeductionType } from "../model/deduction_type";

export  const deductionType1: DeductionType = {
  id: 1,
  name: 'Deduction Type 1'
};

export  const deductionType2: DeductionType = {
  id: 2,
  name: 'Deduction Type 2'
};

export  const deductionType3: DeductionType = {
  id: 3,
  name: 'Deduction Type 3'
};

export  const deductionType4: DeductionType = {
  id: 4,
  name: 'Deduction Type 4'
};

export  const deductionType5: DeductionType = {
  id: 5,
  name: 'Deduction Type 5'
};

export const deductionType6: DeductionType = {
  id: 6,
  name: 'Deduction Type 6'
};

export const deductionTypes: DeductionType[] = [
  deductionType1,
  deductionType2,
  deductionType3,
  deductionType4,
  deductionType5,
  deductionType6
];
