import { MatIconModule } from '@angular/material/icon';
import { TokenComponent } from './utilities/token/token.component';
import { OperatorDropdownComponent } from './components/operator-dropdown/operator-dropdown.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, isDevMode } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app/app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { DivisionordersComponent } from './components/divisionorders/divisionorders.component';
import { ContactUsComponent } from './components/contactUs/contact-us.component';
import { ProfileComponent } from './components/profile/profile.component';
import { PropertiesComponent } from './components/properties/properties.component';
import { StatementComponent } from './components/statement/statement.component';
import { DivisionorderDetailsComponent } from './components/divisionorder-details/divisionorder-details.component';
import { LoginComponent } from './components/login/login.component';
import { TokenInterceptorService } from './core/services/token-interceptor/token-interceptor.service';
import { AllPaymentsComponent } from './components/all-payments/all-payments.component';
import { environment } from '../environments/environment';
import { DummyImageComponent } from './components/dashboard/dummy-image/dummy-image.component';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import {
  MsalGuard, MsalBroadcastService, MsalModule, MsalService,
  MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalGuardConfiguration
} from '@azure/msal-angular';

import { msalConfig, loginRequest } from './auth-config';
import { RegistrationComponent } from './components/registration/registration.component';
import { AdminDashboardComponent } from './components/dashboard/admin-dashboard/admin-dashboard.component';
import { CoreModule } from './core/core.module';
import { MockMsalGuard } from './guards/MockMSALGuard';
import { StatementViewComponent } from './components/statement-view/statement-view.component';
import { GridViewComponent } from './components/grid-view/grid-view.component';
import { StatementFooterComponent } from './components/statement-footer/statement-footer.component';
import { StatementFooterPaidComponent } from './components/statement-footer-paid/statement-footer-paid.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderInterceptor } from './utilities/headerInterceptor';
import { LoaderModule } from './model/loader/loader.module';
import { ModalModule } from './libraries/modal/modal.module';
import { DashboardSummaryComponent } from './components/dashboard/dashboard-summary/dashboard-summary';
import { AzureAuthService } from './modules/azure-auth/services/azure-auth/azure-auth.service';
import { AzureAuthModule } from './modules/azure-auth/azure-auth.module';
import { AZURE_AUTH_CONFIG } from './modules/azure-auth/models/config';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { JwtModule } from '@auth0/angular-jwt';
import { TooltipContentComponent } from '@swimlane/ngx-charts';
import { AuthGuard, MockAuthGuard } from './guards/auth/auth.guard';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UniqueOperatorsPipe } from './pipes/uniqueOpertors';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
      interactionType: InteractionType.Popup,
      authRequest: loginRequest,
      loginFailedRoute: '/login'
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    DivisionordersComponent,
    ContactUsComponent,
    ProfileComponent,
    PropertiesComponent,
    StatementComponent,
    DivisionorderDetailsComponent,
    LoginComponent,
    AllPaymentsComponent,
    DummyImageComponent,
    RegistrationComponent,
    AdminDashboardComponent,
    StatementViewComponent,
    GridViewComponent,
    StatementFooterComponent,
    StatementFooterPaidComponent,
    OperatorDropdownComponent,
    TokenComponent,
    UniqueOperatorsPipe

  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    MsalModule,
    CoreModule,
    BrowserAnimationsModule,
    LoaderModule,
    ModalModule,
    DashboardSummaryComponent,
    AzureAuthModule,
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    NgIdleKeepaliveModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return sessionStorage.getItem('token');
        }
      }
    }),
    LoaderModule,
    SpinnerComponent,
    MatIconModule,
    MatTooltipModule
  ],
  providers: environment.mock ? [

    {
      provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true
    },

    {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory
    },
    {
        provide: MSAL_GUARD_CONFIG,
        useFactory: MSALGuardConfigFactory
    },
    MsalService,
    {provide: MsalGuard, useClass: MockMsalGuard},
    MsalBroadcastService,
    {provide: AuthGuard, useClass: MockAuthGuard},
    AzureAuthService,
    {
      provide: AZURE_AUTH_CONFIG,
      useValue: { /* your azure auth config here */ }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
  ] : [
    {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory
    },
    {
        provide: MSAL_GUARD_CONFIG,
        useFactory: MSALGuardConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    AuthGuard,
    AzureAuthService,
    {
      provide: AZURE_AUTH_CONFIG,
      useValue: { /* your azure auth config here */ }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    TooltipContentComponent

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
