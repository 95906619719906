import { deductionTypes } from './deductionTypes';
import { decks } from './decks';
import { DivisionOrder } from './../model/divisionOrder';
import { titles } from './../mockDB/titles'
import { products } from './products';
import { interestTypes } from './interest-types';

export const divisionOrder1: DivisionOrder = {
  id: 1,
  operatorDivisionOrderCode: '1',
  deck: decks[0],
  title: titles[0],
  product: products[0],
  interestType: interestTypes[0],
  decimalInterest: 1,
  status: '1',
  doDate: new Date(),
  termStart: new Date(),
  termEnd: new Date(),
  deductionsExclusions: [
    deductionTypes[0]
  ],
  insertBy: '1',
  insertDate: new Date('1/1/2021'),
  modifyBy: '1',
  modifyDate: new Date('1/1/2021')
}

export const divisionOrder2: DivisionOrder = {
  id: 2,
  operatorDivisionOrderCode: '2',
  deck: decks[1],
  title: titles[1],
  product: products[1],
  interestType: interestTypes[1],
  decimalInterest: 2,
  status: '2',
  doDate: new Date(),
  termStart: new Date(),
  termEnd: new Date(),
  deductionsExclusions: [
    deductionTypes[1]
  ],
  insertBy: '2',
  insertDate: new Date('2/2/2022'),
  modifyBy: '2',
  modifyDate: new Date('2/2/2022')
}

export const divisionOrder3: DivisionOrder = {
  id: 3,
  operatorDivisionOrderCode: '3',
  deck: decks[2],
  title: titles[2],
  product: products[2],
  interestType: interestTypes[2],
  decimalInterest: 3,
  status: '3',
  doDate: new Date(),
  termStart: new Date(),
  termEnd: new Date(),
  deductionsExclusions: [
    deductionTypes[2]
  ],
  insertBy: '3',
  insertDate: new Date('3/3/2023'),
  modifyBy: '3',
  modifyDate: new Date('3/3/2023')
}

export const divisionOrder4: DivisionOrder = {
  id: 4,
  operatorDivisionOrderCode: '4',
  deck: decks[3],
  title: titles[3],
  product: products[3],
  interestType: interestTypes[3],
  decimalInterest: 4,
  status: '4',
  doDate: new Date(),
  termStart: new Date(),
  termEnd: new Date(),
  deductionsExclusions: [
    deductionTypes[3]
  ],
  insertBy: '4',
  insertDate: new Date('4/4/2024'),
  modifyBy: '4',
  modifyDate: new Date('4/4/2024')
}

export const divisionOrder5: DivisionOrder = {
  id: 5,
  operatorDivisionOrderCode: '5',
  deck: decks[4],
  title: titles[4],
  product: products[4],
  interestType: interestTypes[4],
  decimalInterest: 5,
  status: '5',
  doDate: new Date(),
  termStart: new Date(),
  termEnd: new Date(),
  deductionsExclusions: [
    deductionTypes[4]
  ],
  insertBy: '5',
  insertDate: new Date('5/5/2025'),
  modifyBy: '5',
  modifyDate: new Date('5/5/2025')
}

export const divisionOrder6: DivisionOrder = {
  id: 6,
  operatorDivisionOrderCode: '6',
  deck: decks[5],
  title: titles[5],
  product: products[5],
  interestType: interestTypes[5],
  decimalInterest: 6,
  status: '6',
  doDate: new Date(),
  termStart: new Date(),
  termEnd: new Date(),
  deductionsExclusions: [
    deductionTypes[5]
  ],
  insertBy: '6',
  insertDate: new Date('6/6/2026'),
  modifyBy: '6',
  modifyDate: new Date('6/6/2026')
}

export const divisionOrder7: DivisionOrder = {
  id: 7,
  operatorDivisionOrderCode: '7',
  deck: decks[6],
  title: titles[6],
  product: products[6],
  interestType: interestTypes[6],
  decimalInterest: 7,
  status: '7',
  doDate: new Date(),
  termStart: new Date(),
  termEnd: new Date(),
  deductionsExclusions: [
    deductionTypes[6]
  ],
  insertBy: '7',
  insertDate: new Date('7/7/2027'),
  modifyBy: '7',
  modifyDate: new Date('7/7/2027')
}

export const divisionOrder8: DivisionOrder = {
  id: 8,
  operatorDivisionOrderCode: '8',
  deck: decks[7],
  title: titles[7],
  product: products[7],
  interestType: interestTypes[7],
  decimalInterest: 8,
  status: '8',
  doDate: new Date(),
  termStart: new Date(),
  termEnd: new Date(),
  deductionsExclusions: [
    deductionTypes[7]
  ],
  insertBy: '8',
  insertDate: new Date('8/8/2028'),
  modifyBy: '8',
  modifyDate: new Date('8/8/2028')
}

export const divisionOrder9: DivisionOrder = {
  id: 9,
  operatorDivisionOrderCode: '9',
  deck: decks[8],
  title: titles[8],
  product: products[8],
  interestType: interestTypes[8],
  decimalInterest: 9,
  status: '9',
  doDate: new Date(),
  termStart: new Date(),
  termEnd: new Date(),
  deductionsExclusions: [
    deductionTypes[8]
  ],
  insertBy: '9',
  insertDate: new Date('9/9/2029'),
  modifyBy: '9',
  modifyDate: new Date('9/9/2029')
}

export const divisionOrder10: DivisionOrder = {
  id: 10,
  operatorDivisionOrderCode: '10',
  deck: decks[9],
  title: titles[9],
  product: products[9],
  interestType: interestTypes[9],
  decimalInterest: 10,
  status: '10',
  doDate: new Date(),
  termStart: new Date(),
  termEnd: new Date(),
  deductionsExclusions: [
    deductionTypes[9]
  ],
  insertBy: '10',
  insertDate: new Date('10/10/2030'),
  modifyBy: '10',
  modifyDate: new Date('10/10/2030')
}

export const divisionOrder11: DivisionOrder = {
  id: 11,
  operatorDivisionOrderCode: '11',
  deck: decks[10],
  title: titles[10],
  product: products[10],
  interestType: interestTypes[10],
  decimalInterest: 11,
  status: '11',
  doDate: new Date(),
  termStart: new Date(),
  termEnd: new Date(),
  deductionsExclusions: [
    deductionTypes[10]
  ],
  insertBy: '11',
  insertDate: new Date('11/11/2031'),
  modifyBy: '11',
  modifyDate: new Date('11/11/2031')
}

export const divisionOrder12: DivisionOrder = {
  id: 12,
  operatorDivisionOrderCode: '12',
  deck: decks[11],
  title: titles[11],
  product: products[11],
  interestType: interestTypes[11],
  decimalInterest: 12,
  status: '12',
  doDate: new Date(),
  termStart: new Date(),
  termEnd: new Date(),
  deductionsExclusions: [
    deductionTypes[11]
  ],
  insertBy: '12',
  insertDate: new Date('12/12/2032'),
  modifyBy: '12',
  modifyDate: new Date('12/12/2032')
}

export const divisionOrder13: DivisionOrder = {
  id: 13,
  operatorDivisionOrderCode: '13',
  deck: decks[12],
  title: titles[12],
  product: products[12],
  interestType: interestTypes[12],
  decimalInterest: 13,
  status: '13',
  doDate: new Date(),
  termStart: new Date(),
  termEnd: new Date(),
  deductionsExclusions: [
    deductionTypes[12]
  ],
  insertBy: '13',
  insertDate: new Date('13/13/2033'),
  modifyBy: '13',
  modifyDate: new Date('13/13/2033')
}

export const divisionOrder14: DivisionOrder = {
  id: 14,
  operatorDivisionOrderCode: '14',
  deck: decks[13],
  title: titles[13],
  product: products[13],
  interestType: interestTypes[13],
  decimalInterest: 14,
  status: '14',
  doDate: new Date(),
  termStart: new Date(),
  termEnd: new Date(),
  deductionsExclusions: [
    deductionTypes[13]
  ],
  insertBy: '14',
  insertDate: new Date('14/14/2034'),
  modifyBy: '14',
  modifyDate: new Date('14/14/2034')
}

export const divisionOrder15: DivisionOrder = {
  id: 15,
  operatorDivisionOrderCode: '15',
  deck: decks[14],
  title: titles[14],
  product: products[14],
  interestType: interestTypes[14],
  decimalInterest: 15,
  status: '15',
  doDate: new Date(),
  termStart: new Date(),
  termEnd: new Date(),
  deductionsExclusions: [
    deductionTypes[14]
  ],
  insertBy: '15',
  insertDate: new Date('15/15/2035'),
  modifyBy: '15',
  modifyDate: new Date('15/15/2035')
}

export const divisionOrder16: DivisionOrder = {
  id: 16,
  operatorDivisionOrderCode: '16',
  deck: decks[15],
  title: titles[15],
  product: products[15],
  interestType: interestTypes[15],
  decimalInterest: 16,
  status: '16',
  doDate: new Date(),
  termStart: new Date(),
  termEnd: new Date(),
  deductionsExclusions: [
    deductionTypes[15]
  ],
  insertBy: '16',
  insertDate: new Date('16/16/2036'),
  modifyBy: '16',
  modifyDate: new Date('16/16/2036')
}

export const divisionOrder17: DivisionOrder = {
  id: 17,
  operatorDivisionOrderCode: '17',
  deck: decks[16],
  title: titles[16],
  product: products[16],
  interestType: interestTypes[16],
  decimalInterest: 17,
  status: '17',
  doDate: new Date(),
  termStart: new Date(),
  termEnd: new Date(),
  deductionsExclusions: [
    deductionTypes[16]
  ],
  insertBy: '17',
  insertDate: new Date('17/17/2037'),
  modifyBy: '17',
  modifyDate: new Date('17/17/2037')
}

export const divisionOrder18: DivisionOrder = {
  id: 18,
  operatorDivisionOrderCode: '18',
  deck: decks[17],
  title: titles[17],
  product: products[17],
  interestType: interestTypes[17],
  decimalInterest: 18,
  status: '18',
  doDate: new Date(),
  termStart: new Date(),
  termEnd: new Date(),
  deductionsExclusions: [
    deductionTypes[17]
  ],
  insertBy: '18',
  insertDate: new Date('18/18/2038'),
  modifyBy: '18',
  modifyDate: new Date('18/18/2038')
}

export const divisionOrder19: DivisionOrder = {
  id: 19,
  operatorDivisionOrderCode: '19',
  deck: decks[18],
  title: titles[18],
  product: products[18],
  interestType: interestTypes[18],
  decimalInterest: 19,
  status: '19',
  doDate: new Date(),
  termStart: new Date(),
  termEnd: new Date(),
  deductionsExclusions: [
    deductionTypes[18]
  ],
  insertBy: '19',
  insertDate: new Date('19/19/2039'),
  modifyBy: '19',
  modifyDate: new Date('19/19/2039')
}

export const divisionOrder20: DivisionOrder = {
  id: 20,
  operatorDivisionOrderCode: '20',
  deck: decks[19],
  title: titles[19],
  product: products[19],
  interestType: interestTypes[19],
  decimalInterest: 20,
  status: '20',
  doDate: new Date(),
  termStart: new Date(),
  termEnd: new Date(),
  deductionsExclusions: [
    deductionTypes[19]
  ],
  insertBy: '20',
  insertDate: new Date('20/20/2040'),
  modifyBy: '20',
  modifyDate: new Date('20/20/2040')
}

export const divisionOrders: DivisionOrder[] = [
  divisionOrder1,
  divisionOrder2,
  divisionOrder3,
  divisionOrder4,
  divisionOrder5,
  divisionOrder6,
  divisionOrder7,
  divisionOrder8,
  divisionOrder9,
  divisionOrder10,
  divisionOrder11,
  divisionOrder12,
  divisionOrder13,
  divisionOrder14,
  divisionOrder15,
  divisionOrder16,
  divisionOrder17,
  divisionOrder18,
  divisionOrder19,
  divisionOrder20
]

