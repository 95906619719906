import { PaymentDeduction } from "../model/payments";
import { deductionTypes } from "./deductionTypes";


export const paymentDeduction1: PaymentDeduction = {
  deductionType: deductionTypes[0],
  amount: 100
}

export const paymentDeduction2: PaymentDeduction = {
  deductionType: deductionTypes[1],
  amount: 200
}

export const paymentDeduction3: PaymentDeduction = {
  deductionType: deductionTypes[2],
  amount: 300
}

export const paymentDeduction4: PaymentDeduction = {
  deductionType: deductionTypes[3],
  amount: 400
}

export const paymentDeduction5: PaymentDeduction = {
  deductionType: deductionTypes[4],
  amount: 500
}

export const paymentDeduction6: PaymentDeduction = {
  deductionType: deductionTypes[5],
  amount: 600
}

export const paymentDeduction7: PaymentDeduction = {
  deductionType: deductionTypes[6],
  amount: 700
}

export const paymentDeduction8: PaymentDeduction = {
  deductionType: deductionTypes[7],
  amount: 800
}

export const paymentDeduction9: PaymentDeduction = {
  deductionType: deductionTypes[8],
  amount: 900
}

export const paymentDeduction10: PaymentDeduction = {
  deductionType: deductionTypes[9],
  amount: 1000
}

export const paymentDeduction11: PaymentDeduction = {
  deductionType: deductionTypes[10],
  amount: 1100
}

export const paymentDeduction12: PaymentDeduction = {
  deductionType: deductionTypes[11],
  amount: 1200
}

export const paymentDeduction13: PaymentDeduction = {
  deductionType: deductionTypes[12],
  amount: 1300
}

export const paymentDeduction14: PaymentDeduction = {
  deductionType: deductionTypes[13],
  amount: 1400
}

export const paymentDeduction15: PaymentDeduction = {
  deductionType: deductionTypes[14],
  amount: 1500
}

export const paymentDeduction16: PaymentDeduction = {
  deductionType: deductionTypes[15],
  amount: 1600
}

export const paymentDeduction17: PaymentDeduction = {
  deductionType: deductionTypes[16],
  amount: 1700
}

export const paymentDeduction18: PaymentDeduction = {
  deductionType: deductionTypes[17],
  amount: 1800
}

export const paymentDeduction19: PaymentDeduction = {
  deductionType: deductionTypes[18],
  amount: 1900
}

export const paymentDeduction20: PaymentDeduction = {
  deductionType: deductionTypes[19],
  amount: 2000
}

export const paymentDeduction21: PaymentDeduction = {
  deductionType: deductionTypes[20],
  amount: 2100
}

export const paymentDeduction22: PaymentDeduction = {
  deductionType: deductionTypes[21],
  amount: 2200
}

export const paymentDeduction23: PaymentDeduction = {
  deductionType: deductionTypes[22],
  amount: 2300
}

export const paymentDeduction24: PaymentDeduction = {
  deductionType: deductionTypes[23],
  amount: 2400
}

export const paymentDeductions: PaymentDeduction[] = [
  paymentDeduction1,
  paymentDeduction2,
  paymentDeduction3,
  paymentDeduction4,
  paymentDeduction5,
  paymentDeduction6,
  paymentDeduction7,
  paymentDeduction8,
  paymentDeduction9,
  paymentDeduction10,
  paymentDeduction11,
  paymentDeduction12,
  paymentDeduction13,
  paymentDeduction14,
  paymentDeduction15,
  paymentDeduction16,
  paymentDeduction17,
  paymentDeduction18,
  paymentDeduction19,
  paymentDeduction20,
  paymentDeduction21,
  paymentDeduction22,
  paymentDeduction23,
  paymentDeduction24
]

