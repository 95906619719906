import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { LoaderService } from '../../services/loader/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @Input() size: number = 100;
  @Input() fixed: boolean = true;
  @Input() isLoading?: boolean;
  @HostBinding('style.--data-size') loaderSize!: string;

  public manualLoading = false;
  constructor(
    public loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    this.loaderSize = `${this.size}px`;
    if (this.isLoading !== undefined) {
      this.manualLoading = true;
    }
  }

}
