import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaymentsService } from '../../../core/services/payments/payments.service';
import { Payment, PaymentLoadStatus } from '../../../model/payments';
import { Subject, catchError, takeUntil } from 'rxjs';

@Component({
  selector: 'app-retry',
  standalone: true,
  imports: [],
  templateUrl: './retry.component.html',
  styleUrl: './retry.component.css'
})
export class RetryComponent {
  @Input() parentType?: string;
  @Output() retryRecentActivity? = new EventEmitter<void>();
  @Output() retryOil? = new EventEmitter<void>();
  @Output() retryGas? = new EventEmitter<void>();

  private unsubscribe = new Subject();

  public message: string
  constructor(
    private paymentService: PaymentsService,
  ) { }

  retry() {
    switch (this.parentType) {
      case 'recent-payments':
        this.message = "Recent payments have failed to load!"
        this.getRecentPaymants();
        break;
      case 'recent-activity':
        this.message = "Recent activity has failed to load!"
        this.retryRecentActivity.emit();
        break;
      case 'oil':
        this.message = "Oil data has failed to load!"
        this.retryOil.emit();
        break;
      case 'gas':
        this.message = "Gas data has failed to load!"
        this.retryGas.emit();
        break;
      default:
        console.error('Invalid parent type');
    }
  }

  private getRecentPaymants() {
    this.paymentService.setPaymentLoadStatus(PaymentLoadStatus.NONE);
    this.paymentService.getMroPayments(25).pipe(
      takeUntil(this.unsubscribe),
      catchError((error) => {
        if (error.status === 500) {
          this.paymentService.setPaymentLoadStatus(PaymentLoadStatus.FAILED);
        }
        console.error('getPayments:Error: ' + JSON.stringify(error));
        return error;
      })
    ).subscribe(
      (payments: Payment[]) => {
        this.paymentService.setPayments(payments);
        this.paymentService.setPaymentLoadStatus(PaymentLoadStatus.COMPLETE);
      }
    );
  }
}
