import { Component } from '@angular/core';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent {

  constructor() { }

  public drawerToggle(itemID: string) {
    const drawerID = itemID + '_container';

    const item = document.getElementById(itemID);
    const drawer = document.getElementById(drawerID);

    if (item.classList.contains('btn_drawer_active')) {
      item.classList.remove('btn_drawer_active');
      drawer.classList.remove('drawer_active');
    } else {
      item.classList.add('btn_drawer_active');
      drawer.classList.add('drawer_active');
    }
  }

  public setCursorPosition(event: FocusEvent): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.selectionStart = 0;
    textarea.selectionEnd = 0;
  }

}
