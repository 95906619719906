import { mineralRightsOwners } from './../../../mockDB/mineralRightsOwners';
import { Injectable } from '@angular/core';
import { ProfileService } from './profile.service';
import { getRandomInt } from '../../../mockDB/helpers/randomNumbersHelper';
import { Observable, of } from 'rxjs';
import { MineralRightsOwner } from '../../../model/mineralRightsOwner';

@Injectable({
  providedIn: 'root'
})
export class MockProfileService extends ProfileService {

  constructor() {
    super();
   }

  getOwner(): Observable<MineralRightsOwner> {
    return of(mineralRightsOwners[getRandomInt(0, mineralRightsOwners.length)]);
  }
}