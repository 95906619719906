<div id="view_statement">
  <ng-container>
  <div class="mro_stmnt_header">

    <div class="mro_stmnt_title"><h3>{{ statement.operator.name || '' }}</h3></div>

    <div class="mro_stmnt_data">CK#{{ statement.checkNo }}</div>
    <div class="mro_stmnt_date">{{ getUtcDateString(statement.paymentDate) }}</div>
  </div>
    <div *ngFor="let payment of royaltyPayments"
          class="property_content">

      <fieldset class="grouped no-border div2">
        <!-- <legend class="legend_title">{{ payment.sale.product.name }}</legend> -->
        <div class="data_row compact inline">
          <div class="data_label">Product Name</div>
            <div class="data_value text_right">{{ payment.sale.product.name }}</div>
        </div>
        <div class="data_row compact inline">
          <div class="data_label">Well</div>
            <div class="data_value text_right">{{ payment.well.name}}</div>
        </div>
        <div class="data_row compact inline">
          <div class="data_label">Prod. Date</div>
            <div class="data_value text_right">{{convertToDate(payment.sale.prodDate) | date: "MMM yyyy" }}</div>
        </div>
        <div class="data_row compact inline">
          <div class="data_label">Price</div>
          <div class="data_value text_right">
            ${{ payment.sale.unitPrice | number: "1.2-2":"en-US" || 0.00 }}
          </div>
        </div>
        <div class="data_row compact inline">
          <div class="data_label">Total Volume</div>
          <div class="data_value text_right">
            <a
              href="javascript:void(0)"
              class="blockchain_explorer_icon"
              title="View Blockchain Transaction"
            ></a>
            {{ payment.sale.saleVolume || 0}}
          </div>
        </div>
        <div class="data_row compact inline">
          <div class="data_label">Total Gross</div>
          <div class="data_value text_right">
            <a
              href="javascript:void(0)"
              class="blockchain_explorer_icon"
              title="View Blockchain Transaction"
            ></a>
            ${{ payment.sale.grossValue | number: "1.2-2":"en-US" || 0.00 }}
          </div>
        </div>
        <div class="data_row compact inline" *ngIf="payment.sale.severance != 0 && payment.severance != null && payment.severance != undefined">
          <div class="data_label">Severance</div>
          <div class="data_value text_right">
            {{ payment.sale.severance ? ('$' + (payment.sale.severance | number: "1.2-2":"en-US")) : "$0.00" }}</div>
        </div>
        <div *ngFor="let deduction of payment.sale.deductionObjects">
          <div class="data_row compact inline" *ngIf="deduction.amount != 0 && deduction.amount != undefined && deduction.amount != null">
            <div class="data_label">Total {{ deduction.name }}</div>
            <div class="data_value text_right">
              {{ deduction.amount ? ("$" + (deduction.amount | number: "1.2-2":"en-US" || 0 )) : "$0.00"}}
            </div>
          </div>
        </div>
        <div class="data_row compact inline">
          <div class="data_label">Net</div>
          <div class="data_value text_right">
            {{ payment.sale.netValue ? ("$" + (payment.sale.netValue | number: "1.2-2":"en-US" || 0 )) : "$0.00"}}
          </div>
        </div>
      </fieldset>

      <fieldset class="grouped no_margin no_border div1" id="">
        <div class="data_row compact inline">
          <div class="data_label">Interest Type</div>
          <div class="data_value text_right">
            {{ payment.divisionOrder.interestType.name ? ((payment.divisionOrder.interestType.name || "" )) : ' '}}
          </div>
        </div>
        <div class="data_row compact inline">
          <div class="data_label">Decimal</div>
          <div class="data_value text_right">
            {{ payment.divisionOrder.decimalInterest | number: '.8-8' || 0 }}
          </div>
        </div>
        <div class="data_row compact inline">
          <div class="data_label">Owner Volume</div>
          <div class="data_value text_right">
            {{ payment.mroVolume || 0 }}
          </div>
        </div>
        <div class="data_row compact inline">
          <div class="data_label">Owner Gross</div>
          <div class="data_value text_right">
            {{ payment.mroGrossValue ? ("$" + (payment.mroGrossValue | number: "1.2-2":"en-US" || 0 )) :  "$0.00"}}
          </div>
        </div>
        <div class="data_row compact inline" *ngIf="payment.severance != 0 && payment.severance != undefined && payment.severance != null">
          <div class="data_label">Owner Severance</div>
          <div class="data_value text_right">
            {{ payment.severance ? ("$" + (payment.severance | number: "1.2-2":"en-US" || 0 )) : "$0.00"}}
          </div>
        </div>
        <div *ngFor="let deduction of payment.deductionObjects">
          <div class="data_row compact inline" *ngIf="deduction.amount != 0 && deduction.amount != undefined && deduction.amount != null">
            <div class="data_label">{{ deduction.name }}</div>
            <div class="data_value text_right">
                {{ deduction.amount ? ("$" + (deduction.amount | number: "1.2-2":"en-US" || 0 )) : "$0.00"}}
              </div>
          </div>
        </div>
        <div class="data_row compact inline">
          <div class="data_label">Owner Net</div>
          <div class="data_value text_right">
            {{ payment.mroNetValue ? ("$" + (payment.mroNetValue | number: "1.2-2":"en-US" || 0 )) : "$0.00"}}
          </div>
        </div>
      </fieldset>

      <div class="well_sub_total div3">
        <div class="data_row align_center">
          <div class="data_label">Sub Total</div>
          <div class="data_value medium_text">
            {{ payment.mroNetValue ? ("$" + (payment.mroNetValue | number: "1.2-2":"en-US" || 0 )) : "$0.00"}}
          </div>
        </div>
      </div>
    </div>

  </ng-container>
</div>
