import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Property } from '../../../model/property';

@Injectable({
  providedIn: 'root'
})
export abstract class PropertyService {

  private properties: Property[] = [];

  private lastUpdated: Date;

  abstract getMroProperties(): Observable<Property[]>;

  getProperties(): Property[] {
    return this.properties;
  }

  setProperties(properties: Property[]): void {
    this.properties = properties;
    this.setLastUpdated(new Date());
  }

  getLastUpdated(): Date {
    return this.lastUpdated;
  }

  setLastUpdated(lastUpdated: Date): void {
    this.lastUpdated = lastUpdated;
  }

}
