import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { State } from '../../../model/state';
import { us_states } from '../../../mockDB/states';
import { Operator } from '../../../model/operator';
import { Purchaser } from '../../../model/purchaser';
import { Well } from '../../../model/well';
import { Bank } from '../../../model/bank';
import { operators } from '../../../mockDB/operators';
import { purchasers } from '../../../mockDB/purchasers';
import { wells } from '../../../mockDB/wells';
import { banks } from '../../../mockDB/banks';




@Injectable({
  providedIn: 'root'
})
export class MockLookupService {

  constructor() { }

  getStates(): Observable<State[]> {
    return of(us_states);
  }

  getOperators(): Observable<Operator[]> {
    return of(operators);
  }

  getOperator(id: number): Observable<Operator> {
    return of(operators.find(operator => operator.id === id));
  }

  getMroOperators(): Observable<Operator[]> {
    return of(operators);
  }

  getPurchasers(): Observable<Purchaser[]> {
    return of(purchasers);
  }

  getWells(): Observable<Well[]> {
    return of(wells);
  }

  getBanks(): Observable<Bank[]> {
    return of(banks);
  }

  getInterestTypes(): Observable<string[]> {
    return of(['ORRI', 'WI']);
  }

}
