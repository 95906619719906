import { OperatorMapping } from "./operator";
import { State } from "./state";

export interface MineralRightsOwner {
  id: number;
  ownername: string;
  fname: string;
  lname: string;
  suffix: string;
  taxNumber: string;
  address1: string;
  address2: string;
  city: string;
  stateName: string;
  stateId: number;
  zipCode: string;
  tel: string;
  fax: string;
  cell: string;
  websiteUrl: string;
  status: MroStatus;
  operatorMappings: OperatorMapping[];
  insertBy: number;
  insertDate: Date;
  modifyBy: number;
  modifyDate: Date;
}

export enum MroStatus {
  Initialized,
  registerLocaleData,
}
