
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Payment, RoyaltyPayment } from '../../model/payments';

@Component({
  selector: 'app-statement-footer',
  templateUrl: './statement-footer.component.html',
  styleUrls: ['./statement-footer.component.css']
})
export class StatementFooterComponent implements OnChanges {
  @Input() payment: Payment;

  private headers: string[] = [];

  constructor(private router: Router) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.payment) {
      this.payment = changes.payment.currentValue;
    }
  }

  public back() {
    window.history.back();
  }

  public exportToCSV() {
    const headers = this.createHeaders();
    const csvRows = this.payment.royaltyPayments.map(payment => this.createCSVRow(payment));

    const csvContent = `data:text/csv;charset=utf-8,${headers}\n${csvRows.join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    this.downloadCSV(encodedUri);
  }

  private createHeaders(): string[]	{
    const staticHeadersBefore = [
      "Issue Date",
      "Well",
      "Prod. Date",
      "Product",
      "Price",
      "Sales Volume",
      "Sales Gross Value",
    ];
    const staticHeadersAfterSales = [
      "Sales Net Value",
      "Interest Type",
      "Decimal",
      "Owner Volume",
      "Owner Gross Value",
    ];
    const staticHeadersAfterOwner = ["Amount"];
    const firstRoyaltyPayment = this.payment.royaltyPayments[0];
    const salesDeductionHeaders = Object.values(firstRoyaltyPayment.sale.deductionObjects).map(deduction => deduction.name);
    const ownerDeductioHeaders = Object.values(firstRoyaltyPayment.deductionObjects).map(deduction => deduction.name);
    return [
      ...staticHeadersBefore,
      ...salesDeductionHeaders,
      ...staticHeadersAfterSales,
      ...ownerDeductioHeaders,
      ...staticHeadersAfterOwner,
    ];
  }

  private createCSVRow(payment: RoyaltyPayment): string {
    return [
      payment.paymentDate || '',
      payment.well.name || '',
      payment.sale.prodDate || '',
      payment.sale.product.name || '',
      payment.sale.unitPrice || '',
      payment.sale.saleVolume || '',
      payment.sale.grossValue || '',
      ...Object.values(payment.sale.deductionObjects).map(deduction => deduction.amount || ''),
      payment.sale.netValue || '',
      payment.divisionOrder.interestType.name || '',
      payment.divisionOrder.decimalInterest || '',
      payment.mroVolume || '',
      payment.mroGrossValue || '',
      ...Object.values(payment.deductionObjects).map(deduction => deduction.amount || ''),
      payment.mroNetValue || '',
    ].join(',');
  }

  private downloadCSV(encodedUri: string): void {
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', this.generateFileName());
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  private generateFileName(): string {
    const date = new Date();
    const dateTimeFormat = `${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}_${date.getHours().toString().padStart(2, '0')}${date.getMinutes().toString().padStart(2, '0')}${date.getSeconds().toString().padStart(2, '0')}`;
    return `statement_data_${dateTimeFormat}.csv`;
  }
}
