import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RecentActivityService } from './recent-activity.service';
import { HttpService } from '../../../http.service';
import { MroRecentActivity } from '../../../model/recent_activity';

@Injectable({
  providedIn: 'root'
})
export class HttpRecentActivityService extends RecentActivityService {

  constructor(
    private httpService: HttpService
  ) {
    super();
   }

  getMroRecentActivity(): Observable<MroRecentActivity[]> {
    return this.httpService.get(`/mro/getMroRecentActivity`, true);
  }
}
