import { PaymentType } from "../model/payments";

export const payment_type1: PaymentType = {
  id: 1,
  description: 'ACH',
  name: 'ACH',
  operatorId: 1,
  primaryId: 1
};

export const payment_type2: PaymentType = {
  id: 2,
  description: 'Check',
  name: 'Check',
  operatorId: 2,
  primaryId: 2
};

export const payment_type3: PaymentType = {
  id: 3,
  description: 'Wire',
  name: 'Wire',
  operatorId: 3,
  primaryId: 3
};

export const payment_types: PaymentType[] = [
  payment_type1,
  payment_type2,
  payment_type3
];