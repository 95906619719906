import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { trigger, transition, style, animate } from "@angular/animations";

import { GraphAndBarChartData } from "../../../../model/chart-data";
import { StorageService } from "../../../../core/services/storage/storage.service";
import { SpinnerComponent } from "../../../spinner/spinner.component";
import { NoDataComponent } from "../../../no-data/no-data.component";

@Component({
  selector: "app-graph-chart",
  standalone: true,
  templateUrl: "./graph-chart.component.html",
  styleUrls: ["./graph-chart.component.css"],
  imports: [
    CommonModule,
    FormsModule,
    NgxChartsModule,
    SpinnerComponent,
    NoDataComponent,
  ],
  animations: [
    trigger("animationState", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate("500ms", style({ opacity: 1 })),
      ]),
      transition(":leave", [animate("500ms", style({ opacity: 0 }))]),
    ]),
  ],
})
export class GraphChartComponent {
  @Input() sectionType?: string;
  @Input() breakdown?: string;

  public view = [500, 300];
  public showXAxis: boolean = true;
  public showYAxis: boolean = true;
  public gradient: boolean = false;
  public showLegend: boolean = false;
  public legendPosition: string = "below";
  public showXAxisLabel: boolean = true;
  public showYAxisLabel: boolean = true;
  public trimXAxisTicks: boolean = true;
  public roundDomains: boolean = false;
  public showGridLines: boolean = true;
  public animations: boolean = true;
  public showRefLines: boolean = true;
  public showRefLabels: boolean = true;
  public rotateXAxisTicks: boolean = true;
  public showDataLabel: boolean = true;
  public showDataLabelOnAll: boolean = true;

  public graphAndBarChartData: GraphAndBarChartData[] = [];

  constructor(private storageService: StorageService) {}

  get isMobile() {
    return window.innerWidth <= 768;
  }


  get chartData() {
    switch (this.sectionType) {
      case "revenueByProduct":
        return this.storageService.getMroPaymentSummaryByPeriodByProduct() as GraphAndBarChartData[];
      case "revenueByProperty":
        return this.storageService.getMroPaymentSummaryByPeriodByProperty() as GraphAndBarChartData[];
      default:
        break;
    }
  }

  get dataValid() {
    switch (this.sectionType) {
      case "revenueByProduct":
        return this.storageService.getIsMroPaymentSummaryByPeriodByProductValid();
      case "revenueByProperty":
        return this.storageService.getIsMroPaymentSummaryByPeriodByPropertyValid();
      default:
        break;
    }
  }

  get loading() {
    return this.chartData && this.chartData.length <= 0;
  }

  get xAxisLabel() {
    switch (this.breakdown) {
      case "A":
        return "Year";
      case "M":
        return "Month";
      case "Q":
        return "Quarter";
      default:
        break;
    }
  }

  formatDollar(val: number): string {
    return '$' + val.toLocaleString();
  }
}
