import { Bank } from '../model/bank';
import { randomDateMinOrMax } from './helpers/randomDateHelper';

export  const bank1: Bank = {
  id: 1,
  name: 'Bank of America',
  participating: 'Yes',
  logoPath: 'assets/images/bankofamerica.jpg',
  addr1: '123 Main St',
  addr2: '',
  city: 'Anytown',
  stateCode: 'CA',
  zip: '12345',
  tel: '123-456-7890',
  fax: '123-456-7890',
  email: 'bankofamerica@test.lan',
  insertBy: 1,
  insertDate: randomDateMinOrMax(2022, 1999, true),
  modifiedBy: 1,
  modifiedDate: randomDateMinOrMax(2022, new Date().getFullYear(), true)
};

export  const bank2: Bank = {
  id: 2,
  name: 'Chase',
  participating: 'Yes',
  logoPath: 'assets/images/chase.jpg',
  addr1: '123 Main St',
  addr2: '',
  city: 'Anytown',
  stateCode: 'CA',
  zip: '12345',
  tel: '123-456-7890',
  fax: '123-456-7890',
  email: 'chase@test.lan',
  insertBy: 1,
  insertDate: randomDateMinOrMax(2022, 1999, true),
  modifiedBy: 1,
  modifiedDate: randomDateMinOrMax(2022, new Date().getFullYear(), true)
};

export  const bank3: Bank = {
  id: 3,
  name: 'Wells Fargo',
  participating: 'Yes',
  logoPath: 'assets/images/wellsfargo.jpg',
  addr1: '123 Main St',
  addr2: '',
  city: 'Anytown',
  stateCode: 'CA',
  zip: '12345',
  tel: '123-456-7890',
  fax: '123-456-7890',
  email: 'wellsfargo@test.lan',
  insertBy: 1,
  insertDate: randomDateMinOrMax(2022, 1999, true),
  modifiedBy: 1,
  modifiedDate: randomDateMinOrMax(2022, new Date().getFullYear(), true)
};

export  const bank4: Bank = {
  id: 4,
  name: 'Citi',
  participating: 'Yes',
  logoPath: 'assets/images/citi.jpg',
  addr1: '123 Main St',
  addr2: '',
  city: 'Anytown',
  stateCode: 'CA',
  zip: '12345',
  tel: '123-456-7890',
  fax: '123-456-7890',
  email: 'citi@test.lan',
  insertBy: 1,
  insertDate: randomDateMinOrMax(2022, 1999, true),
  modifiedBy: 1,
  modifiedDate: randomDateMinOrMax(2022, new Date().getFullYear(), true)
};

export  const bank5: Bank = {
  id: 5,
  name: 'US Bank',
  participating: 'Yes',
  logoPath: 'assets/images/usbank.jpg',
  addr1: '123 Main St',
  addr2: '',
  city: 'Anytown',
  stateCode: 'CA',
  zip: '12345',
  tel: '123-456-7890',
  fax: '123-456-7890',
  email: 'usbank@test.lan',
  insertBy: 1,
  insertDate: randomDateMinOrMax(2022, 1999, true),
  modifiedBy: 1,
  modifiedDate: randomDateMinOrMax(2022, new Date().getFullYear(), true)
};

export const banks: Bank[] = [
  bank1,
  bank2,
  bank3,
  bank4,
  bank5
];
