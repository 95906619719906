<div id="mro_content" style="border: 1px solid white;">
  <div class="mobile_container">
    <!-- Owner Details -->
    <div class="content_extra profile" id="owner_details">
      <div class="col_group col_4">
        <div class="col_item">
          <div class="data_row" >
            <div class="data_label">First Name: </div>
            <div class="data_value" *ngIf="currentMRO.fname">{{ currentMRO.fname }}</div>
          </div>
        </div>
        <div class="col_item">
          <div class="data_row">
            <div class="data_label">Last Name:</div>
            <div class="data_value" *ngIf="currentMRO.lname">{{ currentMRO.lname }}</div>
          </div>
        </div>
      </div>
      <div class="col_group col_4">
        <div class="col_item">
          <div class="data_row address" colspan="4">
            <div class="data_label">Address:</div>
            <div class="data_value">
              <div *ngIf="currentMRO.address1">{{ currentMRO.address1 }}</div>
              <div *ngIf="currentMRO.address2">{{ currentMRO.address2 }}</div>
              <div *ngIf="currentMRO.city">{{ currentMRO.city }}</div>
              <div *ngIf="currentMRO.stateName && currentMRO.zipCode">{{ currentMRO.stateName }} {{ currentMRO.zipCode }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col_group col_4">
          <div class="col_item">
            <div class="data_row">
              <div class="data_label">Telephone:</div>
              <div class="data_value">{{ currentMRO.tel }}</div>
            </div>
          </div>
      </div>
      <div class="col_group col_4">
        <div class="col_item">
          <div class="data_row">
            <div class="data_label">Cell:</div>
            <div class="data_value">{{ currentMRO.cell }}</div>
          </div>
        </div>
      </div>
      <div class="col_group col_4">
        <div class="col_item">
          <div class="data_row">
            <div class="data_label">Fax:</div>
            <div class="data_value">{{ currentMRO.fax }}</div>
          </div>
        </div>
      </div>
      <div class="profile-bottom">
        <div class="line_divider"></div>
        <div class="link_options pos_right">
          <ul>
            <li>
              <button type="button" class="btn_normal btn_purple" (click)="logout()" >
                <span class="btn_icon"></span>
                <span class="btn_text">Log Out</span>
              </button>
            </li>
          </ul>
        </div>
      </div>

      <!-- <div class="col_group col_4">
        <div class="col_item">
          <div class="data_row">
            <div class="data_label">Trust Manager</div>
            <div class="data_value">Michael Wilderman</div>
          </div>
        </div>
        <div class="col_item">
          <div class="data_row">
            <div class="data_label">Contact Info</div>
            <div class="data_value">+1 (0)87 653-5555</div>
          </div>
        </div>
        <div class="col_item">
          <div class="data_row">
            <div class="data_label">Email</div>
            <div class="data_value">
              <a href="mailto:michaelwilderman@transbank.com"
                >michaelwilderman&#64;transbank.com</a
              >
            </div>
          </div>
        </div>
        <div class="col_item">
          <div class="data_row">
            <div class="data_label">Designation of Rights</div>
            <div class="data_value">
              Client #5012, TransBank, 1 Bankplace, Wyoming, 81234
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>

</div>

<div>&nbsp;</div>
