import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, NavigationStart} from '@angular/router';
import {PaymentsService} from '../../core/services/payments/payments.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Operator} from '../../model/operator';
import {LookupService} from '../../core/services/lookup/lookup.service'
import {StatementSearch} from '../../model/statement_search';
import {DatePipe} from '@angular/common';
import { Inject } from '@angular/core';
import { Payment } from '../../model/payments';
import { filter } from 'rxjs';

@Component({
  selector: 'app-statement',
  templateUrl: './statement.component.html',
  styleUrls: ['./statement.component.css'],
  providers: [DatePipe]
})
export class StatementComponent implements OnInit {

  public statementForm: UntypedFormGroup;
  public statementSearch: StatementSearch;
  public statements: Payment[];
  public operators: Operator[];
  public allStatement: Payment[];
  public statement: Payment;
  public monthStr: string;
  public paymentDate: Date;
  public currSubStatementIndex = 0;
  public showGridView = false;
  public showStatementView = true;
  public maxMonth: number;
  public minMonth: number;

  private paymentId: string;
  private payments: Payment[];

  private previousUrl: string = '';
  private currentUrl: string = '';

  constructor(
    @Inject(UntypedFormBuilder) private fb: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private paymentService: PaymentsService,
    private lookupService: LookupService,
    private datePipe: DatePipe
  ) {
    this.statementForm = this.fb.group({
      operatorId: [''],
      month: ['']
    });
    this.statementSearch = {
      date: null,
      operatorId: 0,
      month: 1
    };
    // Listen for NavigationStart events
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        // Store the current URL before it changes
        this.previousUrl = this.currentUrl;
        // Update the current URL
        this.currentUrl = event.url;
      });
  }

  ngOnInit() {
    this.paymentId= this.activatedRoute.snapshot.paramMap.get('id');
    this.paymentDate = this.activatedRoute.snapshot.paramMap.get('date') as unknown as Date;
    this.getPayments();
    this.getOperators();
    this.getPaymentStatement();
    this.findMinMaxMonths();
  }

  findMinMaxMonths() {
    if (this.payments && this.payments.length != 0) {
        const months = this.payments.map(statement => {
          const date = new Date(statement.paymentDate);
          return +this.datePipe.transform(date, 'yyyyMM');
        });
        this.minMonth = Math.min(...months);
        this.maxMonth = Math.max(...months);
    }
  }

  get isGridView() {
    return this.showGridView;
  }

  public showGrid() {
    this.showStatementView = false;
    this.showGridView = true;

    const viewGridElement = document.getElementById('view_grid');
    if (viewGridElement) {
      viewGridElement.style.display = 'block';
    }

    const viewStatementLinkElement = document.getElementById('view_statement_link');
    if (viewStatementLinkElement) {
      viewStatementLinkElement.style.display = 'block';
    }


  }

  public showStatement() {
    this.showGridView = false;
    this.showStatementView = true;

    const viewGridElement = document.getElementById('view_grid');
    if (viewGridElement) {
      viewGridElement.style.display = 'none';
    }

    const viewStatementLinkElement = document.getElementById('view_statement_link');
    if (viewStatementLinkElement) {
      viewStatementLinkElement.style.display = 'none';
    }
  }

  public gotoDashboard() {
    this.router.navigate(['/dashboard']);
  }

  public toggleContainer(toggleElement) {
    const toggleElements = document.getElementsByClassName(toggleElement);
    for (let i = 0; i < toggleElements.length; i++) {
      const element = toggleElements[i] as HTMLElement;
      element.style.display = element.style.display === 'none' ? 'block' : 'none';
    }

    const toggleElementShow = document.getElementById(toggleElement + '_show');
    if (toggleElementShow) {
      toggleElementShow.style.display = toggleElementShow.style.display === 'none' ? 'block' : 'none';
    }

    const toggleElementHide = document.getElementById(toggleElement + '_hide');
    if (toggleElementHide) {
      toggleElementHide.style.display = toggleElementHide.style.display === 'none' ? 'block' : 'none';
    }
  }
  getOperators() {
    this.lookupService.getOperators()
      .pipe()
  }

  getPayments() {
    this.payments = this.paymentService.getPayments();
  }

  getPaymentStatement() {
    if (this.payments && this.payments.length > 0) {
      this.statements = this.payments.filter(p =>
        Number(this.datePipe.transform(p.paymentDate, 'yyyyMM')) === Number(this.paymentDate));
      for (let index = 0; index <  this.statements.length; index++) {
        if (this.statements[index].id === Number(this.paymentId)){
          this.currSubStatementIndex = index;
        }
      }
      this.statement = this.statements[this.currSubStatementIndex];
      this.paymentDate = new Date(this.statements[this.currSubStatementIndex].paymentDate);
      this.statementSearch.date = this.paymentDate;
      this.monthStr = this.datePipe.transform(this.statementSearch.date, 'MMM yyyy');
      this.statementSearch.month = Number(this.datePipe.transform(this.statementSearch.date, 'yyyyMM'));
    }
  }

  getMonthStr(dateInt) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return months[dateInt % 100 - 1];
  }

  getYearPart(dateInt) {
    return Math.floor(dateInt / 100);
  }



}
