import { DeductionObject } from "../model/deduction";

export const deductionObject1: DeductionObject = {
  id: 1,
  name: 'Transportation-Gas',
  amount: 10
}

export const deductionObject2: DeductionObject = {
  id: 2,
  name: 'Unspecified',
  amount: 1
}

export const deductionObject3: DeductionObject = {
  id: 3,
  name: 'Processing Sweetening',
  amount: 0.22
}

export const deductionObjects: DeductionObject[] = [
  deductionObject1,
  deductionObject2,
  deductionObject3,
] 

