import { Injectable } from '@angular/core';
import { MineralRightsOwner } from '../../../model/mineralRightsOwner';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export abstract class ProfileService {
  
  abstract getOwner(): Observable<MineralRightsOwner>;

}
