import { Injectable } from '@angular/core';
import { GraphAndBarChartData, PieChartData } from '../../../model/chart-data';

@Injectable({
  providedIn: 'root'
})
export class DataVerificationService {

  validateSeriesData(data: GraphAndBarChartData[]): boolean {
    if (!data || data === undefined || data === null || data.length <= 0) {
      console.info('DataVerificationService: validateSeriesData: data is null or undefined or empty');
      return false;
    }
    for (const series of data) {
      if (!series || series === undefined || series === null || series.series === undefined || series.series === null || series.series.length <= 0) {
        console.info('DataVerificationService: validateSeriesData: series is null or undefined or empty');
        return false;
      }
      for (const item of series.series) {
        if (!item || item === undefined || item === null || item.name === undefined || item.name === null || item.value === undefined || item.value === null) {
          console.info('DataVerificationService: validateSeriesData: item is null or undefined');
          return false;
        }
        if (isNaN(item.value)) {
          console.info('DataVerificationService: validateSeriesData: item.value is not a number');
          return false;
        }
        if (item.name === '' || item.name === null || item.name === undefined) {
          console.info('DataVerificationService: validateSeriesData: item.name is empty or null or undefined');
          return false;
        }
      }
    }
    return true;

  }

  validatePieChartData(data: PieChartData[]): boolean {
    if (!data || data === undefined || data === null || data.length <= 0) {
      console.info('DataVerificationService: validatePieChartData: data is null or undefined or empty');
      return false;
    }
    for (const item of data) {
      if (!item || item === undefined || item === null || item.name === undefined || item.name === null || item.value === undefined || item.value === null) {
        console.info('DataVerificationService: validatePieChartData: item is null or undefined');
        return false;
      }
      if (isNaN(item.value)) {
        console.info('DataVerificationService: validatePieChartData: item.value is not a number');
        return false;
      }
      if (item.name === '' || item.name === null || item.name === undefined) {
        console.info('DataVerificationService: validatePieChartData: item.name is empty or null or undefined');
        return false;
      }
    }
    return true;
  }

}
