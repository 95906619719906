import { GraphAndBarChartData } from '../model/chart-data';

// Mock data for bar chart (product data)
export const mockProductChartData: GraphAndBarChartData[] = [
    {
        name: "Oil",
        series: [
            { name: "Nov, 22", value: 7658.06 },
            { name: "Dec, 22", value: 5973.34 },
            { name: "Jan, 23", value: 5360.14 },
            { name: "Feb, 23", value: 2819.47 },
            { name: "Mar, 23", value: 3765.73 },
            { name: "Apr, 23", value: 3572.4 },
            { name: "May, 23", value: 3605.6 },
            { name: "Jun, 23", value: 4100.5 },
            { name: "Jul, 23", value: 4500.7 },
            { name: "Aug, 23", value: 4700.2 },
            { name: "Sep, 23", value: 4800.9 },
            { name: "Oct, 23", value: 5000.3 },
            { name: "Nov, 23", value: 5200.4 }
            
        ]
    },
    {
        name: "Condensate",
        series: [
            { name: "Nov, 22", value: 0.26 },
            { name: "Dec, 22", value: 0.44 },
            { name: "Jan, 23", value: 0.35 },
            { name: "Feb, 23", value: 555.39 },
            { name: "Mar, 23", value: 0.26 },
            { name: "Apr, 23", value: 555.15 },
            { name: "May, 23", value: 0.39 },
            { name: "Jun, 23", value: 0.41 },
            { name: "Jul, 23", value: 555.45 },
            { name: "Aug, 23", value: 0.48 },
            { name: "Sep, 23", value: 0.50 },
            { name: "Oct, 23", value: 0.52 },
            { name: "Nov, 23", value: 555.54 }
            
        ]
    },
    {
        name: "Natural Gas",
        series: [
            { name: "Nov, 22", value: 3000.0 },
            { name: "Dec, 22", value: 3200.4 },
            { name: "Jan, 23", value: 3100.7 },
            { name: "Feb, 23", value: 2900.5 },
            { name: "Mar, 23", value: 2950.8 },
            { name: "Apr, 23", value: 2800.9 },
            { name: "May, 23", value: 3000.1 },
            { name: "Jun, 23", value: 3100.2 },
            { name: "Jul, 23", value: 3200.3 },
            { name: "Aug, 23", value: 3300.4 },
            { name: "Sep, 23", value: 3400.5 },
            { name: "Oct, 23", value: 3500.6 },
            { name: "Nov, 23", value: 3600.7 }
            
        ]
    },
    {
        name: "Other",
        series: [
            { name: "Nov, 22", value: 0.26 },
            { name: "Dec, 22", value: 666.44 },
            { name: "Jan, 23", value: 0.35 },
            { name: "Feb, 23", value: 555.39 },
            { name: "Mar, 23", value: 0.26 },
            { name: "Apr, 23", value: 555.15 },
            { name: "May, 23", value: 0.39 },
            { name: "Jun, 23", value: 0.41 },
            { name: "Jul, 23", value: 555.45 },
            { name: "Aug, 23", value: 0.48 },
            { name: "Sep, 23", value: 666.50 },
            { name: "Oct, 23", value: 0.52 },
            { name: "Nov, 23", value: 555.54 }
            
        ]
    }
];

// Mock data for graph chart (property data)
export const mockPropertyChartData: GraphAndBarChartData[] = [
    {
        name: "24-9N-15W E/2 SE/4, CONWAY, AR",
        series: [
            { name: "Nov, 22", value: 31.6 },
            { name: "Dec, 22", value: 43.97 },
            { name: "Jan, 23", value: 82.41 },
            { name: "Feb, 23", value: 37.38 },
            { name: "Mar, 23", value: 66.04 },
            { name: "Apr, 23", value: 60.69 },
            { name: "May, 23", value: 35.52 },
            { name: "Jun, 23", value: 54.21 },
            { name: "Jul, 23", value: 62.45 },
            { name: "Aug, 23", value: 59.38 },
            { name: "Sep, 23", value: 72.14 },
            { name: "Oct, 23", value: 68.32 },
        ]
    },
    {
        name: "KARNES, TX - MAURER-MCFARLAND UNIT (FPM - HEARTLAND)",
        series: [
            { name: "Nov, 22", value: 5807.91 },
            { name: "Dec, 22", value: 5955.7 },
            { name: "Jan, 23", value: 5101.4 },
            { name: "Feb, 23", value: 2496.9 },
            { name: "Mar, 23", value: 3071.14 },
            { name: "Apr, 23", value: 3031.38 },
            { name: "May, 23", value: 2570.1 },
            { name: "Jun, 23", value: 2784.3 },
            { name: "Jul, 23", value: 2960.4 },
            { name: "Aug, 23", value: 2895.6 },
            { name: "Sep, 23", value: 3107.7 },
            { name: "Oct, 23", value: 3210.8 },
        ]
    },
    {
        name: "MIDLAND, TX - SMITH FIELD (XTO ENERGY)",
        series: [
            { name: "Nov, 22", value: 3210.78 },
            { name: "Dec, 22", value: 3345.8 },
            { name: "Jan, 23", value: 3125.0 },
            { name: "Feb, 23", value: 2954.9 },
            { name: "Mar, 23", value: 3097.2 },
            { name: "Apr, 23", value: 3234.3 },
            { name: "May, 23", value: 3310.5 },
            { name: "Jun, 23", value: 3485.4 },
            { name: "Jul, 23", value: 3650.7 },
            { name: "Aug, 23", value: 3585.2 },
            { name: "Sep, 23", value: 3754.6 },
            { name: "Oct, 23", value: 3875.1 },
        ]
    },
    {
        name: "PERMIAN BASIN, TX - WESTERN BLOCK (CHEVRON)",
        series: [
            { name: "Nov, 22", value: 4455.9 },
            { name: "Dec, 22", value: 4620.7 },
            { name: "Jan, 23", value: 4400.6 },
            { name: "Feb, 23", value: 4230.5 },
            { name: "Mar, 23", value: 4380.4 },
            { name: "Apr, 23", value: 4505.3 },
            { name: "May, 23", value: 4670.2 },
            { name: "Jun, 23", value: 4800.1 },
            { name: "Jul, 23", value: 4955.9 },
            { name: "Aug, 23", value: 4875.8 },
            { name: "Sep, 23", value: 5035.7 },
            { name: "Oct, 23", value: 5185.6 },
        ]
    },
    {
        name: "BAKKEN SHALE, ND - NORTH BLOCK (EXXONMOBIL)",
        series: [
            { name: "Nov, 22", value: 2795.6 },
            { name: "Dec, 22", value: 2890.3 },
            { name: "Jan, 23", value: 2710.2 },
            { name: "Feb, 23", value: 2580.1 },
            { name: "Mar, 23", value: 2695.0 },
            { name: "Apr, 23", value: 2790.8 },
            { name: "May, 23", value: 2905.7 },
            { name: "Jun, 23", value: 3050.6 },
            { name: "Jul, 23", value: 3205.5 },
            { name: "Aug, 23", value: 3150.4 },
            { name: "Sep, 23", value: 3310.3 },
            { name: "Oct, 23", value: 3450.2 },
        ]
    },
    {
        name: "EAGLE FORD, TX - EASTERN BLOCK (PIONEER)",
        series: [
            { name: "Nov, 22", value: 3520.9 },
            { name: "Dec, 22", value: 3645.7 },
            { name: "Jan, 23", value: 3460.6 },
            { name: "Feb, 23", value: 3320.5 },
            { name: "Mar, 23", value: 3470.4 },
            { name: "Apr, 23", value: 3585.3 },
            { name: "May, 23", value: 3720.2 },
            { name: "Jun, 23", value: 3880.1 },
            { name: "Jul, 23", value: 4005.0 },
            { name: "Aug, 23", value: 3950.9 },
            { name: "Sep, 23", value: 4100.8 },
            { name: "Oct, 23", value: 4250.7 },
        ]
    },
];