import { Component, OnInit } from '@angular/core';
import { User } from '../../model/user';
import { AuthService } from '../../core/services/auth/auth.service';
import { ProfileService } from '../../core/services/profile/profile.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { MineralRightsOwner, MroStatus } from '../../model/mineralRightsOwner';
import { Subject, catchError, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  public userRegistrationStatus = false;
  public mro: MineralRightsOwner;
  public loggedInUser: User;

  private unsubscribe = new Subject<void>();

  constructor(
    private authService: AuthService,
    private profileService: ProfileService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    //this.userRegistrationStatus = this.authService.getLoggedInUserRegistrationStatus();
    const user = sessionStorage.getItem('user');

    if (environment.mock){

      this.mro = {
        id: 0,
        ownername: '',
        fname: '',
        lname: '',
        suffix: '',
        taxNumber: '',
        address1: '',
        address2: '',
        city: '',
        stateName: '',
        stateId: 0,
        zipCode: '',
        tel: '',
        fax: '',
        cell: '',
        websiteUrl: '',
        status: MroStatus.Initialized,
        operatorMappings: null,
        insertBy: 0,
        insertDate: new Date(),
        modifyBy: 0,
        modifyDate: new Date()
      }
      this.getMRO();
    }
    else {
      const parsedUser = JSON.parse(user);
      this.mro = parsedUser.mro;
    }


  }

  get currentMRO() {
    return this.mro;
  }

  async getLoggedInInfo() {
    this.loggedInUser = this.authService.getUser();

    return this.loggedInUser;
  }

  getMRO(): void {
    this.profileService.getOwner()
      .pipe(
        takeUntil(this.unsubscribe),
        catchError((err) => {
          console.error('getMRO:Error', err);
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this._router.navigate(['/login']);
            }
          }
          return null;
        }
        )
      )
      .subscribe(
          mro => {
            this.mro = mro as MineralRightsOwner;
            sessionStorage.setItem("mro", JSON.stringify(mro));
          }
    );
  }

  changeAddress() {
    this._router.navigate(['/changeAddress']);
  }

  public logout() {
    this.authService.logout();
  }
}
