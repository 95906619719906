import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-invite-user',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
  userEmail = '';
  inviteStatus = '';

  constructor(private route: ActivatedRoute, private router: Router) {}
  ngOnInit() {
    this.route.fragment.subscribe(fragment => {
      if (fragment) {
        const tokenParam = new URLSearchParams(fragment).get('id_token');
        if (tokenParam) {
          console.info('ID Token:', tokenParam);
          this.router.navigate(['/dashboard']);
        }
      }
    });
  }
}
