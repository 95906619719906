<div id="mro_recent_activity" *ngIf="mroRecentActivity">
  <div class="mobile_container">
    <div id="mro_recent_activity_inner">
      <h1 class="mro_title">Recent Activity</h1>
      <div *ngIf="loading">
        <div class="mro_spinner_container">
          <app-spinner [sectionType]="'recentPayment'"></app-spinner>
        </div>
      </div>
      <div *ngIf="!loading && !failed">
        <div class="intro_text">
          <div class="fine_print">
            Reported production, sales & activity
            <span *ngIf="!noData"> - updated{{ noOfDaysAfterUpdate }} days ago</span>
          </div>
        </div>
        <div class="mro_content_inner">
          <div class="site_lists">
            <div *ngIf="!noData">
              <div *ngFor="let activity of recentActivities" class="site_item">
                <div
                  class="site_name"
                  matTooltip="{{ activity.propertyName }}"
                  matTooltipPosition="above"
                >
                  <h3>{{ activity.propertyName }}</h3>
                </div>
                <!-- <div class="site_number" matTooltip="#{{ activity.propertyNo }}" matTooltipPosition="above">#{{ activity.propertyNo }}</div> -->
                <div class="site_icon">
                  <div class="site_icon_main">
                    <ng-container
                      *ngIf="
                        activity.productName.toUpperCase() === 'OIL';
                        else gasIcon
                      "
                    >
                      <span
                        matTooltip="{{ activity.productName.toUpperCase() }}"
                        class="icon_crude_oil"
                      ></span>
                    </ng-container>
                    <ng-template #gasIcon>
                      <ng-container
                        *ngIf="
                          activity.productName.toUpperCase() === 'GAS' ||
                            activity.productName.toUpperCase() === 'LNG';
                          else combinationIcon
                        "
                      >
                        <span
                          matTooltip="{{ activity.productName.toUpperCase() }}"
                          class="icon_flame"
                        ></span>
                      </ng-container>
                      <ng-template #combinationIcon>
                        <ng-container
                          *ngIf="
                            activity.productName.toUpperCase() ===
                              'COMBINATION';
                            else specialIcon
                          "
                        >
                          <span
                            matTooltip="{{
                              activity.productName.toUpperCase()
                            }}"
                            class="icon_crude_oil"
                          ></span>
                        </ng-container>
                        <ng-template #specialIcon>
                          <ng-container
                            *ngIf="
                              activity.productName.toUpperCase() === 'SPECIAL';
                              else otherIcon
                            "
                          >
                            <span
                              matTooltip="{{
                                activity.productName.toUpperCase()
                              }}"
                              class="icon_gas"
                            ></span>
                          </ng-container>
                          <ng-template #otherIcon>
                            <span
                              matTooltip="{{
                                activity.productName.toUpperCase()
                              }}"
                              class="icon_property"
                            ></span>
                          </ng-template>
                        </ng-template>
                      </ng-template>
                    </ng-template>
                  </div>
                  <div class="site_icon_shadow">
                    <span
                      *ngIf="
                        activity.productName.toUpperCase() === 'OIL';
                        else defaultIcon
                      "
                      class="icon_crude_oil_shadow"
                    ></span>
                    <span
                      *ngIf="
                        activity.productName.toUpperCase() === 'GAS' ||
                          activity.productName.toUpperCase() === 'LNG';
                        else defaultIcon
                      "
                      class="icon_flame_shadow"
                    ></span>
                    <span
                      *ngIf="
                        activity.productName.toUpperCase() === 'OTHER';
                        else defaultIcon
                      "
                      class="icon_property_shadow"
                    ></span>
                    <ng-template #defaultShadowIcon
                      ><span
                        matTooltip="{{ activity.productName.toUpperCase() }}"
                        class="icon_crude_oil_shadow"
                      ></span
                    ></ng-template>
                  </div>
                  <div
                    *ngIf="
                      activity.wellStatus &&
                      activity.wellStatus.toUpperCase() == 'ACTIVE'
                    "
                    class="site_status active"
                  ></div>
                  <!-- <div *ngIf="(activity.wellStatus).toUpperCase() == 'PAUSED'" class="site_status paused"></div> -->
                  <!-- <div *ngIf="(activity.wellStatus).toUpperCase() == 'INACTIVE'" class="site_status inactive"></div> -->
                </div>
                <div class="site_value">
                  ${{ activity.ownerGrossValue | number : "1.2-2" : "en-US" }}
                </div>
                <div class="site_production">
                  {{ activity.ownerVolume | number : "1.2-2" : "en-US" }}
                  <span *ngIf="activity.productName.toUpperCase() === 'OIL'"
                    >bbls</span
                  >
                  <span
                    *ngIf="
                      activity.productName.toUpperCase() === 'GAS' ||
                      activity.productName.toUpperCase() === 'LNG'
                    "
                    >MCF</span
                  >
                  <span
                    *ngIf="activity.productName.toUpperCase() === 'COMBINATION'"
                    >bbls</span
                  >
                  <span *ngIf="activity.productName.toUpperCase() === 'SPECIAL'"
                    >MCF</span
                  >
                  <span
                    *ngIf="
                      activity.productName.toUpperCase() !== 'OIL' &&
                      activity.productName.toUpperCase() !== 'GAS' &&
                      activity.productName.toUpperCase() !== 'COMBINATION' &&
                      activity.productName.toUpperCase() !== 'SPECIAL'
                    "
                  >
                  </span>
                </div>
                <div
                  class="site_reference"
                  matTooltip="{{ activity.wellApiNo }}"
                  matTooltipPosition="above"
                >
                  API#{{ activity.wellApiNo }}
                </div>
              </div>
            </div>
            <div *ngIf="noData" class="col_group centered">
              <div class="no_data_text">
                <div class="no_data_text">
                  <div>No recent activity available!</div>
                </div>
              </div>
            </div>
          </div>
          <div class="space_divider extra_padding"></div>
          <div class="col_group centered">
            <div class="col_item">
              <a routerLink="/properties">Properties: {{ noOfProperty }}</a>
            </div>
            <!-- <div class="col_item">
              <a routerLink="/divisionOrders">Smart Division Order&trade;: {{noOfDivisionOrder}}</a>
            </div> -->
          </div>
          <div class="space_divider extra_padding"></div>

          <!-- Info Component -->
          <div class="info_component align_center">
            <div class="fine_print">
              Note: Values are owner quantities, are based on reported sales &
              production figures and may be subject to deductions.
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!loading && failed">
        <div class="mro_error_container">
          <div class="align_center">
            <app-retry
              [parentType]="'recent-activity'"
              (retryRecentActivity)="retry()"
            ></app-retry>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
