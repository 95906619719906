import { DeductionObject } from "../model/deduction";

export const extractDeductionObjects = (objects: DeductionObject[])  =>{
  // eslint-disable-next-line prefer-const
  let deductionHeaders: string[] = [];
  for (const object of objects) {
    deductionHeaders.push(object.name);
  }
  return deductionHeaders;
}
