import { counties, lovingTX } from './counties';
import { Property } from "../model/property";
import { randomDateMinOrMax } from './helpers/randomDateHelper';
import { getRandomInt } from './helpers/randomNumbersHelper';
import { wells } from './wells';

export  const property1: Property = {
  id: 1,
  propertyNo: '775008 O0001',
  name: 'WHEAT TRUST C27-4 LOV 2H',
  description: 'SECTION 4, BLOCK C-27, PUBLIC SCHOOL LANDS SURVEY, A-771, LOVING COUNTY, TEXAS',
  geographicalData: {
    county: lovingTX,
    section: '4',
    block: 'C-27',
    surveyName: 'PUBLIC SCHOOL LANDS SURVEY',
    abstract: 'A-771',
    grossAcreage: 21,
    netAcreage: 21,
    insertBy: 1,
    insertDate: new Date('09/26/2022'),
    modifyBy: 1,
    modifyDate: new Date('09/26/2022'),
    sectionNo: '4',
    township: 'Loving County',
    range: ''
  },
  effectiveFrom: new Date('09/26/2022'),
  wells: [
    wells[1]
  ]
};

export  const property2: Property = {
  id: 2,
  propertyNo: 'HEARTLAND-014',
  name: 'HEARTLAND',
  description: 'SW/4 NW/4 AND NE/4 SW/4, SECTION 17, TOWNSHIP 8 NORTH, RANGE 11 WEST, FAULKNER COUNTY, ARKANSAS',
  geographicalData: {
    county: counties[getRandomInt(0, 49)], 
    section: '17',
    block: null,
    surveyName: null,
    abstract: null,
    grossAcreage: null,
    netAcreage: null,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '8 NORTH',
    range: '11 WEST'
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [
    wells[4]
  ]
};

export  const property3: Property = {
  id: 3,
  propertyNo: 'HAVERFORD-214',
  name: 'HAVERFORD',
  description: 'KARNES, TX - DOC UNIT (FPM - HAVERFORD)',
  geographicalData: {
    county: counties[getRandomInt(0, 49)],
    section: null,
    block: null,
    surveyName: null,
    abstract: null,
    grossAcreage: null,
    netAcreage: null,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '',
    range: ''
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [
    wells[2]
  ]
};

export  const property4: Property = {
  id: 4,
  propertyNo: 'HEARTLAND-010',
  name: 'HEARTLAND',
  description: 'SW/4 NW/4 AND NW/4 SW/4 SECTION 6, TOWNSHIP 8 NORTH, RANGE 13 WEST, FAULKNER COUNTY, ARKANSAS',
  geographicalData: {
    county: counties[getRandomInt(0, 49)], 
    section: '6',
    block: null,
    surveyName: null,
    abstract: null,
    grossAcreage: null,
    netAcreage: null,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '8 NORTH',
    range: '13 WEST'
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [
    wells[2]
  ]
};

export  const property5: Property = {
  id: 5,
  propertyNo: 'HEARTLAND-011',
  name: 'HEARTLAND',
  description: 'S/2 NW/4 AND NW/4 SE/4, SECTION 5, TOWNSHIP 8 NORTH, RANGE 13 WEST, FAULKNER COUNTY, ARKANSAS',
  geographicalData: {
    county: counties[getRandomInt(0, 49)], 
    section: '5',
    block: null,
    surveyName: null,
    abstract: null,
    grossAcreage: null,
    netAcreage: null,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '8 NORTH',
    range: '13 WEST'
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [
    wells[1]
  ]
};
export  const property6: Property = {
  id: 6,
  propertyNo: 'HEARTLAND-001',
  name: 'HEARTLAND',
  description: 'S/2 SE/4 SECTION 37, BLOCK 38, T-1-S, T&P RR CO SURVEY, MIDLAND COUNTY, TEXAS, CONTAINING 80 ACRES, MORE OR LESS',
  geographicalData: {
    county: counties[getRandomInt(0, 49)], 
    section: '37',
    block: '38',
    surveyName: 'T&P RR CO SURVEY',
    abstract: 'T-1-S',
    grossAcreage: 80,
    netAcreage: 80,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '',
    range: ''
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [
    wells[5]
  ]
};

export  const property7: Property = {
  id: 7,
  propertyNo: 'HEARTLAND-002',
  name: 'HEARTLAND',
  description: 'UPTON COUNTY, TX - 640 ACRES, A-118 & A-232 (FPM - HEARTLAND)',
  geographicalData: {
    county: counties[getRandomInt(0, 49)],
    block: null,
    surveyName: null,
    abstract: null,
    grossAcreage: null,
    netAcreage: 640,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '',
    range: '',
    section: ''
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [
    wells[6]
  ]
};

export  const property8: Property = {
  id: 8,
  propertyNo: 'HEARTLAND-004',
  name: 'HEARTLAND',
  description: 'NW/4 SECTION 13, TOWNSHIP 21 SOUTH, RANGE 35 WEST, KEARNY COUNTY, KANSAS',
  geographicalData: {
    county: counties[getRandomInt(0, 49)], 
    section: '13',
    block: null,
    surveyName: null,
    abstract: null,
    grossAcreage: null,
    netAcreage: null,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '21 SOUTH',
    range: '35 WEST'
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [
    wells[1]
  ]
};

export  const property9: Property = {
  id: 9,
  propertyNo: 'HEARTLAND-006',
  name: 'HEARTLAND',
  description: `656.0995 ACRES, MORE OR LESS, MORE SPECIFICALLY DESCRIBED IN THAT CERTAIN UNIT DESIGNATION, DATED JAN 2, 2013, 
                RECORDED IN VOL 610, PG 740 OF THE OFFICIAL RECORDS OF LAVACA COUNTY, TEXAS, TOGETHER WITH ANY AMENDMENTS AND REVISIONS THERETO.
                
                67.5 acres of land, more or less, out of the Wm. Strode Survey, A-52, Lavaca County, Texas and being the same land described 
                in that certain Deed dated March 7, 1946 from V. H. Houston and wife, Edith Houston to Oscar Marburger, recorded in Volume 153, 
                Page 602, Deeed Records, Lavaca County, Texas`,
  geographicalData: {
    county: counties[getRandomInt(0, 49)],  
    section: '',
    block: '',
    surveyName: '',
    abstract: 'A-52',
    grossAcreage: 656.0995,
    netAcreage: 67.5,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '',
    range: ''
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [ 
    wells[5]
  ]
};

export const property10: Property = {
  id: 10,
  propertyNo: 'HEARTLAND-007',
  name: 'HEARTLAND',
  description: 'S/2 SW/4 SECTION 26, TOWNSHIP 10 NORTH, RANGE 12 WEST, CLEBURNE COUNTY, ARKANSAS',
  geographicalData: {
    county: counties[getRandomInt(0, 49)], 
    section: '26',
    block: null,
    surveyName: null,
    abstract: null,
    grossAcreage: null,
    netAcreage: null,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '10 NORTH',
    range: '12 WEST'
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [
    wells[1]
  ]
};

export const property11: Property = {
  id: 11,
  propertyNo: 'HEARTLAND-009',
  name: 'HEARTLAND',
  description: 'ALL OF SECTION 7, TOWNSHIP 8 NORTH, RANGE 13 WEST, FAULKNER COUNTY, ARKANSAS',
  geographicalData: {
    county: counties[getRandomInt(0, 49)], 
    section: '7',
    block: null,
    surveyName: null,
    abstract: null,
    grossAcreage: null,
    netAcreage: null,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '8 NORTH',
    range: '13 WEST'
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [
    wells[2]
  ]
};

export const property12: Property = {
  id: 12,
  propertyNo: 'HEARTLAND-013',
  name: '14-8N-12W N/2 SE/4, FAULKNER, AR',
  description: 'N/2 SE/4 LESS AND EXCEPT THE SOUTH 115 YARDS OF THE WEST 213 YARDS OF THE NW/4 SE/4, SECTION 14, TOWNSHIP 8 NORTH, RANGE 12 WEST, FAULKNER COUNTY, ARKANSAS',
  geographicalData: {
    county: counties[getRandomInt(0, 49)],  
    section: '14',
    block: null,
    surveyName: null,
    abstract: null,
    grossAcreage: null,
    netAcreage: null,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '8 NORTH',
    range: '12 WEST'
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [
    wells[3]
  ]
};

export const property13: Property = {
  id: 13,
  propertyNo: 'HEARTLAND-012',
  name: 'HEARTLAND',
  description: 'E/2 NW/4 AND E/2 SE/4 SW/4, SECTION 4, TOWNSHIP 8 NORTH, RANGE 13 WEST, FAULKNER COUNTY, ARKANSAS',
  geographicalData: {
    county: counties[getRandomInt(0, 49)], 
    section: '4',
    block: null,
    surveyName: null,
    abstract: null,
    grossAcreage: null,
    netAcreage: null,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '8 NORTH',
    range: '13 WEST'
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [
    wells[3]
  ]
};

export const property14: Property = {
  id: 14,
  propertyNo: 'HEARTLAND-016',
  name: 'HEARTLAND',
  description: 'E/2 SE/4 SECTION 24, TOWNSHIP 9 NORTH, RANGE 15 WEST, CONWAY COUNTY, ARKANSAS EXCEPT 2 ACRES IN THE NE CORNER THEREOF CONVEYED FOR A SCHOOL DESCRIBED AS FOLLOWS:  BEGIN AT THE NE CORNER OF THE NE/4 SE/4 AND RUN WEST 70 YARDS; THENCE SOUTH 140 YARDS; THENCE EAST 70 YARDS, THENCE NORTH 140 YARDS TO THE POINT OF BEGINNING.',
  geographicalData: {
    county: counties[getRandomInt(0, 49)], 
    section: '24',
    block: null,
    surveyName: null,
    abstract: null,
    grossAcreage: null,
    netAcreage: null,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '9 NORTH',
    range: '15 WEST'
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [
    wells[5]
  ]
};

export const property15: Property = {
  id: 15,
  propertyNo: 'HEARTLAND-017',
  name: 'HEARTLAND',
  description: 'N/2 NE/4 SECTION 30, TOWNSHIP 9 NORTH, RANGE 14 WEST, CONWAY COUNTY, ARKANSAS',
  geographicalData: {
    county: counties[getRandomInt(0, 49)], 
    section: '30',
    block: null,
    surveyName: null,
    abstract: null,
    grossAcreage: null,
    netAcreage: null,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '9 NORTH',
    range: '14 WEST'
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [
    wells[4]
  ]
};

export const property17: Property = {
  id: 17,
  propertyNo: 'HEARTLAND-018',
  name: 'HEARTLAND',
  description: 'S/2 SW/4 SE/4, SECTION 19, TOWNSHIP 9 NORTH, RANGE 14 WEST, CONWAY COUNTY, ARKANSAS',
  geographicalData: {
    county: counties[getRandomInt(0, 49)],
    section: '19',
    block: null,
    surveyName: null,
    abstract: null,
    grossAcreage: null,
    netAcreage: null,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '9 NORTH',
    range: '14 WEST'
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [
    wells[1]
  ]
};

export const property18: Property = {
  id: 18,
  propertyNo: 'HEARTLAND-020',
  name: 'KARNES, TX - MAURER-MCFARLAND UNIT (FPM - HEARTLAND)',
  description: '',
  geographicalData: {
    county: counties[getRandomInt(0, 49)],
    section: null,
    block: null,
    surveyName: null,
    abstract: null,
    grossAcreage: null,
    netAcreage: null,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '',
    range: ''
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [
    wells[5]
  ]
};

export const property19: Property = {
  id: 19,
  propertyNo: 'HEARTLAND-008',
  name: 'HEARTLAND',
  description: 'ALL OF SECTION 15, TOWNSHIP 7 NORTH, RANGE 16 WEST, CONWAY COUNTY, ARKANSAS',
  geographicalData: {
    county: counties[getRandomInt(0, 49)],
    section: '15',
    block: null,
    surveyName: null,
    abstract: null,
    grossAcreage: null,
    netAcreage: null,
    insertBy: 1,
    insertDate: randomDateMinOrMax(2022, 1999, true),
    modifyBy: 1,
    modifyDate: randomDateMinOrMax(2022, new Date().getFullYear(), true),
    sectionNo: '',
    township: '7 NORTH',
    range: '16 WEST'
  },
  effectiveFrom: randomDateMinOrMax(2022, 1999, true),
  wells: [
    wells[1]
  ]
};

export const properties: Property[] = [
  property1,
  property2,
  property3,
  property4,
  property5,
  property6,
  property7,
  property8,
  property9,
  property10,
  property11,
  property12,
  property13,
  property14,
  property15,
  property17,
  property18,
  property19
];

