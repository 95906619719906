import { Component, Input } from '@angular/core';
import { Property } from '../../../model/property';
import { CommonModule } from '@angular/common';
import { Well } from '../../../model/well';

@Component({
  selector: 'app-well-widget',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './well-widget.component.html',
  styleUrl: './well-widget.component.css'
})
export class WellWidgetComponent {
  @Input() property: Property;
  @Input() well: Well;
  @Input() wellIndex: number;

  navigateToWellGIS(wellGIS: string) {
    window.open(wellGIS, '_blank');
  }
}
