import { MroRecentActivity } from './../../../model/recent_activity';
import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { RecentActivityService } from '../../../core/services/recent-activity/recent-activity.service';
import { LoaderService } from '../../../model/loader/services/loader/loader.service';
import { Subject, takeUntil, finalize, catchError, of } from 'rxjs';
import { PropertyService } from '../../../core/services/property/property.service';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from '../../spinner/spinner.component';
import { ActivityLoadStatus } from '../../../model/activity';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-recent-activity',
  standalone: true,
  imports: [
    CommonModule,
    SpinnerComponent,
    RouterModule,
    MatTooltipModule
  ],
  templateUrl: './recent-activity.component.html',
  styleUrls: ['./recent-activity.component.css']
})
export class RecentActivityComponent implements OnInit {

  public recentActivities: MroRecentActivity[] = [];
  public noOfDaysAfterUpdate: number;
  public noOfProperty: number;
  public noOfDivisionOrder: number;
  public noData: boolean;

  public OIL = 1;
  public GAS = 2;
  public COMBINATION = 3;
  public SPECIAL = 4;
  public OTHER = 5;

  public ActivityLoadStatus: ActivityLoadStatus = ActivityLoadStatus.NONE;

  private unsubscribe = new Subject();

  constructor(
    private recentActivityService: RecentActivityService,
    private router: Router,
    private loaderService: LoaderService,
    private propertyService: PropertyService) { }

  ngOnInit() {
    this.getRecentActivities();
    this.loadProperties();
  }

  get loading() {
    if (this.recentActivities.length > 0 || this.noData === true) {
      return false;
    } else {
      return true;
    }
  }

  get mroRecentActivity() {
    return this.recentActivities;
  }

  public retry() {
    this.getRecentActivities();
  }

  public getRecentActivities() {
    this.loaderService.openLoader();
    this.ActivityLoadStatus = ActivityLoadStatus.NONE;
    this.recentActivityService.getMroRecentActivity()
      .pipe(
        takeUntil(this.unsubscribe),
        catchError(error => {
          if (error.status === 500) {
            this.ActivityLoadStatus = ActivityLoadStatus.FAILED;
          }
          return of(null);
        }),
        finalize(() => this.loaderService.closeLoader())
      )
      .subscribe(activities => {
        if (activities !== null) {
          if (activities.length === 0) {
            this.noData = true;
          } else if (activities.length === 1 && activities[0].propertyId === null) {
            this.noData = true;
          }
          this.recentActivities = activities;
          this.ActivityLoadStatus = ActivityLoadStatus.COMPLETE;
          if (this.recentActivities.length > 0 && this.recentActivities[0].paymentDate !== null) {
            this.calculateDateDiffInDays(this.recentActivities[0].paymentDate);
          }
          this.propertyService.getProperties();
          this.noOfDivisionOrder = this.groupBy(this.recentActivities, RecentActivity => RecentActivity.divisionOrderId).size;
        }
      });
  }

  private loadProperties() {
    this.propertyService.getMroProperties()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(properties => {
        this.propertyService.setProperties(properties);
        this.noOfProperty = properties.length;
      });
  }

  public groupBy(list, keyGetter) {
    const map = new Map();
    if (Array.isArray(list) && list.length === 0) {
      list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
          map.set(key, [item]);
        } else {
          collection.push(item);
        }
      });
    }
    return map;
  }

  public calculateDateDiffInDays(dateSent) {
    const currentDate = new Date();
    dateSent = new Date(dateSent);
    this.noOfDaysAfterUpdate = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24));
  }

  public getProductIconType(productId: number): number {
    const oilType: number[] = [5, 10, 11, 36];
    const gasType: number[] = [20, 21, 22, 23, 30, 31, 32, 33, 34, 35];
    const combinationType: number[] = [12, 42];
    const specialType: number[] = [41, 43, 44];
    const otherType: number[] = [40, 50, 60, 90];

    if (oilType.find(element => element === productId)) {
      return this.OIL;

    } else if (gasType.find(element => element === productId)) {
      return this.GAS;

    } else if (combinationType.find(element => element === productId)) {
      return this.COMBINATION;

    } else if (specialType.find(element => element === productId)) {
      return this.SPECIAL;

    } else if (otherType.find(element => element === productId)) {
      return this.OTHER;
    }
  }
}
