import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-divisionorders',
  templateUrl: './divisionorders.component.html',
  styleUrls: ['./divisionorders.component.css']
})
export class DivisionordersComponent {

  constructor(
    private router: Router
  ) { }

  public gotoDashboard() {
    this.router.navigate(['/dashboard']);
  }
}
