<div class="link_options_container">
  <div class="link_options pos_left">
    <ul>
      <li>
        <button type="button" class="btn_normal btn_purple" (click)="exportToCSV()">
          <span class="btn_icon">
            <span class="icon_csv"></span>
          </span>
          <span class="btn_text">CSV Export</span>
        </button>
      </li>
    </ul>
  </div>
  <div class="link_options pos_right">
    <ul>
      <li>
        <button type="button" class="btn_normal btn_purple" (click)="back()">
          <span class="btn_icon"></span>
          <span class="btn_text">Close</span>
        </button>
      </li>
    </ul>
  </div>
</div>
