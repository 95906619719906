import { Component, Input, OnInit } from '@angular/core';
import { Payment, RoyaltyPayment } from '../../model/payments';
import { extractDeductionObjects } from '../../utilities/extractHeadings';

@Component({
  selector: 'app-grid-view',
  templateUrl: './grid-view.component.html',
  styleUrl: './grid-view.component.css'
})
export class GridViewComponent implements OnInit {
  @Input() statement: Payment;
  public deductionHeaders: string[];
  public salesDeductionHeaders: string[];
  public columns: boolean[] = [];
  public deductionColumns: boolean[] = [];
  public salesDeductionColumns: boolean[] = [];
  public columnsSorted: boolean[] = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false];
  public selectedColumn: number;
  public selectedDirection: string;

  ngOnInit() {
    if (this.statement && this.statement.royaltyPayments && this.statement.royaltyPayments.length >= 1) {
      this.deductionHeaders = extractDeductionObjects(this.statement.royaltyPayments[0].deductionObjects);
      this.salesDeductionHeaders = extractDeductionObjects(this.statement.royaltyPayments[0].sale.deductionObjects);
    }
    this.getColumnsToDisplay();
    this.getDeductionColumnsToDisplay();
    this.getSalesDeductionColumnsToDisplay();
  }

  isSelected(column: number, direction: string) {
    return this.selectedColumn === column && this.selectedDirection === direction;
  }

  getDeductionColumnsToDisplay() {
    for (let i = 0; i < this.statement.royaltyPayments.length; i++) {
      for (let j = 0; j < this.deductionHeaders.length; j++) {
        if (this.statement.royaltyPayments[i].deductionObjects && this.statement.royaltyPayments[i].deductionObjects[j]) {
          if (this.statement.royaltyPayments[i].deductionObjects[j].amount !== null) {
            this.deductionColumns[j] = true;
          } else {
            if (this.deductionColumns[j] !== true) {
              this.deductionColumns[j] = false;
            }
          }
        } else {
          if (this.deductionColumns[j] !== true) {
            this.deductionColumns[j] = false;
          }
        }
      }
    }
  }

  getSalesDeductionColumnsToDisplay() {
    for (let i = 0; i < this.statement.royaltyPayments.length; i++) {
      for (let j = 0; j < this.salesDeductionHeaders.length; j++) {
        if (this.statement.royaltyPayments[i].sale.deductionObjects && this.statement.royaltyPayments[i].sale.deductionObjects[j]) {
          if (this.statement.royaltyPayments[i].sale.deductionObjects[j].amount !== null) {
            this.salesDeductionColumns[j] = true;
          } else {
            if (this.salesDeductionColumns[j] !== true) {
              this.salesDeductionColumns[j] = false;
            }
          }
        } else {
          if (this.salesDeductionColumns[j] !== true) {
            this.salesDeductionColumns[j] = false;
          }
        }
      }
    }
  }

  getColumnsToDisplay() {
    for (let i = 0; i < this.statement.royaltyPayments.length; i++) {
      this.columns[0] = this.statement.royaltyPayments[i].paymentDate !== null;
      this.columns[1] = this.statement.royaltyPayments[i].well.name !== null;
      this.columns[4] = this.statement.royaltyPayments[i].sale.prodDate !== null;
      this.columns[5] = this.statement.royaltyPayments[i].sale.product.name !== null;
      this.columns[6] = this.statement.royaltyPayments[i].sale.unitPrice !== null;
      this.columns[7] = this.statement.royaltyPayments[i].sale.saleVolume !== null;
      this.columns[8] = this.statement.royaltyPayments[i].sale.grossValue !== null;
      this.columns[9] = this.statement.royaltyPayments[i].sale.severance !== null;
      this.columns[10] = this.statement.royaltyPayments[i].sale.netValue !== null;
      this.columns[11] = this.statement.royaltyPayments[i].divisionOrder.interestType.name !== null;
      this.columns[12] = this.statement.royaltyPayments[i].divisionOrder.decimalInterest !== null;
      this.columns[13] = this.statement.royaltyPayments[i].mroVolume !== null;
      this.columns[14] = this.statement.royaltyPayments[i].mroGrossValue !== null;
      this.columns[15] = this.statement.royaltyPayments[i].severance !== null;
      this.columns[16] = this.statement.royaltyPayments[i].mroNetValue !== null;
    }
  }

  convertToDate(dateString: string): Date {
    if (dateString === null) {
      return null;
    } else {
      const year = Number(dateString.substr(0, 4));
      const month = Number(dateString.substr(4, 2)) - 1;
      return new Date(year, month);
    }
  }

  sortByDeduction(propertyPath: string, type: string, direction: string) {
    this.statement.royaltyPayments.sort((a, b) => {
      const aValue = type === 'sales' ? a.sale.deductionObjects.find(obj => obj.name === propertyPath)?.amount : a.deductionObjects.find(obj => obj.name === propertyPath)?.amount;
      const bValue = type === 'sales' ? b.sale.deductionObjects.find(obj => obj.name === propertyPath)?.amount : b.deductionObjects.find(obj => obj.name === propertyPath)?.amount;
      if (aValue < bValue) {
        return direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }



  sortBy(column: number, direction: string) {
    this.selectedColumn = column;
    this.selectedDirection = direction;
    switch (column) {
      case 0:
        this.sortByProperty(column, (a, b) => this.convertToDate(a.paymentDate.toString())?.getTime() - this.convertToDate(b.paymentDate.toString())?.getTime(), direction);
        break;
      case 1:
        this.sortByProperty(column, (a, b) => a.well.name.localeCompare(b.well.name), direction);
        break;
      case 4:
        this.sortByProperty(column, (a, b) => this.convertToDate(a.sale.prodDate?.toString())?.getTime() - this.convertToDate(b.sale.prodDate?.toString())?.getTime(), direction);
        break;
      case 5:
        this.sortByProperty(column, (a, b) => a.sale.product.id - b.sale.product.id, direction);
        break;
      case 6:
        this.sortByProperty(column, (a, b) => a.sale.unitPrice - b.sale.unitPrice, direction);
        break;
      case 7:
        this.sortByProperty(column, (a, b) => a.sale.saleVolume - b.sale.saleVolume, direction);
        break;
      case 8:
        this.sortByProperty(column, (a, b) => a.sale.grossValue - b.sale.grossValue, direction);
        break;
      case 9:
        this.sortByProperty(column, (a, b) => a.sale.severance - b.sale.severance, direction);
        break;
      case 10:
        this.sortByProperty(column, (a, b) => a.sale.netValue - b.sale.netValue, direction);
        break;
      case 11:
        this.sortByProperty(column, (a, b) => a.divisionOrder.interestType.id - b.divisionOrder.interestType.id, direction);
        break;
      case 12:
        this.sortByProperty(column, (a, b) => a.divisionOrder.decimalInterest - b.divisionOrder.decimalInterest, direction);
        break;
      case 13:
        this.sortByProperty(column, (a, b) => a.mroVolume - b.mroVolume, direction);
        break;
      case 14:
        this.sortByProperty(column, (a, b) => a.mroGrossValue - b.mroGrossValue, direction);
        break;
      case 15:
        this.sortByProperty(column, (a, b) => a.severance - b.severance, direction);
        break;
      case 16:
        this.sortByProperty(column, (a, b) => a.mroNetValue - b.mroNetValue, direction);
        break;
    }
  }

  private sortByProperty(column: number, compareFn: (a: RoyaltyPayment, b: RoyaltyPayment) => number, direction: string) {
    this.statement.royaltyPayments.sort(compareFn);
    if (direction === 'dsc') {
      this.statement.royaltyPayments.reverse();
    }
  }

  private sortByDeductionProperty(deductionKey: string, compareFn: (a: unknown, b: unknown) => number, direction: string) {
    this.statement.royaltyPayments.sort((a, b) => compareFn(a.deductionObjects[deductionKey], b.deductionObjects[deductionKey]));
    if (direction === 'dsc') {
      this.statement.royaltyPayments.reverse();
    }
  }

}
