import { PieChartData } from "../model/chart-data";
import { PaymentSummary, PaymentSummaryData } from "../model/payment-summary";

// Mock data for getMroPaymentSummaryByPeriodDataObjects
export const mockPieChartData: PieChartData[] = [
    { name: 'Nov, 22', value: 17641.49 },
    { name: 'Dec, 22', value: 14369.73 },
    { name: 'Jan, 23', value: 12826.54 },
    { name: 'Feb, 23', value: 7492.53 },
    { name: 'Mar, 23', value: 8508.62 },
    { name: 'Apr, 23', value: 8062.8 },
    { name: 'May, 23', value: 7410.44 },
    { name: 'Jun, 23', value: 6452.43 },
    { name: 'Jul, 23', value: 6284.67 },
    { name: 'Aug, 23', value: 6043.18 },
    { name: 'Sep, 23', value: 5739.04 },
    { name: 'Oct, 23', value: 5034.2 },
    { name: 'Nov, 23', value: 5084.78 },
    
  ];
  
  // Mock data for getMroPaymentSummaryByProperty
  export const mockPaymentSummaryByProperty: PaymentSummaryData[] = [
    { name: '24-9N-15W E/2 SE/4, CONWAY, AR', value: 31.6 },
    { name: '13-21S-35W NW/4, KEARNY, KS (FPM - HEARTLAND)', value: 311.4 },
    { name: '14-8N-12W N/2 SE/4, FAULKNER, AR', value: 1.7 },
    { name: '19-9N-14W S/2 SW/4 SE/4, CONWAY, AR', value: 15.39 },
    { name: '26-10N-12W S/2 SW/4, CLEBURNE, AR', value: 17.83 },
    { name: '30-9N-14W N/2 NE/4, CONWAY, AR ', value: 16.31 },
    { name: '4-8N-13W E/2 NW/4, E/2 SE/4 SW/4, FAULKNER, AR', value: 4.95 },
    { name: '5-8N-13W S/2 NW/4, NW/4 SE/4, FAULKNER, AR', value: 13.01 },
    
  ];
  
  // Mock data for getMroPaymentSummaryByProduct
  export const mockPaymentSummaryByProduct: PaymentSummaryData[] = [
    { name: 'Condensate', value: 0.12 },
    { name: 'Gas', value: 10575.02 },
    { name: 'Oil', value: 93008.28 },
    { name: 'Other', value: 7367.03 },
    { name: 'Combination', value: 1112.0 },
    
  ];

  export const mockPieChartDataCard: PaymentSummary = {
    noProperties: 12,
    noProducts: 17,
    noLineItems: 12635,
    
    grossAmount: 117420.67,
    netAmount: 110950.45,
    severance: 3053.12,
    deductions: 519.55,
    data: [
        { name: 'Nov, 22', value: 17641.49 },
        { name: 'Dec, 22', value: 14369.73 },
        { name: 'Jan, 23', value: 12826.54 },
        { name: 'Feb, 23', value: 7492.53 },
        { name: 'Mar, 23', value: 8508.62 },
        { name: 'Apr, 23', value: 8062.8 },
        { name: 'May, 23', value: 7410.44 },
        { name: 'Jun, 23', value: 6452.43 },
        { name: 'Jul, 23', value: 6284.67 },
        { name: 'Aug, 23', value: 6043.18 },
        { name: 'Sep, 23', value: 5739.04 },
        { name: 'Oct, 23', value: 5034.2 },
        { name: 'Nov, 23', value: 5084.78 },
    ]
};
