import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { StorageService } from '../../../../core/services/storage/storage.service';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { NoDataComponent } from '../../../no-data/no-data.component';

@Component({
  selector: 'app-pie-chart',
  standalone: true,
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.css'],
  imports: [
    CommonModule,
    FormsModule,
    NgxChartsModule,
    SpinnerComponent,
    NoDataComponent
  ],
  encapsulation: ViewEncapsulation.None
})
export class PieChartComponent {
  @Input() period?: string;
  @Input() sectionType: string;
  @Input() isDateRange: boolean;

  public animations: boolean = true;
  public useGradient: boolean = true;
  public legend: boolean = true;

  constructor(
    private storageService: StorageService
  ) {}
  get isMobile() {
    return window.innerWidth <= 768;
  }


    get pieChartData() {
        switch (this.sectionType) {
          case 'payment':
            return this.storageService.getMroPaymentSummaryDataObjects();
          case 'property':
            return this.storageService.getMroPaymentSummaryByProperty();
          case 'product':
            return this.storageService.getMroPaymentSummaryByProduct();
        }
    }

    get dataValid() {
      switch (this.sectionType) {
        case 'payment':
          return this.storageService.getIsMroPaymentSummaryValid();
        case 'property':
          return this.storageService.getIsMroPaymentSummaryByPropertyValid();
        case 'product':
          return this.storageService.getIsMroPaymentSummaryByProductValid();
      }
    }

    get loading() {
      if (this.pieChartData) {
        return this.pieChartData.length <= 0;
      } else {
        return true;
      }
    }

    get centerValue() {
      switch (this.sectionType) {
        case 'payment':
          return this.pieChartData.reduce((sum, data) => sum + data.value, 0);
        case 'property':
          return this.pieChartData.reduce((sum, data) => sum + data.value, 0);
        case 'product':
          return this.pieChartData.reduce((sum, data) => sum + data.value, 0);
      }
    }

    get centerLabel() {
      switch (this.sectionType) {
        case 'payment':
          return 'Total Payments';
        case 'property':
          return 'Net Revenue';
        case 'product':
          return 'Net Revenue';
      }
    }

 }
