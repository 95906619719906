import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Partner } from '../../../model/partner';

@Injectable({
  providedIn: 'root'
})
export abstract class PartnerService {
  public partner: Partner;

  abstract getPartner(): Observable<Partner>;

  setPartner(partner: Partner): void {
    this.partner = partner;
  }

  getPartnerData(): Partner {
    return this.partner;
  }
}
