import { Injectable } from '@angular/core';
import { PieChartData } from '../../../model/chart-data';
import { PaymentSummary } from '../../../model/payment-summary';

@Injectable({
  providedIn: 'root'
})
export class ChartDataProcessingService {

  constructor() { }

  public getPaymentSummaryDataObjects(paymentSummaryData: PaymentSummary): PieChartData[] {
    const pieChartData: PieChartData[] = [];
      for (const data of paymentSummaryData.data) {
          const name = data.name;
          const value = data.value;
          pieChartData.push({ name, value });
      }
    return pieChartData;
  }

}
