import { Injectable } from '@angular/core';
import { MarketService } from './market.service';
import { Observable, of } from 'rxjs';
import { ResourcePriceData } from '../../../model/resourcePriceData';
import { naturalGasPrices } from 'src/app/mockDB/naturalGasPrices';

@Injectable({
  providedIn: 'root'
})
export class MockMarketService extends MarketService{

  constructor() {
    super();
   }

   private transformPricesToResourcePriceData(prices): ResourcePriceData[] {
    return prices.map((priceData, index) => ({
      id: index + 1,
      readingDateTime: priceData.date.toISOString(),
      commodity: 'Natural Gas',
      price: priceData.price,
      difference: priceData.price - priceData.lastPrice
    }));
  }

  getMarketPrice(resource: string): Observable<ResourcePriceData> {
    console.info('MockMarketService.getMarketPrice()', resource);
    const transformedNaturalGasPrices = this.transformPricesToResourcePriceData(naturalGasPrices);
    // Return the first item or any specific item as needed
    return of(transformedNaturalGasPrices[0]);
  }
}
