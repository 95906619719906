import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { MroRecentActivity } from '../../../model/recent_activity';

@Injectable({
  providedIn: 'root'
})
export abstract class RecentActivityService {

  constructor() { }

  abstract getMroRecentActivity(): Observable<MroRecentActivity[]>;
}
