import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MroPerformance, PropertyPerformance } from '../../../model/performance';

@Injectable({
  providedIn: 'root'
})
export abstract class PerformanceService {
  private performanceData: MroPerformance[] = [];
  
  abstract getMROPerformance(periodStart: Date, periodEnd: Date): Observable<MroPerformance[]>;
  abstract getPropertyPerformance(propertyId: number): Observable<PropertyPerformance[]>;

  getPerformances(): MroPerformance[] {
    return this.performanceData;
  }

  setPerformances(performances: MroPerformance[]): void {
    this.performanceData = performances;
  }


}
