<div id="mro_content">
  <div class="mobile_container">
    <div id="mro_recent_payments_inner" *ngIf="!loading">
      <h1 class="mro_title">Payments</h1>
      <div class="mro_content_inner">
        <div class="col_group centered">
          <div class="col_item">
            <form [formGroup]="paymentForm">
              <div class="form_row no_padding">
                <div class="form_label hidden_label">
                  <label for="operator_list">Operators</label>
                </div>
                <div class="form_input">
                  <select
                    class="inputbox inputbox_filter"
                    id="operator_list"
                    name="operator_list"
                    formControlName="operatorId"
                    (change)="onOperatorChange()"
                  >
                    <option value="" [selected]="">All Operators</option>
                    <option
                      *ngFor="let operator of operators"
                      value="{{ operator.id }}"
                    >
                      {{ operator.name }}
                    </option>
                  </select>
                </div>
              </div>
            </form>
          </div>

          <div class="col_item">
            <div class="date_range">
              <ul class="button_group">
                <li>
                  <button type="button"
                          class="btn_normal btn_group"
                          (click)="onDownMonth()"
                          [disabled]="isMinMonth">
                    <span class="btn_icon"
                      ><span class="icon_chevron_left"></span
                    ></span>
                    <span class="btn_text"
                      ><span class="hidden_text">Previous</span></span
                    >
                  </button>
                </li>
                <li>
                  <button type="button" class="btn_normal btn_group">
                    <span class="btn_text">
                      {{monthStr}}
                    </span>
                  </button>
                </li>
                <li>
                  <button type="button"
                          class="btn_normal btn_group"
                          (click)="onUpMonth()"
                          [disabled]="isMaxMonth">
                    <span class="btn_icon"
                      ><span class="icon_chevron_right"></span
                    ></span>
                    <span class="btn_text"
                      ><span class="hidden_text">Next</span></span
                    >
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="mro_content_inner"  *ngIf="!loading">
        <ul class="payments_list" *ngIf="recordsAvailable">
            <li *ngFor="let payment of payments">
              <div class="payments_container" >
                <a routerLink="/statement/{{payment.paymentDate | date: 'yyyyMM'}}/{{payment.id}}"
                  [ngClass]="{'disabled-link': payment.royaltyPayments.length == 0}"
                  (click)="payment.royaltyPayments.length == 0 && $event.preventDefault()"
                  class="payments_link">
                  <div class="paymnent_date">
                    {{ payment.paymentDate | date }}
                  </div>
                  <div class="payment_description">
                    {{payment.operator.name}}
                  </div>
                  <div class="payment_value">
                    <span *ngIf="payment.checkAmount != null">$</span>
                    {{ payment.checkAmount | number: ".2":"en-US" }}
                  </div>
                  <span class="payment_status" *ngIf="payment.royaltyPayments.length == 0">Pending</span>
                </a>
              </div>
            </li>
        </ul>
        <ul class="payments_list" *ngIf="!recordsAvailable">
          <li>
            <div class="payments_container">
              <div class="payment_description no-records">
                <span><b>No records available</b></span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div  *ngIf="loading">
      <app-spinner [sectionType]="'home'"></app-spinner>
    </div>
  </div>
</div>
