import { State } from "../model/state";

export  const alaska: State = {
  id: 1,
  name: 'Alaska',
  abbr: 'AK'
};

export  const alabama: State = {
  id: 2,
  name: 'Alabama',
  abbr: 'AL'
};

export  const arkansas: State = {
  id: 3,
  name: 'Arkansas',
  abbr: 'AR'
};

export  const arizona: State = {
  id: 4,
  name: 'Arizona',
  abbr: 'AZ'
};

export  const california: State = {
  id: 5,
  name: 'California',
  abbr: 'CA'
};

export  const colorado: State = {
  id: 6,
  name: 'Colorado',
  abbr: 'CO'
};

export  const connecticut: State = {
  id: 7,
  name: 'Connecticut',
  abbr: 'CT'
};

export  const delaware: State = {
  id: 8,
  name: 'Delaware',
  abbr: 'DE'
};

export  const florida: State = {
  id: 9,
  name: 'Florida',
  abbr: 'FL'
};

export  const georgia: State = {
  id: 10,
  name: 'Georgia',
  abbr: 'GA'
};

export  const hawaii: State = {
  id: 11,
  name: 'Hawaii',
  abbr: 'HI'
};

export  const iowa: State = {
  id: 12,
  name: 'Iowa',
  abbr: 'IA'
};

export  const idaho: State = {
  id: 13,
  name: 'Idaho',
  abbr: 'ID'
};

export  const illinois: State = {
  id: 14,
  name: 'Illinois',
  abbr: 'IL'
};

export  const indiana: State = {
  id: 15,
  name: 'Indiana',
  abbr: 'IN'
};

export  const kansas: State = {
  id: 16,
  name: 'Kansas',
  abbr: 'KS'
};

export  const kentucky: State = {
  id: 17,
  name: 'Kentucky',
  abbr: 'KY'
};

export  const louisiana: State = {
  id: 18,
  name: 'Louisiana',
  abbr: 'LA'
};

export  const massachusetts: State = {
  id: 19,
  name: 'Massachusetts',
  abbr: 'MA'
};

export  const maryland: State = {
  id: 20,
  name: 'Maryland',
  abbr: 'MD'
};

export  const maine: State = {
  id: 21,
  name: 'Maine',
  abbr: 'ME'
};

export  const michigan: State = {
  id: 22,
  name: 'Michigan',
  abbr: 'MI'
};

export  const minnesota: State = {
  id: 23,
  name: 'Minnesota',
  abbr: 'MN'
};

export  const missouri: State = {
  id: 24,
  name: 'Missouri',
  abbr: 'MO'
};

export  const mississippi: State = {
  id: 25,
  name: 'Mississippi',
  abbr: 'MS'
};

export  const montana: State = {
  id: 26,
  name: 'Montana',
  abbr: 'MT'
};

export  const northCarolina: State = {
  id: 27,
  name: 'North Carolina',
  abbr: 'NC'
};

export  const northDakota: State = {
  id: 28,
  name: 'North Dakota',
  abbr: 'ND'
};

export  const nebraska: State = {
  id: 29,
  name: 'Nebraska',
  abbr: 'NE'
};

export  const newHampshire: State = {
  id: 30,
  name: 'New Hampshire',
  abbr: 'NH'
};

export  const newJersey: State = {
  id: 31,
  name: 'New Jersey',
  abbr: 'NJ'
};

export  const newMexico: State = {
  id: 32,
  name: 'New Mexico',
  abbr: 'NM'
};

export  const nevada: State = {
  id: 33,
  name: 'Nevada',
  abbr: 'NV'
};

export  const newYork: State = {
  id: 34,
  name: 'New York',
  abbr: 'NY'
};

export  const ohio: State = {
  id: 35,
  name: 'Ohio',
  abbr: 'OH'
};

export  const oklahoma: State = {
  id: 36,
  name: 'Oklahoma',
  abbr: 'OK'
};

export  const oregon: State = {
  id: 37,
  name: 'Oregon',
  abbr: 'OR'
};

export  const pennsylvania: State = {
  id: 38,
  name: 'Pennsylvania',
  abbr: 'PA'
};

export  const rhodeIsland: State = {
  id: 39,
  name: 'Rhode Island',
  abbr: 'RI'
};

export  const southCarolina: State = {
  id: 40,
  name: 'South Carolina',
  abbr: 'SC'
};

export  const southDakota: State = {
  id: 41,
  name: 'South Dakota',
  abbr: 'SD'
};

export  const tennessee: State = {
  id: 42,
  name: 'Tennessee',
  abbr: 'TN'
};

export  const texas: State = {
  id: 43,
  name: 'Texas',
  abbr: 'TX'
};

export  const utah: State = {
  id: 44,
  name: 'Utah',
  abbr: 'UT'
};

export  const virginia: State = {
  id: 45,
  name: 'Virginia',
  abbr: 'VA'
};

export  const vermont: State = {
  id: 46,
  name: 'Vermont',
  abbr: 'VT'
};

export  const washington: State = {
  id: 47,
  name: 'Washington',
  abbr: 'WA'
};

export  const wisconsin: State = {
  id: 48,
  name: 'Wisconsin',
  abbr: 'WI'
};

export  const westVirginia: State = {
  id: 49,
  name: 'West Virginia',
  abbr: 'WV'
};

export  const wyoming: State = {
  id: 50,
  name: 'Wyoming',
  abbr: 'WY'
};

export const us_states: State[] = [
  alaska,
  alabama,
  arkansas,
  arizona,
  california,
  colorado,
  connecticut,
  delaware,
  florida,
  georgia,
  hawaii,
  iowa,
  idaho,
  illinois,
  indiana,
  kansas,
  kentucky,
  louisiana,
  massachusetts,
  maryland,
  maine,
  michigan,
  minnesota,
  missouri,
  mississippi,
  montana,
  northCarolina,
  northDakota,
  nebraska,
  newHampshire,
  newJersey,
  newMexico,
  nevada,
  newYork,
  ohio,
  oklahoma,
  oregon,
  pennsylvania,
  rhodeIsland,
  southCarolina,
  southDakota,
  tennessee,
  texas,
  utah,
  virginia,
  vermont,
  washington,
  wisconsin,
  westVirginia,
  wyoming
];

