import { Component } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { PortalUser } from 'src/app/model/portalUser';
import { User } from '../../../model/user';
import { Subject, catchError, takeUntil } from 'rxjs';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrl: './admin-dashboard.component.css'
})

export class AdminDashboardComponent {

  constructor(private _auth: AuthService) { }

  private unsubscribe = new Subject();

  public user: PortalUser = {
    email: '',
    mineralRights: false,
    multiAccountManager: false,
    operator: false,
    selectedAccounts: []
  };
  registerUser(): void {
    this._auth.registerPortalUser(this.user)
    .pipe(
      takeUntil(this.unsubscribe),
      catchError((error) => {
        console.error(error);
        return error;
      })
    )
    .subscribe(
      (res: User) => {
        sessionStorage.setItem('userToken', res.token);
        sessionStorage.setItem('user', res.id.toString());
      }
    );
  }

  clearMultiAccount(): void {
    if (!this.user.multiAccountManager) {
      this.user.selectedAccounts = [];
    }
  }

  clearOperator(): void {
    if (!this.user.operator) {
      this.user.selectedOperator = '';
    }
  }
}
