import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Payment } from '../../model/payments';

@Component({
  selector: 'app-statement-footer-paid',
  templateUrl: './statement-footer-paid.component.html',
  styleUrl: './statement-footer-paid.component.css'
})
export class StatementFooterPaidComponent implements OnChanges {
  @Input() statement: Payment;
  public total: number = 0;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.statement) {
      this.totalAmount();
    }
  }

  totalAmount() {
    this.total = 0;
    if (this.statement != undefined && this.statement != null) {
      for (const payment of this.statement.royaltyPayments) {
        this.total += payment.mroNetValue;
      }
    }
  }

}
