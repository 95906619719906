import { MultiAccountEntity } from "../model/multiAccount";

export const multiAccountEntities1: MultiAccountEntity = {
  id: 1,
  name: 'Multi Account Entity 1',
  logo: '',
  address1: '123 Main St',
  address2: '',
  city: 'Anytown',
  state: undefined,
  zipCode: '12345',
  tel: '1234567890',
  fax: '1234567890',
  email: '',
  web: '',
  insertBy: 1,
  insertDate: '',
  modifyBy: 1,
  modifyDate: ''
};

export const multiAccountEntities2: MultiAccountEntity = {
  id: 2,
  name: 'Multi Account Entity 2',
  logo: '',
  address1: '123 Main St',
  address2: '',
  city: 'Anytown',
  state: undefined,
  zipCode: '12345',
  tel: '1234567890',
  fax: '1234567890',
  email: '',
  web: '',
  insertBy: 1,
  insertDate: '',
  modifyBy: 1,
  modifyDate: ''
};

export const multiAccountEntities3: MultiAccountEntity = {
  id: 3,
  name: 'Multi Account Entity 3',
  logo: '',
  address1: '123 Main St',
  address2: '',
  city: 'Anytown',
  state: undefined,
  zipCode: '12345',
  tel: '1234567890',
  fax: '1234567890',
  email: '',
  web: '',
  insertBy: 1,
  insertDate: '',
  modifyBy: 1,
  modifyDate: ''
};

export const multiAccountEntities4: MultiAccountEntity = {
  id: 4,
  name: 'Multi Account Entity 4',
  logo: '',
  address1: '123 Main St',
  address2: '',
  city: 'Anytown',
  state: undefined,
  zipCode: '12345',
  tel: '1234567890',
  fax: '1234567890',
  email: '',
  web: '',
  insertBy: 1,
  insertDate: '',
  modifyBy: 1,
  modifyDate: ''
};

export const multiAccountEntities5: MultiAccountEntity = {
  id: 5,
  name: 'Multi Account Entity 5',
  logo: '',
  address1: '123 Main St',
  address2: '',
  city: 'Anytown',
  state: undefined,
  zipCode: '12345',
  tel: '1234567890',
  fax: '1234567890',
  email: '',
  web: '',
  insertBy: 1,
  insertDate: '',
  modifyBy: 1,
  modifyDate: ''
};

export const multiAccountEntities6: MultiAccountEntity = {
  id: 6,
  name: 'Multi Account Entity 6',
  logo: '',
  address1: '123 Main St',
  address2: '',
  city: 'Anytown',
  state: undefined,
  zipCode: '12345',
  tel: '1234567890',
  fax: '1234567890',
  email: '',
  web: '',
  insertBy: 1,
  insertDate: '',
  modifyBy: 1,
  modifyDate: ''
};

export const multiAccountEntities7: MultiAccountEntity = {
  id: 7,
  name: 'Multi Account Entity 7',
  logo: '',
  address1: '123 Main St',
  address2: '',
  city: 'Anytown',
  state: undefined,
  zipCode: '12345',
  tel: '1234567890',
  fax: '1234567890',
  email: '',
  web: '',
  insertBy: 1,
  insertDate: '',
  modifyBy: 1,
  modifyDate: ''
};

export const multiAccountEntities8: MultiAccountEntity = {
  id: 8,
  name: 'Multi Account Entity 8',
  logo: '',
  address1: '123 Main St',
  address2: '',
  city: 'Anytown',
  state: undefined,
  zipCode: '12345',
  tel: '1234567890',
  fax: '1234567890',
  email: '',
  web: '',
  insertBy: 1,
  insertDate: '',
  modifyBy: 1,
  modifyDate: ''
};

export const multiAccountEntities9: MultiAccountEntity = {
  id: 9,
  name: 'Multi Account Entity 9',
  logo: '',
  address1: '123 Main St',
  address2: '',
  city: 'Anytown',
  state: undefined,
  zipCode: '12345',
  tel: '1234567890',
  fax: '1234567890',
  email: '',
  web: '',
  insertBy: 1,
  insertDate: '',
  modifyBy: 1,
  modifyDate: ''
};

export const multiAccountEntities10: MultiAccountEntity = {
  id: 10,
  name: 'Multi Account Entity 10',
  logo: '',
  address1: '123 Main St',
  address2: '',
  city: 'Anytown',
  state: undefined,
  zipCode: '12345',
  tel: '1234567890',
  fax: '1234567890',
  email: '',
  web: '',
  insertBy: 1,
  insertDate: '',
  modifyBy: 1,
  modifyDate: ''
};

export const multiAccountEntities: MultiAccountEntity[] = [
  multiAccountEntities1,
  multiAccountEntities2,
  multiAccountEntities3,
  multiAccountEntities4,
  multiAccountEntities5,
  multiAccountEntities6,
  multiAccountEntities7,
  multiAccountEntities8,
  multiAccountEntities9,
  multiAccountEntities10
];
