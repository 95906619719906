import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { RecentActivityService } from './recent-activity.service';
import { MroRecentActivity } from '../../../model/recent_activity';
import { recentActivities } from '../../../mockDB/recent_activities';

@Injectable({
  providedIn: 'root'
})
export class MockRecentActivityService extends RecentActivityService {

  constructor() {
    super();
  }

  getMroRecentActivity(): Observable<MroRecentActivity[]> {
    return of(recentActivities)
  }
}
