<div id="mro_header">
  <div class="mobile_container">
    <div id="mro_header_inner">
      <div id="mro_menu" *ngIf="loggedIn">
        <button
          type="button"
          class="btn_normal btn_icon_only btn_inverse_color"
          id="btn_view_menu"
          (click)="openMenu()"
        >
          <span class="btn_icon"><span class="icon_menu"></span></span>
          <span class="btn_text">Menu</span>
        </button>
      </div>
      <div id="mro_logo">
        <a routerLink="/dashboard" title="Go to Homepage" *ngIf="partnerData && partnerData.logo">
          <span class="header_logo_image">
            <img *ngIf="partnerData && partnerData.logo" [src]="partnerData.logo" alt="Partner Logo" />
          </span>
          <span class="hidden_text">{{ partnerData?.name }}</span>
        </a>
        <a routerLink="/dashboard" title="Go to Homepage">
          <span class="header_logo_image">
            <img src="../../../assets/images/pipelineDL_logo_rreverse675w.png" alt="Pipeline Logo" />
            <img src="../../../assets/images/OGTS_logo_small.png" alt="Partner Logo" />
          </span>
          <span class="hidden_text">OGTS</span>
        </a>
      </div>

      <div id="mro_profile" *ngIf="loggedIn">
        <button
          type="button"
          class="btn_normal btn_icon_only btn_inverse_color"
          (click)="navigateToProfile()"
        >
          <span class="btn_icon"><span class="icon_mro_profile"></span></span>
          <span class="btn_text">Profile</span>
        </button>
      </div>

    </div>
  </div>

  <div id="mro_menu_container" (click)="toggleNavbar()" [class.collapsed]="!menuOpen" data-target="#navbarNav">
    <div class="mobile_container">
      <div class="menu_header">
        <div class="mro_menu_title" (click)="toggleNavbar()"><h3>Menu</h3></div>
      </div>
      <div class="menu_content">
        <ul>
            <li *ngIf="!isAdminLoggedIn"><a routerLink="/dashboard" (click)="openMenu()" (click)="toggleNavbar()">Home</a></li>
            <li *ngIf="!isAdminLoggedIn"><a routerLink="/properties" (click)="openMenu()" (click)="toggleNavbar()">Properties</a></li>
            <li *ngIf="!isAdminLoggedIn"><a routerLink="/payments" (click)="openMenu()" (click)="toggleNavbar()">Payments</a></li>
            <li *ngIf="!isAdminLoggedIn"><a routerLink="/contactUs" (click)="openMenu()" (click)="toggleNavbar()">Contact Us</a></li>
            <li *ngIf="!isAdminLoggedIn"><a routerLink="/profile" (click)="openMenu()" (click)="toggleNavbar()">Profile</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>
