export interface MarketPrice {
  commodity: string;
  readingDateTime: Date;
}

export enum MarketLoadStatus {
  LOADING,
  FAILED,
  COMPLETE
}
