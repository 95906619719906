<div id="view_grid">
  <!-- Grid Details -->
  <div class="scroll_container">
    <table class="table_grid no_wrap">
      <thead class="header-row-min">
        <tr >
          <!-- <th class="col_chbox">

              <div class="table_header_label">
                <div class="form_row no_padding clearfix">
                  <input
                    type="checkbox"
                    id="chbox_select_all"
                    name="chbox_select_all"
                    value="all"
                    class="inputbox"
                    data-chbox-group="batch_table"
                  />
                  <label for="chbox_select_all" class="hidden_text"
                    >Select All</label
                  >
                </div>
              </div>
            </div>
          </th> -->
          <th *ngIf="columns[0]" class="table_header" [class.selected]="isSelected(0, 'asc') || isSelected(0, 'dsc')">
            <div class="table-header-inner">

            <div class="header-text">
              Issue Date
            </div>
            <div class="button-container">
              <button
                (click)="sortBy(0, 'asc')"
                class="btn btn-sm icon-button" [class.selected]="isSelected(0, 'asc')"
              >
              <mat-icon [ngStyle]="{color: isSelected(0, 'dsc') ? 'green' : 'black'}">keyboard_arrow_up</mat-icon>
              </button>
              <button (click)="sortBy(0, 'dsc')" class="btn btn-sm icon-button" [class.selected]="isSelected(0, 'dsc')">
                <mat-icon [ngStyle]="{color: isSelected(0, 'dsc') ? 'green' : 'black'}">keyboard_arrow_down</mat-icon>
              </button>
            </div>
            </div>
          </th>
          <th *ngIf="columns[1]" class="table_header" [class.selected]="isSelected(1, 'asc') || isSelected(1, 'dsc')">
            <div class="header-text">
              Well
            </div>

            <div class="button-container">
              <button (click)="sortBy(1, 'asc')" class="btn btn-sm icon-button" [class.selected]="isSelected(1, 'asc')">
                <mat-icon>keyboard_arrow_up</mat-icon>
              </button>
              <button (click)="sortBy(1, 'dsc')" class="btn btn-sm icon-button" [class.selected]="isSelected(1, 'dsc')">
                <mat-icon>keyboard_arrow_down</mat-icon>
              </button>
            </div>
          </th>
          <th *ngIf="columns[4]" class="table_header" [class.selected]="isSelected(4, 'asc') || isSelected(4, 'dsc')">
            <div class="header-text">
              Prod. Date
            </div>

            <div class="button-container">
              <button (click)="sortBy(4, 'asc')" class="btn btn-sm icon-button" [class.selected]="isSelected(4, 'asc')">
                <mat-icon>keyboard_arrow_up</mat-icon>
              </button>
              <button (click)="sortBy(4, 'dsc')" class="btn btn-sm icon-button" [class.selected]="isSelected(4, 'dsc')">
                <mat-icon>keyboard_arrow_down</mat-icon>
              </button>
            </div>
          </th>
          <th *ngIf="columns[5]" class="table_header" [class.selected]="isSelected(5, 'asc') || isSelected(5, 'dsc')">
            <div class="header-text">
              Product
            </div>
            <div class="button-container">
              <button (click)="sortBy(5, 'asc')" class="btn btn-sm icon-button" [class.selected]="isSelected(5, 'asc')">
                <mat-icon>keyboard_arrow_up</mat-icon>
              </button>
              <button (click)="sortBy(5, 'dsc')" class="btn btn-sm icon-button" [class.selected]="isSelected(5, 'dsc')">
                <mat-icon>keyboard_arrow_down</mat-icon>
              </button>
            </div>
          </th>
          <th *ngIf="columns[6]" class="table_header" [class.selected]="isSelected(6, 'asc') || isSelected(6, 'dsc')">
            <div class="header-text">
              Price
            </div>
            <div class="button-container">
              <button (click)="sortBy(6, 'asc')" class="btn btn-sm icon-button" [class.selected]="isSelected(6, 'asc')">
                <mat-icon>keyboard_arrow_up</mat-icon>
              </button>
              <button (click)="sortBy(6, 'dsc')" class="btn btn-sm icon-button" [class.selected]="isSelected(6, 'dsc')">
                <mat-icon>keyboard_arrow_down</mat-icon>
              </button>
            </div>
          </th>
          <th *ngIf="columns[7]" class="table_header" [class.selected]="isSelected(7, 'asc') || isSelected(7, 'dsc')">
            <div class="header-text">
              Sales Volume
            </div>
            <div class="button-container">
              <button (click)="sortBy(7, 'asc')" class="btn btn-sm icon-button" [class.selected]="isSelected(7, 'asc')">
                <mat-icon>keyboard_arrow_up</mat-icon>
              </button>
              <button (click)="sortBy(7, 'dsc')" class="btn btn-sm icon-button" [class.selected]="isSelected(7, 'dsc')">
                <mat-icon>keyboard_arrow_down</mat-icon>
              </button>
            </div>
          </th>
          <th *ngIf="columns[8]" class="table_header" [class.selected]="isSelected(8, 'asc') || isSelected(8, 'dsc')">
            <div class="header-text">
              Sales Gross Value
            </div>
            <div class="button-container">
              <button (click)="sortBy(8, 'asc')" class="btn btn-sm icon-button" [class.selected]="isSelected(8, 'asc')">
                <mat-icon>keyboard_arrow_up</mat-icon>
              </button>
              <button (click)="sortBy(8, 'dsc')" class="btn btn-sm icon-button" [class.selected]="isSelected(8, 'dsc')">
                <mat-icon>keyboard_arrow_down</mat-icon>
              </button>
            </div>
          </th>
          <ng-container
            *ngFor="let header of salesDeductionHeaders; let i = index"
          >
            <th *ngIf="salesDeductionColumns[i]" class="table_header">
              <div class="header-text">
                {{ header }}
              </div>
              <div class="button-container">
                <button
                  (click)="sortByDeduction(header, 'sales', 'asc')"
                  class="btn btn-sm icon-button"
                >
                  <mat-icon>keyboard_arrow_up</mat-icon>
                </button>
                <button
                  (click)="sortByDeduction(header, 'sales', 'dsc')"
                  class="btn btn-sm icon-button"
                >
                  <mat-icon>keyboard_arrow_down</mat-icon>
                </button>
              </div>
            </th>
          </ng-container>
          <th *ngIf="columns[10]" class="table_header" [class.selected]="isSelected(10, 'asc') || isSelected(10, 'dsc')">
            <div class="header-text">
              Sales Net Value
            </div>
            <div class="button-container">
              <button (click)="sortBy(10, 'asc')" class="btn btn-sm icon-button" [class.selected]="isSelected(10, 'asc')">
                <mat-icon>keyboard_arrow_up</mat-icon>
              </button>
              <button (click)="sortBy(10, 'dsc')" class="btn btn-sm icon-button" [class.selected]="isSelected(10, 'dsc')">
                <mat-icon>keyboard_arrow_down</mat-icon>
              </button>
            </div>
          </th>
          <th *ngIf="columns[11]" class="table_header" [class.selected]="isSelected(11, 'asc') || isSelected(11, 'dsc')">
            <div class="header-text">
              Interest Type
            </div>
            <div class="button-container">
              <button (click)="sortBy(11, 'asc')" class="btn btn-sm icon-button" [class.selected]="isSelected(11, 'asc')">
                <mat-icon>keyboard_arrow_up</mat-icon>
              </button>
              <button (click)="sortBy(11, 'dsc')" class="btn btn-sm icon-button" [class.selected]="isSelected(11, 'dsc')">
                <mat-icon>keyboard_arrow_down</mat-icon>
              </button>
            </div>
          </th>
          <th *ngIf="columns[12]" class="table_header" [class.selected]="isSelected(12, 'asc') || isSelected(12, 'dsc')">
            <div class="header-text">
              Decimal
            </div>
            <div class="button-container">
              <button (click)="sortBy(12, 'asc')" class="btn btn-sm icon-button" [class.selected]="isSelected(12, 'asc')">
                <mat-icon>keyboard_arrow_up</mat-icon>
              </button>
              <button (click)="sortBy(12, 'dsc')" class="btn btn-sm icon-button" [class.selected]="isSelected(12, 'dsc')">
                <mat-icon>keyboard_arrow_down</mat-icon>
              </button>
            </div>
          </th>
          <th *ngIf="columns[13]" class="table_header" [class.selected]="isSelected(13, 'asc') || isSelected(13, 'dsc')">
            <div class="header-text">
              Owner Volume
            </div>
            <div class="button-container">
              <button (click)="sortBy(13, 'asc')" class="btn btn-sm icon-button" [class.selected]="isSelected(13, 'asc')">
                <mat-icon>keyboard_arrow_up</mat-icon>
              </button>
              <button (click)="sortBy(13, 'dsc')" class="btn btn-sm icon-button" [class.selected]="isSelected(13, 'dsc')">
                <mat-icon>keyboard_arrow_down</mat-icon>
              </button>
            </div>
          </th>
          <th *ngIf="columns[14]" class="table_header" [class.selected]="isSelected(14, 'asc') || isSelected(14, 'dsc')">
            <div class="header-text">
              Owner Gross Value
            </div>
            <div class="button-container">
              <button (click)="sortBy(14, 'asc')" class="btn btn-sm icon-button" [class.selected]="isSelected(14, 'asc')">
                <mat-icon>keyboard_arrow_up</mat-icon>
              </button>
              <button (click)="sortBy(14, 'dsc')" class="btn btn-sm icon-button" [class.selected]="isSelected(14, 'dsc')">
                <mat-icon>keyboard_arrow_down</mat-icon>
              </button>
            </div>
          </th>
          <ng-container *ngFor="let header of deductionHeaders; let i = index">
            <th *ngIf="deductionColumns[i]" class="table_header">
              <div class="header-text">
                {{ header }}
              </div>
              <div class="button-container">
                <button
                  (click)="sortByDeduction(header, 'owner', 'asc')"
                  class="btn btn-sm icon-button"
                >
                  <mat-icon>keyboard_arrow_up</mat-icon>
                </button>
                <button
                  (click)="sortByDeduction(header, 'owner', 'dsc')"
                  class="btn btn-sm icon-button"
                >
                  <mat-icon>keyboard_arrow_down</mat-icon>
                </button>
              </div>
            </th>
          </ng-container>

          <th class="table_header" [class.selected]="isSelected(16, 'asc') || isSelected(16, 'dsc')">
            <div class="header-text">
              Subtotal
            </div>
            <div class="button-container">
              <button (click)="sortBy(16, 'asc')" class="btn btn-sm icon-button" [class.selected]="isSelected(16, 'asc')">
                <mat-icon>keyboard_arrow_up</mat-icon>
              </button>
              <button (click)="sortBy(16, 'dsc')" class="btn btn-sm icon-button" [class.selected]="isSelected(16, 'dsc')">
                <mat-icon>keyboard_arrow_down</mat-icon>
              </button>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container>
          <tr *ngFor="let payment of statement.royaltyPayments">
            <!-- <td class="col_chbox">
                <div class="form_row no_padding clearfix">
                  <input
                    type="checkbox"
                    id="chbox_select_row_1"
                    name="chbox_select_row_1"
                    value="row_1"
                    class="inputbox table_chbox"
                    data-chbox-group="batch_table"
                  />
                  <label for="chbox_select_row_1" class="hidden_text"
                  >Select Well #5462587123</label
                  >
                </div>
              </td> -->
            <td *ngIf="columns[0]" [class.selected]="isSelected(0, 'asc') || isSelected(0, 'dsc')">{{ columns[0] && payment.paymentDate }}</td>
            <td *ngIf="columns[1]" [class.selected]="isSelected(1, 'asc') || isSelected(1, 'dsc')">
              {{ payment.well.name }}
            </td>
            <td *ngIf="columns[4]" [class.selected]="isSelected(4, 'asc') || isSelected(4, 'dsc')">
              <span>
                {{
                  convertToDate(payment.sale.prodDate) | date : "MMM yyyy"
                }}
              </span>
            </td>
            <td *ngIf="columns[5]" [class.selected]="isSelected(5, 'asc') || isSelected(5, 'dsc')">{{ payment.sale.product.name }}</td>
            <td class="right-align" *ngIf="columns[6]" [class.selected]="isSelected(6, 'asc') || isSelected(6, 'dsc')">
              {{
                payment.sale.unitPrice !== null
                  ? "$" + (payment.sale.unitPrice | number : "1.2-2" : "en-US")
                  : " "
              }}
            </td>
            <td class="right-align" *ngIf="columns[7]" [class.selected]="isSelected(7, 'asc') || isSelected(7, 'dsc')">
              {{ payment.sale.saleVolume | number : "1.2-2" : "en-US" }}
            </td>
            <td class="right-align" *ngIf="columns[8]" [class.selected]="isSelected(8, 'asc') || isSelected(8, 'dsc')">
              {{
                payment.sale.grossValue !== null
                  ? "$" + (payment.sale.grossValue | number : "1.2-2" : "en-US")
                  : " "
              }}
            </td>
            <ng-container
              *ngFor="
                let deduction of payment.sale.deductionObjects;
                let i = index
              "
            >
              <td class="right-align" *ngIf="salesDeductionColumns[i] !== false">
                <div>
                  {{
                    deduction.amount !== null
                      ? "$" + (deduction.amount | number : "1.2-2" : "en-US")
                      : " "
                  }}
                </div>
              </td>
            </ng-container>
            <td class="right-align" *ngIf="columns[10]" [class.selected]="isSelected(10, 'asc') || isSelected(10, 'dsc')">
              {{
                payment.sale.netValue !== null
                  ? "$" + (payment.sale.netValue | number : "1.2-2" : "en-US")
                  : " "
              }}
            </td>
            <td *ngIf="columns[11]" [class.selected]="isSelected(11, 'asc') || isSelected(11, 'dsc')">
              {{ payment.divisionOrder.interestType.name }}
            </td>
            <td class="right-align" *ngIf="columns[12]" [class.selected]="isSelected(12, 'asc') || isSelected(12, 'dsc')">
              {{ payment.divisionOrder.decimalInterest | number : ".6" }}
            </td>
            <td class="right-align" *ngIf="columns[13]" [class.selected]="isSelected(13, 'asc') || isSelected(13, 'dsc')">
              {{ payment.mroVolume | number : "1.2-6" : "en-US" }}
            </td>
            <td class="right-align" *ngIf="columns[14]" [class.selected]="isSelected(14, 'asc') || isSelected(14, 'dsc')">
              {{
                payment.sale !== null
                  ? "$" + (payment.mroGrossValue | number : "1.2-6" : "en-US")
                  : " "
              }}
            </td>
            <ng-container
              *ngFor="let deduction of payment.deductionObjects; let i = index"
            >
              <td class="right-align" *ngIf="deductionColumns[i]">
                <div>
                  {{
                    deduction.amount !== null
                      ? "$" + (deduction.amount | number : "1.2-2" : "en-US")
                      : " "
                  }}
                </div>
              </td>
            </ng-container>
            <td class="right-align" [class.selected]="isSelected(1, 'asc') || isSelected(8, 'dsc')">
              {{
                payment.sale !== null
                  ? "$" + (payment.mroNetValue | number : "1.2-2" : "en-US")
                  : "a "
              }}
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
