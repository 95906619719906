import { Observable } from 'rxjs';
import { Property } from './../../../model/property';
import { Product } from '../../../model/product'
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PropertyService } from '../../../core/services/property/property.service';
import { products } from '../../../mockDB/products';
import { ProductService } from '../../../core/services/products/product.service';
import { PaymentSummary } from '../../../model/payment-summary';
import { PaymentsService } from '../../../core/services/payments/payments.service';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { StorageService } from '../../../core/services/storage/storage.service';
import { GraphChartComponent } from './graph-chart/graph-chart.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { DataCardComponent } from './data-card/data-card.component';
import { VerticalBarChartComponent } from './vertical-bar-chart/vertical-bar-chart.component';
import { PaymentCount } from './../../../model/payment-summary';
@Component({
  selector: 'app-dashboard-summary',
  standalone: true,
  imports: [
    CommonModule,
    PieChartComponent,
    GraphChartComponent,
    BarChartComponent,
    DataCardComponent,
    VerticalBarChartComponent
  ],
  templateUrl: './dashboard-summary.component.html',
  styleUrls: ['./dashboard-summary.component.css']

})
export class DashboardSummaryComponent implements OnInit {
  public properties: Property[] = [];
  public products: Product[] = [];
  public checkDates: string[];
  public paymentSummaryData: PaymentSummary[];
  public propertyNames: string[] = [];
  public fromDate: string;
  public toDate: string = new Date().toISOString().split('T')[0];
  public currentPage: number = 0;
  public propertyId: number = -1;
  public productId: number = -1;
  public isDateRange: boolean = false;
  public breakdown: string = "M";

  public totalPages: number = 3;

  goToPage(pageIndex: number): void {
    this.currentPage = pageIndex;
  }

  constructor(
    private propertyService: PropertyService,
    private productService: ProductService,
    private paymentsService: PaymentsService,
    private storageService: StorageService
  ) { }

  ngOnInit(): void {
    this.setInitialFromDate();
    this.products = products;
    this.initializeData();
    this.getProperties();
    this.getProducts();
  }

  get today(): string {
    return new Date().toISOString().split('T')[0];
  }

  public goToPreviousPage(): void {
    if (this.currentPage > 0) {
      this.currentPage--;
    }
  }

  public goToNextPage(): void {
    if (this.currentPage < this.totalPages - 1) {
      this.currentPage++;
    }
  }

  private setInitialFromDate(): void {
    const date = new Date();
    date.setMonth(date.getMonth() - 18);
    this.fromDate = date.toISOString().split('T')[0];
  }

  private initializeData(): void {
    this.getMroPaymentSummaryByPeriod();
    this.getMroPaymentSummaryByPeriodByProduct();
    this.getMroPaymentSummaryByPeriodByProperty();
    this.getMroPaymentSummaryByProduct();
    this.getMroPaymentSummaryByProperty();
  }

  private getMroPaymentSummaryByPeriod(): void {
    this.paymentsService.getMroPaymentSummaryByPeriod(this.fromDate, this.toDate, this.propertyId, this.productId, this.breakdown)
      .subscribe(data => {
        this.storageService.setMroPaymentSummaryByPeriod(data);
      });
  }

  private getMroPaymentSummaryByProperty(): void {
    this.paymentsService.getMroPaymentSummaryByProperty(this.fromDate, this.toDate, this.propertyId, this.productId)
      .subscribe(data => {
        this.storageService.setMroPaymentSummaryByProperty(data);
      });
  }

  private getMroPaymentSummaryByProduct(): void {
    this.paymentsService.getMroPaymentSummaryByProduct(this.fromDate, this.toDate, this.propertyId, this.productId)
      .subscribe(data => {
        this.storageService.setMroPaymentSummaryByProduct(data);
      });
  }

  private getMroPaymentSummaryByPeriodByProperty(): void {
    this.paymentsService.getMroPaymentSummaryByPeriodByProperty(this.fromDate, this.toDate, this.propertyId, this.productId, this.breakdown)
      .subscribe(data => {
        this.storageService.setMroPaymentSummaryByPeriodByProperty(data);
      });
  }

  private getMroPaymentSummaryByPeriodByProduct(): void {
    this.paymentsService.getMroPaymentSummaryByPeriodByProduct(this.fromDate, this.toDate, this.propertyId, this.productId, this.breakdown)
      .subscribe(data => {
        this.storageService.setMroPaymentSummaryByPeriodByProduct(data);
      });
  }

  private getProducts(): void {
    this.productService.getProducts().subscribe(products => {
      this.products = products;

      this.paymentsService.getMroPendingPaymentCount().subscribe(pendingCount => {
        if (pendingCount.paymentCount > 0) {
          const product: Product = { id: 99, name: 'Unknown', operatorId: 0, primaryId: 99 };
          this.products.push(product);
        }
      });
    });
  }

  private getProperties(): void {
    this.properties = this.propertyService.getProperties();

    this.paymentsService.getMroPendingPaymentCount().subscribe(pendingCount => {
      if (pendingCount.paymentCount > 0) {
        const property: Property = { id: 0, name: 'Unknown', description: '', propertyNo: '', effectiveFrom: new Date(), wells: [] };
        this.properties.push(property);
      }
    });
  }

  updateBreakdown(breakdown: string): void {
    this.breakdown = breakdown;
    this.initializeData();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFromDateChange(event: any): void {
    this.fromDate = event.target.value;
    this.isDateRange = true;
    this.initializeData();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onToDateChange(event: any): void {
    this.toDate = event.target.value;
    this.initializeData();
  }

  onPropertyNameChange(propertyId: number): void {
    this.propertyId = propertyId;
    this.initializeData();
  }

  onProductTypeChange(productId: number): void {
    this.productId = productId;
    this.initializeData();
  }

  }

