import { Injectable } from '@angular/core';
import { LoaderModule } from '../../loader.module';

@Injectable({
  providedIn: LoaderModule
})
export class LoaderService {
  public isLoading = false;

  private loaderCounter = 0;

  openLoader(): void {
    this.loaderCounter++;
    this.setIsLoading();
  }

  closeLoader(): void {
    if (this.loaderCounter > 0) {
      this.loaderCounter--;
    }
    this.setIsLoading();
  }

  private setIsLoading(): void {
    this.isLoading = this.loaderCounter > 0;
  }
}
