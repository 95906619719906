import { Component, Inject, OnInit } from '@angular/core';
import { Payment } from '../../model/payments';
import { PaymentsService } from '../../core/services/payments/payments.service';
import { Operator } from '../../model/operator';
import { LookupService } from '../../core/services/lookup/lookup.service';
import { PaymentSearch } from '../../model/payment_search';
import { DatePipe } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-all-payments',
  templateUrl: './all-payments.component.html',
  styleUrls: ['./all-payments.component.css'],
  providers: [DatePipe]
})
export class AllPaymentsComponent implements OnInit {

  public paymentForm: UntypedFormGroup = this.fb.group({
    operatorId: '',
    month: this.datePipe.transform(new Date(), 'yyyyMM')
  });

  public paymentSearch: PaymentSearch = {
    operatorId: 0,
    month: parseInt(this.datePipe.transform(new Date(), 'yyyyMM')),
    date: new Date()
  };

  public operators: Operator[];
  public allPayments: Payment[];
  public payments: Payment[];
  public monthStr: string;
  public paymentDate: Date;
  public lastPaymentMonth: number;
  public maxMonth: number;
  public minMonth: number;
  public loading: boolean;

  private unsubscribe = new Subject<void>();

  constructor(
    @Inject(UntypedFormBuilder)
    private fb: UntypedFormBuilder,
    private paymentService: PaymentsService,
    private lookupService: LookupService,
    private datePipe: DatePipe
  ) {
    this.paymentSearch.month = this.paymentForm.get('month').value;
    this.paymentSearch.operatorId = 0;
    this.paymentService.payments$.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.getPayments();
      this.findMinMaxMonths();
    });
  }

  ngOnInit() {
    this.getOperators();
    this.getPayments();
    this.findMinMaxMonths();
    this.getPayments();
  }

  findMinMaxMonths() {
    const fullPaymentList = this.paymentService.getPayments();
    if (!fullPaymentList || fullPaymentList.length === 0) return;

    const months = fullPaymentList.map(statement => this.getYearMonthFromDate(statement.paymentDate));
    const dates = fullPaymentList.map(statement => new Date(statement.paymentDate).getTime());

    this.setMinMaxMonths(months);
    this.setMinMaxDates(dates);
  }

  private getYearMonthFromDate(date: Date): number {
    return +this.datePipe.transform(new Date(date), 'yyyyMM');
  }

  private setMinMaxMonths(months: number[]): void {
    this.minMonth = Math.min(...months);
    this.maxMonth = Math.max(...months);
    this.adjustPaymentSearchMonth();
  }

  private setMinMaxDates(dates: number[]): void {
    const minDate = Math.min(...dates);
    const maxDate = Math.max(...dates);
    this.adjustPaymentSearchDate(minDate, maxDate);
  }

  private adjustPaymentSearchMonth(): void {
    if (this.paymentSearch.month > this.maxMonth || this.paymentSearch.month < this.minMonth) {
      this.paymentSearch.month = this.paymentSearch.month > this.maxMonth ? this.maxMonth : this.minMonth;
      this.filterPayments();
    }
  }

  private adjustPaymentSearchDate(minDate: number, maxDate: number): void {
    if (this.paymentSearch.month > this.maxMonth || this.paymentSearch.month < this.minMonth) {
      this.paymentSearch.date = this.paymentSearch.month > this.maxMonth ? new Date(maxDate) : new Date(minDate);
      this.monthStr = this.datePipe.transform(this.paymentSearch.date, 'MMM yyyy');
    }
  }

  get isMaxMonth(): boolean {
    return this.paymentSearch.month === this.maxMonth;
  }

  get isMinMonth(): boolean {
    return this.paymentSearch.month === this.minMonth;
  }

  getOperators() {
    this.lookupService.getOperators()
      .pipe(
        takeUntil(this.unsubscribe)
      ).subscribe(operators => {
        this.operators = operators as Operator[];
      });
  }

  getPayments() {
    const allPayments = this.paymentService.getPayments();
    const paymentsInTargetMonth = allPayments.filter(payment => {
      const paymentMonth = Number(this.datePipe.transform(payment.paymentDate, 'yyyyMM'));
      return paymentMonth === this.paymentSearch.month;
    });
    if (paymentsInTargetMonth && paymentsInTargetMonth.length > 0) {
      this.allPayments = paymentsInTargetMonth;
      this.payments = this.allPayments;
      this.paymentDate = new Date(this.payments[0].paymentDate);
      this.paymentSearch.date = this.paymentDate;
      this.monthStr = this.datePipe.transform(this.paymentSearch.date, 'MMM yyyy');
      this.paymentSearch.month = Number(this.datePipe.transform(this.paymentSearch.date, 'yyyyMM'));
      if (this.lastPaymentMonth === undefined) {
        this.lastPaymentMonth = this.paymentSearch.month;
      }
    } else {
      this.allPayments = [];
      this.monthStr = this.datePipe.transform(this.paymentSearch.date, 'MMM yyyy');
    }
  }

  get recordsAvailable(): boolean {
    if (!this.allPayments && this.allPayments.length <= 0) {
      this.getPayments();
    }
    return this.allPayments && this.allPayments.length > 0;
  }

  public onOperatorChange() {
    this.paymentSearch.operatorId = this.paymentForm.get('operatorId').value === '' ? 0 : this.paymentForm.get('operatorId').value;
    this.filterPayments();
  }

  public onDownMonth() {
    this.paymentSearch.date.setMonth((this.paymentSearch.date.getMonth() - 1));
    this.monthStr = this.datePipe.transform(this.paymentSearch.date, 'MMM yyyy');
    this.paymentSearch.month = Number(this.datePipe.transform(this.paymentSearch.date, 'yyyyMM'));

    this.getPayments();
    this.filterPayments();
  }

  public onUpMonth() {
    this.paymentSearch.date.setMonth((this.paymentSearch.date.getMonth() + 1));
    this.monthStr = this.datePipe.transform(this.paymentSearch.date, 'MMM yyyy');
    this.paymentSearch.month = Number(this.datePipe.transform(this.paymentSearch.date, 'yyyyMM'));

    this.getPayments();
    this.filterPayments();
  }

  private filterPayments() {
    if (this.paymentSearch.operatorId !== 0) {
      this.payments = this.allPayments.filter(p => p.operator.id == this.paymentSearch.operatorId);
    } else {
      this.payments = this.allPayments;
    }
  }

}
