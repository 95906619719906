import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { State } from '../../../model/state';
import { County } from '../../../model/county';
import { Operator } from '../../../model/operator';
import { Purchaser } from '../../../model/purchaser';
import { Product } from '../../../model/product';
import { Well } from '../../../model/well';
import { Bank } from '../../../model/bank';

@Injectable({
    providedIn: 'root'
  })
  export abstract class LookupService {

  constructor(private http: HttpClient) { }

  abstract getStates(): Observable<State[]>;

  abstract getAllStatesCounties(): Observable<State[]>;

  abstract getCounties(stateName: string): Observable<County[]>;

  abstract getOperators(): Observable<Operator[]>;

  abstract getOperator(id: number): Observable<Operator>;

  abstract getMroOperators(): Observable<Operator[]>;

  abstract getPurchasers(): Observable<Purchaser[]>;

  abstract getProducts(): Observable<Product[]>;

  abstract getWells(): Observable<Well[]>;

  abstract getBanks(): Observable<Bank[]>;

  abstract getInterestTypes(): Observable<string[]>;

  // getDivisionOrderInterestTypes(): Observable<DivisionOrderInterestType[]> {
  //   const url = `${this.apiUrl}/divisionOrderInterestTypes`;
  //   return this.http.get<DivisionOrderInterestType[]>(url).pipe(
  //     catchError(this.handleError)
  //   );
  // }

  private handleError(error: HttpErrorResponse) {
    console.error('An error occurred:', error);
    return throwError('Something went wrong. Please try again later.');
  }
}
