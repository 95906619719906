<div id="mro_content">
  <div class="mobile_container">
    <div id="content_inner">
      <!-- Content Header -->
      <div class="content_header">
        <div class="content_history">
          <!-- <button type="button" class="btn_normal btn_big btn_icon_only" id="btn_history_back" onclick="window.location='dashboard.html'">
            <span class="btn_icon"><span class="icon_back"></span></span>
            <span class="btn_text">Back</span>
          </button> -->
        </div>
        <div class="content_title">
          <h1>Properties</h1>
        </div>
        <div class="content_timestamp">
          <div class="timestamp">Last updated: {{ lastModified | date: 'dd MMM yyyy h:mm a' }}</div>
        </div>
      </div>

      <!-- Action Bar -->
      <div class="content_action_bar">
        <div class="action_bar_left">
          <ul>
            <li>
              <!-- <fieldset class="form_row no_padding clearfix">
                <legend class="hidden_legend">Filter Rows</legend>
                <div class="toggle_group">
                  <div class="toggle_group_option">
                    <div class="toggle_input">
                      <input
                        type="radio"
                        value="all"
                        id="property_all"
                        name="filter_option"
                        class="filter_option"
                        (click)="btnAllProperties()"
                      />
                    </div>
                    <label
                      for="property_all"
                      [ngClass]="{
                      toggle_active: propertySearch.propertyType === 0
                    }"
                    >All</label
                    >
                  </div>
                  <div class="toggle_group_option">
                    <div class="toggle_input">
                      <input
                        type="radio"
                        value="in_progress"
                        id="property_in_progress"
                        name="filter_option"
                        class="filter_option"
                        (click)="btnInProgressProperties()"
                      />
                    </div>
                    <label
                      for="property_in_progress"
                      [ngClass]="{
                      toggle_active: propertySearch.propertyType === 1
                    }"
                    >In Progress</label
                    >
                  </div>
                  <div class="toggle_group_option">
                    <div class="toggle_input">
                      <input
                        type="radio"
                        value="active"
                        id="property_active"
                        name="filter_option"
                        class="filter_option"
                        (click)="btnActiveProperties()"
                      />
                    </div>
                    <label
                      for="property_active"
                      [ngClass]="{
                      toggle_active: propertySearch.propertyType === 2
                    }"
                    >Active</label
                    >
                  </div>
                  <div class="toggle_group_option">
                    <div class="toggle_input">
                      <input
                        type="radio"
                        value="inactive"
                        id="property_inactive"
                        name="filter_option"
                        class="filter_option"
                        (click)="btnInactiveProperties()"
                      />
                    </div>
                    <label
                      for="property_inactive"
                      [ngClass]="{
                      toggle_active: propertySearch.propertyType === 3
                    }"
                    >Inactive</label
                    >
                  </div>
                </div>
              </fieldset> -->
            </li>
          </ul>
        </div>
        <form [formGroup]="propertyForm">
          <div class="action_bar_right">
            <ul>
              <li>
                <div class="form_row no_padding">
                  <div class="form_label hidden_label">
                    <label for="filter_search_table">Search Table</label>
                  </div>
                  <div class="form_input">
                    <input
                      #filter_search_table
                      class="inputbox searchbox"
                      id="filter_search_table"
                      name="filter_search_table"
                      placeholder="Search Property No. or Name…"
                      (keyup)="doSearch(filter_search_table.value)"
                      formControlName="searchStr"
                    />
                  </div>
                </div>
              </li>
              <li>
                <div class="form_row no_padding">
                  <div class="form_label hidden_label">
                    <label for="filter_property_states">Property State</label>
                  </div>
                  <div class="form_input">
                    <select
                      matTooltip="Property State"
                      name="state"
                      id="state"
                      class="inputbox"
                      formControlName="state"
                      (change)="onStateChange()"
                      title="Property State"
                    >
                      <option value="" [selected]="">All States</option>
                      <option *ngFor="let state of states" value="{{ state.id }}">
                        {{ state.name }}
                        <p *ngIf="state.abbr">({{ state.abbr }})</p>
                      </option>
                    </select>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </form>
      </div>

      <!-- Body Content -->
      <div class="table-container">
        <table class="properties-table">
          <thead>
            <tr>
              <th class="property-name">Name</th>
              <!-- <th>Property #</th> -->
              <th class="property-description">Description</th>
              <th class="property-state">State</th>
              <th class="property-county">County</th>
              <th class="property-operator">Operator</th>
              <!-- <th>Purchaser</th> -->
            </tr>
          </thead>
          <tbody>
            <tr class="pointer" *ngFor="let property of properties" (click)="navigateToPropertyDetail(property.id)">
              <td matTooltip="{{ property.name }}" matTooltipPosition="above">{{ property.name || '' }}</td>
              <!-- <td matTooltip="{{ property.propertyNo }}" matTooltipPosition="above">{{ property.propertyNo || '' }}</td> -->
              <td matTooltip="{{ property.description }}" matTooltipPosition="above">{{ property.description || '' }}</td>
              <td matTooltip="{{ property.geographicalData?.county?.state?.name }}" matTooltipPosition="above">{{ property.geographicalData?.county?.state?.abbr || '' }}</td>
              <td matTooltip="{{ property.geographicalData?.county?.name }}" matTooltipPosition="above">{{ property.geographicalData?.county?.name || '' }}</td>
              <td matTooltipPosition="above">
                  <div *ngFor="let operator of property.wells | uniqueOperators; let i = index">
                    {{ i > 0 ? ',' : ' ' }}{{ operator.operatorName }}
                  </div>
              </td>
                             <!-- <td matTooltip="{{ property.purchaserName }}">{{ property.purchaserName }}</td> -->
            </tr>
          </tbody>
        </table>
      </div>


      <!-- Body Footer -->
      <div class="content_footer">
        <div class="content_footer_inner">
          <div class="content_footer_left">
            <ul>
              <li>
                <button
                  type="button"
                  class="btn_normal btn_white"
                  id="btn_back"
                  (click)="onBack()"
                >
                  <span class="btn_icon"><span class="icon_back"></span></span>
                  &nbsp;<span class="btn_text">Back</span>
                </button>
              </li>
            </ul>
          </div>
          <div class="content_footer_right">
            <ul>
              <!-- <li>
                <button
                  type="button"
                  class="btn_normal btn_blue"
                  id="btn_export_pdf_properties"
                >
                  <span class="btn_icon"><span class="icon_share"></span></span>
                  &nbsp;<span class="btn_text">PDF</span>
                </button>
              </li> -->
              <li>
                <button
                type="button"
                class="btn_normal btn_blue"
                id="btn_export_csv_properties"
                (click)="exportToCSV()"
              >
              <span class="btn_icon"> <img src="../assets/images/download_icon.svg" alt="Download Icon"> </span>
                &nbsp;<span class="btn_text">CSV</span>
              </button>

              </li>
              <!-- <li>
                <button
                  type="button"
                  class="btn_normal btn_blue"
                  id="btn_export_excel_properties"
                >
                  <span class="btn_icon"><span class="icon_share"></span></span>
                  &nbsp;<span class="btn_text">Excel</span>
                </button>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
