import { Property } from './../../model/property';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PropertyDetailWidgetComponent } from './property-detail-widget/property-detail-widget.component';
import { ActivatedRoute } from '@angular/router';
import { PropertyService } from '../../core/services/property/property.service';
import { WellWidgetComponent } from './well-widget/well-widget.component';
import { PerformanceWidgetComponent } from './performance-widget/performance-widget.component';
import { Location } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-property-detail',
  standalone: true,
  imports: [
    CommonModule,
    PropertyDetailWidgetComponent,
    WellWidgetComponent,
    PerformanceWidgetComponent,
    MatIconModule
  ],
  templateUrl: './property-detail.component.html',
  styleUrl: './property-detail.component.css'
})
export class PropertyDetailComponent implements OnInit {
  @ViewChild('spreadContainer', { static: false }) spreadContainer: ElementRef;
  public lastUpdated: Date = new Date();
  public property: Property;

  private propertyId: number;
  private properties: Property[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private propertyService: PropertyService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.propertyId = +this.activatedRoute.snapshot.params['id'];
    this.getProperties();
    this.property = this.getProperty();
  }

  getProperties(): void {
    this.properties = this.propertyService.getProperties();
  }

  getProperty(): Property {
    return this.properties.find(property => property.id === this.propertyId);
  }

  public onBack() {
    this.location.back();
  }

}
