<div id="mro_content">
  <div class="mobile_container" [ngClass]="{'mobile_container_grid': isGridView}">
    <div id="mro_recent_payments_inner">
      <h1 class="mro_title">Statement</h1>

      <div class="mro_content_inner">

        <div class="space_divider"></div>
        <div class="align_center">
          <button
            *ngIf="showStatementView"
            type="button"
            class="btn_normal btn_purple_link btn_no_border btn_view_switcher"
            id="view_grid_link"
            (click)="showGrid()"
          >
            <span class="btn_icon"><span class="icon_table"></span></span>
            <span class="btn_text">Grid View</span>
          </button>
          <button
            *ngIf="showGridView"
            type="button"
            class="btn_normal btn_purple_link btn_no_border btn_view_switcher"
            id="view_statement_link"
            (click)="showStatement()"
          >
            <span class="btn_icon"><span class="icon_statement"></span></span>
            <span class="btn_text">Statement View</span>
          </button>
        </div>
        <div class="space_divider"></div>

        <!-- Statement Viewer -->
        <app-statement-view
          *ngIf="showStatementView"
          [statement]="statement"
          [statements]="statements"
        ></app-statement-view>

        <!-- Grid Viewer -->
        <app-grid-view
          *ngIf="showGridView"
          [statement]="statement"
        ></app-grid-view>

        <app-statement-footer-paid
          [statement]="statement"
        ></app-statement-footer-paid>

        <app-statement-footer
          *ngIf="statement"
          [payment]="statement"
        ></app-statement-footer>
      </div>
    </div>
  </div>
</div>
