import { Sales } from "../model/sales";
import { deductionObjects } from "./deductionObjects";
import { products } from "./products";
import { purchasers } from "./purchasers";
import { salesDeductions } from "./sales-deductions";

export const sale1: Sales = {
  id: 1,
  operatorPaymentCode: "15644",
  salesDate: new Date('2023-01-01'),
  year: 2023,
  month: 1,
  product: products[0],
  saleVolume: 15,
  unitPrice: 1000,
  grossValue: 15000,
  netValue: 15000,
  deductionObjects: [
    deductionObjects[0]
  ],
  deductions: [
    salesDeductions[0]
  ],
  prodDate: new Date('2023-01-01'),
  fromProdDate: new Date('2023-01-01'),
  purchaser: purchasers[0],
  severance: 0
};

export const sale2: Sales = {
  id: 2,
  operatorPaymentCode: "15645",
  salesDate: new Date('2023-01-01'),
  year: 2023,
  month: 1,
  product: products[1],
  saleVolume: 15,
  unitPrice: 1000,
  grossValue: 15000,
  netValue: 15000,
  deductionObjects: [
    deductionObjects[1]
  ],
  deductions: [
    salesDeductions[1]
  ],
  prodDate: new Date('2023-01-01'),
  fromProdDate: new Date('2023-01-01'),
  purchaser: purchasers[0],
  severance: 0
};

export const sale3: Sales = {
  id: 3,
  operatorPaymentCode: "15646",
  salesDate: new Date('2023-01-01'),
  year: 2023,
  month: 1,
  product: products[2],
  saleVolume: 15,
  unitPrice: 1000,
  grossValue: 15000,
  netValue: 15000,
  deductionObjects: [
    deductionObjects[2]
  ],
  deductions: [
    salesDeductions[2]
  ],
  prodDate: new Date('2023-01-01'),
  fromProdDate: new Date('2023-01-01'),
  purchaser: purchasers[0],
  severance: 0
};

export const sale4: Sales = {
  id: 4,
  operatorPaymentCode: "15647",
  salesDate: new Date('2023-01-01'),
  year: 2023,
  month: 1,
  product: products[3],
  saleVolume: 15,
  unitPrice: 1000,
  grossValue: 15000,
  netValue: 15000,
  deductionObjects: [
    deductionObjects[3]
  ],
  deductions: [
    salesDeductions[3]
  ],
  prodDate: new Date('2023-01-01'),
  fromProdDate: new Date('2023-01-01'),
  purchaser: purchasers[0],
  severance: 0
};

export const sale5: Sales = {
  id: 5,
  operatorPaymentCode: "12546",
  salesDate: new Date('2023-01-01'),
  year: 2023,
  month: 1,
  product: products[4],
  saleVolume: 15,
  unitPrice: 1000,
  grossValue: 15000,
  netValue: 15000,
  deductionObjects: [
    deductionObjects[4]
  ],
  deductions: [
    salesDeductions[4]
  ],
  prodDate: new Date('2023-01-01'),
  fromProdDate: new Date('2023-01-01'),
  purchaser: purchasers[0],
  severance: 0
};

export const sale6: Sales = {
  id: 6,
  operatorPaymentCode: "12547",
  salesDate: new Date('2023-01-01'),
  year: 2023,
  month: 1,
  product: products[5],
  saleVolume: 15,
  unitPrice: 1000,
  grossValue: 15000,
  netValue: 15000,
  deductionObjects: [
    deductionObjects[5]
  ],
  deductions: [
    salesDeductions[5]
  ],
  prodDate: new Date('2023-01-01'),
  fromProdDate: new Date('2023-01-01'),
  purchaser: purchasers[0],
  severance: 0
};

export const sale7: Sales = {
  id: 7,
  operatorPaymentCode: "12548",
  salesDate: new Date('2023-01-01'),
  year: 2023,
  month: 1,
  product: products[6],
  saleVolume: 15,
  unitPrice: 1000,
  grossValue: 15000,
  netValue: 15000,
  deductionObjects: [
    deductionObjects[0]
  ],
  deductions: [
    salesDeductions[0]
  ],
  prodDate: new Date('2023-01-01'),
  fromProdDate: new Date('2023-01-01'),
  purchaser: purchasers[0],
  severance: 0
};

export const sale8: Sales = {
  id: 8,
  operatorPaymentCode: "18755",
  salesDate: new Date('2023-01-01'),
  year: 2023,
  month: 1,
  product: products[7],
  saleVolume: 15,
  unitPrice: 1000,
  grossValue: 15000,
  netValue: 15000,
  deductionObjects: [
    deductionObjects[1]
  ],
  deductions: [
    salesDeductions[1]
  ],
  prodDate: new Date('2023-01-01'),
  fromProdDate: new Date('2023-01-01'),
  purchaser: purchasers[0],
  severance: 0
};

export const sale9: Sales = {
  id: 9,
  operatorPaymentCode: "18756",
  salesDate: new Date('2023-01-01'),
  year: 2023,
  month: 1,
  product: products[8],
  saleVolume: 15,
  unitPrice: 1000,
  grossValue: 15000,
  netValue: 15000,
  deductionObjects: [
    deductionObjects[2]
  ],
  deductions: [
    salesDeductions[2]
  ],
  prodDate: new Date('2023-01-01'),
  fromProdDate: new Date('2023-01-01'),
  purchaser: purchasers[0],
  severance: 0
};

export const sale10: Sales = {
  id: 10,
  operatorPaymentCode: "18757",
  salesDate: new Date('2023-01-01'),
  year: 2023,
  month: 1,
  product: products[9],
  saleVolume: 15,
  unitPrice: 1000,
  grossValue: 15000,
  netValue: 15000,
  deductionObjects: [
    deductionObjects[3]
  ],
  deductions: [
    salesDeductions[3]
  ],
  prodDate: new Date('2023-01-01'),
  fromProdDate: new Date('2023-01-01'),
  purchaser: purchasers[0],
  severance: 0
};

export const sale11: Sales = {
  id: 11,
  operatorPaymentCode: "18758",
  salesDate: new Date('2023-01-01'),
  year: 2023,
  month: 1,
  product: products[0],
  saleVolume: 15,
  unitPrice: 1000,
  grossValue: 15000,
  netValue: 15000,
  deductionObjects: [
    deductionObjects[4]
  ],
  deductions: [
    salesDeductions[4]
  ],
  prodDate: new Date('2023-01-01'),
  fromProdDate: new Date('2023-01-01'),
  purchaser: purchasers[0],
  severance: 0
};

export const sale12: Sales = {
  id: 12,
  operatorPaymentCode: "18759",
  salesDate: new Date('2023-01-01'),
  year: 2023,
  month: 1,
  product: products[1],
  saleVolume: 15,
  unitPrice: 1000,
  grossValue: 15000,
  netValue: 15000,
  deductionObjects: [
    deductionObjects[5]
  ],
  deductions: [
    salesDeductions[5]
  ],
  prodDate: new Date('2023-01-01'),
  fromProdDate: new Date('2023-01-01'),
  purchaser: purchasers[0],
  severance: 0
};

export const sale13: Sales = {
  id: 13,
  operatorPaymentCode: "18760",
  salesDate: new Date('2023-01-01'),
  year: 2023,
  month: 1,
  product: products[2],
  saleVolume: 15,
  unitPrice: 1000,
  grossValue: 15000,
  netValue: 15000,
  deductionObjects: [
    deductionObjects[0]
  ],
  deductions: [
    salesDeductions[0]
  ],
  prodDate: new Date('2023-01-01'),
  fromProdDate: new Date('2023-01-01'),
  purchaser: purchasers[0],
  severance: 0
};

export const sale14: Sales = {
  id: 14,
  operatorPaymentCode: "18761",
  salesDate: new Date('2023-01-01'),
  year: 2023,
  month: 1,
  product: products[3],
  saleVolume: 15,
  unitPrice: 1000,
  grossValue: 15000,
  netValue: 15000,
  deductionObjects: [
    deductionObjects[1]
  ],
  deductions: [
    salesDeductions[1]
  ],
  prodDate: new Date('2023-01-01'),
  fromProdDate: new Date('2023-01-01'),
  purchaser: purchasers[0],
  severance: 0
};

export const sale15: Sales = {
  id: 15,
  operatorPaymentCode: "14662",
  salesDate: new Date('2023-01-01'),
  year: 2023,
  month: 1,
  product: products[4],
  saleVolume: 15,
  unitPrice: 1000,
  grossValue: 15000,
  netValue: 15000,
  deductionObjects: [
    deductionObjects[2]
  ],
  deductions: [
    salesDeductions[2]
  ],
  prodDate: new Date('2023-01-01'),
  fromProdDate: new Date('2023-01-01'),
  purchaser: purchasers[0],
  severance: 0
};

export const sale16: Sales = {
  id: 16,
  operatorPaymentCode: "14663",
  salesDate: new Date('2023-01-01'),
  year: 2023,
  month: 1,
  product: products[5],
  saleVolume: 15,
  unitPrice: 1000,
  grossValue: 15000,
  netValue: 15000,
  deductionObjects: [
    deductionObjects[3]
  ],
  deductions: [
    salesDeductions[3]
  ],
  prodDate: new Date('2023-01-01'),
  fromProdDate: new Date('2023-01-01'),
  purchaser: purchasers[0],
  severance: 0
};

export const sale17: Sales = {
  id: 17,
  operatorPaymentCode: "14664",
  salesDate: new Date('2023-01-01'),
  year: 2023,
  month: 1,
  product: products[6],
  saleVolume: 15,
  unitPrice: 1000,
  grossValue: 15000,
  netValue: 15000,
  deductionObjects: [
    deductionObjects[4]
  ],
  deductions: [
    salesDeductions[4]
  ],
  prodDate: new Date('2023-01-01'),
  fromProdDate: new Date('2023-01-01'),
  purchaser: purchasers[0],
  severance: 0
};

export const sale18: Sales = {
  id: 18,
  operatorPaymentCode: "14665",
  salesDate: new Date('2023-01-01'),
  year: 2023,
  month: 1,
  product: products[7],
  saleVolume: 15,
  unitPrice: 1000,
  grossValue: 15000,
  netValue: 15000,
  deductionObjects: [
    deductionObjects[5]
  ],
  deductions: [
    salesDeductions[5]
  ],
  prodDate: new Date('2023-01-01'),
  fromProdDate: new Date('2023-01-01'),
  purchaser: purchasers[0],
  severance: 0
};

export const sale19: Sales = {
  id: 19,
  operatorPaymentCode: "14666",
  salesDate: new Date('2023-01-01'),
  year: 2023,
  month: 1,
  product: products[8],
  saleVolume: 15,
  unitPrice: 1000,
  grossValue: 15000,
  netValue: 15000,
  deductionObjects: [
    deductionObjects[0]
  ],
  deductions: [
    salesDeductions[0]
  ],
  prodDate: new Date('2023-01-01'),
  fromProdDate: new Date('2023-01-01'),
  purchaser: purchasers[0],
  severance: 0
};

export const sale20: Sales = {
  id: 20,
  operatorPaymentCode: "14667",
  salesDate: new Date('2023-01-01'),
  year: 2023,
  month: 1,
  product: products[9],
  saleVolume: 15,
  unitPrice: 1000,
  grossValue: 15000,
  netValue: 15000,
  deductionObjects: [
    deductionObjects[1]
  ],
  deductions: [
    salesDeductions[1]
  ],
  prodDate: new Date('2023-01-01'),
  fromProdDate: new Date('2023-01-01'),
  purchaser: purchasers[0],
  severance: 0
};

export const sale21: Sales = {
  id: 21,
  operatorPaymentCode: "14668",
  salesDate: new Date('2023-01-01'),
  year: 2023,
  month: 1,
  product: products[0],
  saleVolume: 15,
  unitPrice: 1000,
  grossValue: 15000,
  netValue: 15000,
  deductionObjects: [
    deductionObjects[2]
  ],
  deductions: [
    salesDeductions[2]
  ],
  prodDate: new Date('2023-01-01'),
  fromProdDate: new Date('2023-01-01'),
  purchaser: purchasers[0],
  severance: 0
};

export const sale22: Sales = {
  id: 22,
  operatorPaymentCode: "14669",
  salesDate: new Date('2023-01-01'),
  year: 2023,
  month: 1,
  product: products[1],
  saleVolume: 15,
  unitPrice: 1000,
  grossValue: 15000,
  netValue: 15000,
  deductionObjects: [
    deductionObjects[3]
  ],
  deductions: [
    salesDeductions[3]
  ],
  prodDate: new Date('2023-01-01'),
  fromProdDate: new Date('2023-01-01'),
  purchaser: purchasers[0],
  severance: 0
};

export const sale23: Sales = {
  id: 23,
  operatorPaymentCode: "14670",
  salesDate: new Date('2023-01-01'),
  year: 2023,
  month: 1,
  product: products[2],
  saleVolume: 15,
  unitPrice: 1000,
  grossValue: 15000,
  netValue: 15000,
  deductionObjects: [
    deductionObjects[4]
  ],
  deductions: [
    salesDeductions[4]
  ],
  prodDate: new Date('2023-01-01'),
  fromProdDate: new Date('2023-01-01'),
  purchaser: purchasers[0],
  severance: 0
};

export const sale24: Sales = {
  id: 24,
  operatorPaymentCode: "14671",
  salesDate: new Date('2023-01-01'),
  year: 2023,
  month: 1,
  product: products[3],
  saleVolume: 15,
  unitPrice: 1000,
  grossValue: 15000,
  netValue: 15000,
  deductionObjects: [
    deductionObjects[5]
  ],
  deductions: [
    salesDeductions[5]
  ],
  prodDate: new Date('2023-01-01'),
  fromProdDate: new Date('2023-01-01'),
  purchaser: purchasers[0],
  severance: 0
};

export const sale25: Sales = {
  id: 25,
  operatorPaymentCode: "14672",
  salesDate: new Date('2023-01-01'),
  year: 2023,
  month: 1,
  product: products[4],
  saleVolume: 15,
  unitPrice: 1000,
  grossValue: 15000,
  netValue: 15000,
  deductionObjects: [
    deductionObjects[0]
  ],
  deductions: [
    salesDeductions[0]
  ],
  prodDate: new Date('2023-01-01'),
  fromProdDate: new Date('2023-01-01'),
  purchaser: purchasers[0],
  severance: 0
};

export const sale26: Sales = {
  id: 26,
  operatorPaymentCode: "14673",
  salesDate: new Date('2023-01-01'),
  year: 2023,
  month: 1,
  product: products[5],
  saleVolume: 15,
  unitPrice: 1000,
  grossValue: 15000,
  netValue: 15000,
  deductionObjects: [
    deductionObjects[1]
  ],
  deductions: [
    salesDeductions[1]
  ],
  prodDate: new Date('2023-01-01'),
  fromProdDate: new Date('2023-01-01'),
  purchaser: purchasers[0],
  severance: 0
};

export const sale27: Sales = {
  id: 27,
  operatorPaymentCode: "14674",
  salesDate: new Date('2023-01-01'),
  year: 2023,
  month: 1,
  product: products[6],
  saleVolume: 15,
  unitPrice: 1000,
  grossValue: 15000,
  netValue: 15000,
  deductionObjects: [
    deductionObjects[2]
  ],
  deductions: [
    salesDeductions[2]
  ],
  prodDate: new Date('2023-01-01'),
  fromProdDate: new Date('2023-01-01'),
  purchaser: purchasers[0],
  severance: 0
};

export const sale28: Sales = {
  id: 28,
  operatorPaymentCode: "14675",
  salesDate: new Date('2023-01-01'),
  year: 2023,
  month: 1,
  product: products[7],
  saleVolume: 15,
  unitPrice: 1000,
  grossValue: 15000,
  netValue: 15000,
  deductionObjects: [
    deductionObjects[3]
  ],
  deductions: [
    salesDeductions[3]
  ],
  prodDate: new Date('2023-01-01'),
  fromProdDate: new Date('2023-01-01'),
  purchaser: purchasers[0],
  severance: 0
};

export const sale29: Sales = {
  id: 29,
  operatorPaymentCode: "14676",
  salesDate: new Date('2023-01-01'),
  year: 2023,
  month: 1,
  product: products[8],
  saleVolume: 15,
  unitPrice: 1000,
  grossValue: 15000,
  netValue: 15000,
  deductionObjects: [
    deductionObjects[4]
  ],
  deductions: [
    salesDeductions[4]
  ],
  prodDate: new Date('2023-01-01'),
  fromProdDate: new Date('2023-01-01'),
  purchaser: purchasers[0],
  severance: 0
};

export const sale30: Sales = {
  id: 30,
  operatorPaymentCode: "14677",
  salesDate: new Date('2023-01-01'),
  year: 2023,
  month: 1,
  product: products[9],
  saleVolume: 15,
  unitPrice: 1000,
  grossValue: 15000,
  netValue: 15000,
  deductionObjects: [
    deductionObjects[5]
  ],
  deductions: [
    salesDeductions[5]
  ],
  prodDate: new Date('2023-01-01'),
  fromProdDate: new Date('2023-01-01'),
  purchaser: purchasers[0],
  severance: 0
};

export const sales = [
  sale1,
  sale2,
  sale3,
  sale4,
  sale5,
  sale6,
  sale7,
  sale8,
  sale9,
  sale10,
  sale11,
  sale12,
  sale13,
  sale14,
  sale15,
  sale16,
  sale17,
  sale18,
  sale19,
  sale20,
  sale21,
  sale22,
  sale23,
  sale24,
  sale25,
  sale26,
  sale27,
  sale28,
  sale29,
  sale30
];
