import { InterestType } from "../model/interestType";

export const interestType1: InterestType = {
  id: 0,
  name: "WI",
  abbr: "WI",
  operatorId: 1,
  primaryId: 0,
  cdex: ""
};

export const interestType2: InterestType = {
  id: 1,
  name: "ORRI",
  abbr: "ORRI",
  operatorId: 2,
  primaryId: 0,
  cdex: ""
};

export const interestType3: InterestType = {
  id: 2,
  name: "RI",
  abbr: "RI",
  operatorId: 3,
  primaryId: 0,
  cdex: ""
};

export const interestType4: InterestType = {
  id: 3,
  name: "NPRI",
  abbr: "NPRI",
  operatorId: 4,
  primaryId: 0,
  cdex: ""
};

export const interestType5: InterestType = {
  id: 4,
  name: "GRI",
  abbr: "GRI",
  operatorId: 5,
  primaryId: 0,
  cdex: ""
};

export const interestType6: InterestType = {
  id: 5,
  name: "NRI",
  abbr: "NRI",
  operatorId: 6,
  primaryId: 0,
  cdex: ""
};

export const interestType7: InterestType = {
  id: 6,
  name: "WI",
  abbr: "WI",
  operatorId: 7,
  primaryId: 0,
  cdex: ""
};

export const interestType8: InterestType = {
  id: 7,
  name: "ORRI",
  abbr: "ORRI",
  operatorId: 8,
  primaryId: 0,
  cdex: ""
};

export const interestType9: InterestType = {
  id: 8,
  name: "RI",
  abbr: "RI",
  operatorId: 9,
  primaryId: 0,
  cdex: ""
};

export const interestType10: InterestType = {
  id: 9,
  name: "NPRI",
  abbr: "NPRI",
  operatorId: 10,
  primaryId: 0,
  cdex: ""
};

export const interestType11: InterestType = {
  id: 10,
  name: "GRI",
  abbr: "GRI",
  operatorId: 11,
  primaryId: 0,
  cdex: ""
};

export const interestType12: InterestType = {
  id: 11,
  name: "NRI",
  abbr: "NRI",
  operatorId: 12,
  primaryId: 0,
  cdex: ""
};

export const interestType13: InterestType = {
  id: 12,
  name: "WI",
  abbr: "WI",
  operatorId: 13,
  primaryId: 0,
  cdex: ""
};

export const interestType14: InterestType = {
  id: 13,
  name: "ORRI",
  abbr: "ORRI",
  operatorId: 14,
  primaryId: 0,
  cdex: ""
};

export const interestType15: InterestType = {
  id: 14,
  name: "RI",
  abbr: "RI",
  operatorId: 15,
  primaryId: 0,
  cdex: ""
};

export const interestType16: InterestType = {
  id: 15,
  name: "NPRI",
  abbr: "NPRI",
  operatorId: 16,
  primaryId: 0,
  cdex: ""
};

export const interestType17: InterestType = {
  id: 16,
  name: "GRI",
  abbr: "GRI",
  operatorId: 17,
  primaryId: 0,
  cdex: ""
};

export const interestType18: InterestType = {
  id: 17,
  name: "NRI",
  abbr: "NRI",
  operatorId: 18,
  primaryId: 0,
  cdex: ""
};

export const interestType19: InterestType = {
  id: 18,
  name: "WI",
  abbr: "WI",
  operatorId: 19,
  primaryId: 0,
  cdex: ""
};

export const interestType20: InterestType = {
  id: 19,
  name: "ORRI",
  abbr: "ORRI",
  operatorId: 20,
  primaryId: 0,
  cdex: ""
};

export const interestType21: InterestType = {
  id: 20,
  name: "RI",
  abbr: "RI",
  operatorId: 21,
  primaryId: 0,
  cdex: ""
};

export const interestTypes: InterestType[] = [
  interestType1,
  interestType2,
  interestType3,
  interestType4,
  interestType5,
  interestType6,
  interestType7,
  interestType8,
  interestType9,
  interestType10,
  interestType11,
  interestType12,
  interestType13,
  interestType14,
  interestType15,
  interestType16,
  interestType17,
  interestType18,
  interestType19,
  interestType20,
];
