import { Injectable } from '@angular/core';
import { ProductService } from './product.service';
import { HttpService } from '../../../http.service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { Product } from '../../../model/product';

@Injectable({
  providedIn: 'root'
})
export class HttpProductService extends ProductService {

  private url: string = environment.apiUrl;

  constructor(
    private httpService: HttpService
  ) {
    super();
  }

  getProducts(): Observable<Product[]> {
    return this.httpService.get(`/mro/getMroProducts`, true);
  }
}
