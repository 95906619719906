import { DeductionObject } from './deduction';
import { DeductionType } from './deduction_type';
import { DivisionOrder } from './divisionOrder';
import { MineralRightsOwner } from './mineralRightsOwner'
import { Operator } from './operator';
import { Sales } from './sales';
import { Well } from './well';


export enum PaymentLoadStatus {
  NONE,
  FAILED,
  COMPLETE
}
export interface Payment {
  id: number;
  operator: Operator;
  paymentDate: Date;
  paymentDateString?: string;
  mineralRightsOwner?: MineralRightsOwner;
  paymentType: PaymentType;
  paymentStatus: PaymentStatus;
  checkNo: string;
  checkAmount: number;
  royaltyPayments: RoyaltyPayment[];
  insertBy: string;
  insertDate: Date;
  modifyBy: string;
  modifyDate: Date;
}

export interface PaymentType {
  id: number;
  description?: string;
  name: string;
  operatorId?: number;
  primaryId?: number;
}

export interface PaymentStatus {
  id: number;
  description?: string;
  name: string;
  operatorId?: number;
  primaryId?: number;
}

export interface PaymentDeduction {
  deductionType: DeductionType;
  amount: number;
}

export interface RoyaltyPayment {
  id: number;
  operatorPaymentCode: string;
  sale: Sales;
  divisionOrder: DivisionOrder;
  paymentDate: string;
  mroVolume: number;
  mroGrossValue: number;
  mroDeductions: number;
  mroNetValue: number;
  severance: number;
  deductionObjects: DeductionObject[];
  deductions: PaymentDeduction[];
  well: Well;
}

export interface PaymentProperty {
  id: number;
  name: string;
}

export interface PaymentProduct {
  id: number;
  name: string;
}

export const paymentsCSVHeader = [
  "Issue Date",
  "Property",
  "CheckNo",
  "DOI",
  "Prod. Date",
  "Product",
  "Price",
  "Sales Volume",
  "Sales Gross Value",
  "Sales Severance",
  "Sales Deductions",
  "Sales Net Value",
  "Interest Type",
  "Decimal",
  "Owner Volume",
  "Owner Gross Value",
  "Owner Severance",
  "Owner Deductions",
  "Subtotal"
];
