import { Injectable } from '@angular/core';
import { PaymentsService } from './payments.service';
import { Observable, of } from 'rxjs';
import { Payment } from '../../../model/payments';
import { payments } from '../../../mockDB/payments';
import { mockPaymentCount } from '../../../mockDB/payment-count';
import { PaymentSummary, PaymentSummaryByPeriodSeriesData, PaymentSummaryData, PaymentCount } from '../../../model/payment-summary';
import { mockPaymentSummaryByProduct, mockPaymentSummaryByProperty, mockPieChartDataCard } from 'src/app/mockDB/pie-chart-mock';
import { mockProductChartData, mockPropertyChartData } from 'src/app/mockDB/graph-bar-chart';

@Injectable({
  providedIn: 'root'
})
export class MockPaymentsService extends PaymentsService {

  constructor() {
    super();
  }

  getMroPayments(limit: number): Observable<Payment[]> {
    console.info('limit: ', limit);
    console.info('payments: ', payments);
    return of(payments);
  }

  getMroPaymentSummaryByProperty(fromDate: string, toDate: string, propertyId: number, productId: number): Observable<PaymentSummaryData[]> {
    console.info('fromDate: ', fromDate);
    console.info('toDate: ', toDate);
    console.info('propertyId: ', propertyId);
    console.info('productId: ', productId);

    return of(mockPaymentSummaryByProperty);
  }

  getMroPaymentSummaryByProduct(fromDate: string, toDate: string, propertyId: number, productId: number): Observable<PaymentSummaryData[]> {
    console.info('fromDate: ', fromDate);
    console.info('toDate: ', toDate);
    console.info('propertyId: ', propertyId);
    console.info('productId: ', productId);

    return of(mockPaymentSummaryByProduct);
  }

  getMroPaymentSummaryByPeriod(fromDate: string, toDate: string, propertyId: number, productId: number, breakdown: string): Observable<PaymentSummary> {
    console.info('fromDate: ', fromDate);
    console.info('toDate: ', toDate);
    console.info('propertyId: ', propertyId);
    console.info('productId: ', productId);
    console.info('breakdown: ', breakdown)

    return of(mockPieChartDataCard);
  }

  getMroPaymentSummaryByPeriodByProperty(fromDate: string, toDate: string, propertyId: number, productId: number, breakdown: string): Observable<PaymentSummaryByPeriodSeriesData[]> {
    console.info('fromDate: ', fromDate);
    console.info('toDate: ', toDate);
    console.info('propertyId: ', propertyId);
    console.info('productId: ', productId);
    console.info('breakdown: ', breakdown)

    return of(mockPropertyChartData);
  }

  getMroPaymentSummaryByPeriodByProduct(fromDate: string, toDate: string, propertyId: number, productId: number, breakdown: string): Observable<PaymentSummaryByPeriodSeriesData[]> {
    console.info('fromDate: ', fromDate);
    console.info('toDate: ', toDate);
    console.info('propertyId: ', propertyId);
    console.info('productId: ', productId);
    console.info('breakdown: ', breakdown)

    return of(mockProductChartData);
  }

  getMroPendingPaymentCount():
    Observable<PaymentCount> {
    return of(mockPaymentCount);
  };
}
