import { Product } from "../model/product";

export  const product1: Product = {
  id: 1,
  name: 'OIL',
  operatorId: 1,
  primaryId: 0
};

export  const product2: Product = {
  id: 2,
  name: 'GAS',
  operatorId: 2,
  primaryId: 0
};

export  const product3: Product = {
  id: 3,
  name: 'OTHER',
  operatorId: 3,
  primaryId: 0
};

export const product4: Product = {
  id: 4,
  name: 'COMBINATION',
  operatorId: 4,
  primaryId: 0
};

export const product5: Product = {
  id: 5,
  name: 'SPECIAL',
  operatorId: 5,
  primaryId: 0
};


export const products: Product[] = [
  product1,
  product2,
  product3,
  product4,
  product5
];
