<div id="mro_payments">
  <div class="mobile_container">
    <div id="mro_recent_payments_inner">
      <h1 class="mro_title">Recent Payments</h1>
      <div *ngIf="loading">
        <div class="mro_spinner_container">
          <app-spinner [sectionType]="'recentPayment'"></app-spinner>
        </div>
      </div>
      <div class="mro_content_inner" *ngIf="!loading && !failed">
        <ul class="payments_list">

          <li *ngFor="let payment of recentPayments">
              <div class="payments_container" *ngIf="payment != undefined">
                <a routerLink="/statement/{{payment.paymentDate | date: 'yyyyMM'}}/{{payment.id}}"
                  [ngClass]="{'disabled-link': payment.royaltyPayments.length == 0}"
                  (click)="payment.royaltyPayments.length == 0 && $event.preventDefault()"
                  class="payments_link">
                  <div class="paymnent_date">
                    {{ getUtcDateString(payment.paymentDate) }}
                  </div>
                  <div class="payment_description">{{payment.operator.name}}</div>
                  <div class="payment_value">
                    <span *ngIf="payment.checkAmount != null">$</span>
                    {{ payment?.checkAmount | number: "1.2-2":"en-US" }}
                  </div>
                  <span class="payment_status" *ngIf="payment.royaltyPayments.length == 0">Pending</span>
                </a>
            </div>
          </li>
        </ul>

        <div class="align_center">
          <button
            type="button"
            class="btn_normal btn_no_border btn_purple_link"
            (click)="viewAllPayments()"
          >
            <span class="btn_text">View All Payments</span>
            <span class="btn_icon"
              ><span class="icon_chevron_right"></span
            ></span>
          </button>
        </div>
      </div>
      <div *ngIf="!loading && failed">
        <div class="mro_error_container">
          <div class="align_center">
            <app-retry [parentType]="recent-payment"></app-retry>
          </div>
        </div>
    </div>
  </div>
</div>
