import { Deck } from "../model/deck";
import { operators } from "./operators";
import { getRandomInt } from "./helpers/randomNumbersHelper";
import { randomDateMinOrMax } from "./helpers/randomDateHelper";

export const deck1: Deck = {
  id: 1,
  operator: operators[getRandomInt(0, operators.length - 1)],
  code: "deck1",
  changeCode: "changeCode1",
  insertBy: "insertBy1",
  insertDate: randomDateMinOrMax(2019, 2020, false),
  modifyBy: "modifyBy1",
  modifyDate: randomDateMinOrMax(2020, 2023, false)
};

export const deck2: Deck = {
  id: 2,
  operator: operators[getRandomInt(0, operators.length - 1)],
  code: "deck2",
  changeCode: "changeCode2",
  insertBy: "insertBy2",
  insertDate: randomDateMinOrMax(2019, 2020, false),
  modifyBy: "modifyBy2",
  modifyDate: randomDateMinOrMax(2020, 2023, false)
};

export const deck3: Deck = {
  id: 3,
  operator: operators[getRandomInt(0, operators.length - 1)],
  code: "deck3",
  changeCode: "changeCode3",
  insertBy: "insertBy3",
  insertDate: randomDateMinOrMax(2019, 2020, false),
  modifyBy: "modifyBy3",
  modifyDate: randomDateMinOrMax(2020, 2023, false)
};

export const decks: Deck[] = [
  deck1, 
  deck2, 
  deck3
];