<div class="widget_container quater" id="widget_property_details">
  <div class="widget_inner">
    <div class="widget_header">
      <div class="widget_title">
        <h3>Property Details</h3>
      </div>
      <div class="widget_actions"></div>
    </div>
    <div class="widget_content">
      <div class="data_list_container">
        <ul class="data_list">
          <li>
          </li>
           <li>
            <div class="data_list_label">Effective From</div>
            <div class="data_list_value">
              {{ property?.effectiveFrom | date: "MMM d, yyyy" }}
            </div>
          </li>
          <li>
            <div class="data_list_label">Wells</div>
            <div class="data_list_value">
              {{ property?.wells.length }}
            </div>
          </li>
          <li>
            <div class="data_list_label">Gross Acreage</div>
            <div class="data_list_value">
              {{ property?.geographicalData?.grossAcreage }}
            </div>
          </li>
          <li>
            <div class="data_list_label">Net Acreage</div>
            <div class="data_list_value">
              {{ property?.geographicalData?.netAcreage }}
            </div>
          </li>
          <li>
            <div class="data_list_label">Section</div>
            <div class="data_list_value">
              {{ property?.geographicalData?.section }}
            </div>
          </li>
          <li>
            <div class="data_list_label">Section #</div>
            <div class="data_list_value">
              {{ property?.geographicalData?.sectionNo}}
            </div>
          </li>
          <li>
            <div class="data_list_label">Township</div>
            <div class="data_list_value">
              {{ property?.geographicalData?.township }}
            </div>
          </li>
        </ul>
        <ul class="data_list">
          <li>
            <div class="data_list_label">Range</div>
            <div class="data_list_value">
              {{ property?.geographicalData?.range }}
            </div>
          </li>
          <li>
            <div class="data_list_label">State</div>
            <div class="data_list_value">
              {{ property?.geographicalData?.county?.state?.name }}
            </div>
          </li>
          <li>
            <div class="data_list_label">County Parish</div>
            <div class="data_list_value">
              {{ property?.geographicalData?.county?.name }}
            </div>
          </li>
          <li>
            <div class="data_list_label">Survey Name</div>
            <div class="data_list_value">
              {{ property?.geographicalData?.surveyName }}
            </div>
          </li>
          <li>
            <div class="data_list_label">Abstract</div>
            <div class="data_list_value">
              {{ property?.geographicalData?.abstract }}
            </div>
          </li>
          <li>
            <div class="data_list_label">Block</div>
            <div class="data_list_value">
              {{ property?.geographicalData?.block }}
            </div>
          </li>
            <li>
              <div class="data_list_label modal-link" (click)="openModal()">Legal Description</div>
            </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<app-modal *ngIf="modalOpen">
  <div class="modal-container">
    <div class="modal-header">
      <h4 class="modal-title">Legal Description</h4>
      <button type="button" class="close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{ property.description }}</p>
    </div>
    <div class="modal-footer">
      <!-- <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button> -->
    </div>
  </div>
</app-modal>
