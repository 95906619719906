import { Component, Inject, OnInit } from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Property } from '../../model/property';
import { PropertySearch } from '../../model/property_search';
import { LookupService } from '../../core/services/lookup/lookup.service';
import { Router } from '@angular/router';
import { PropertyService } from '../../core/services/property/property.service';
import { saveAs } from 'file-saver';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.css'],
  providers: [DatePipe]
})
export class PropertiesComponent implements OnInit {

  public propertyForm: UntypedFormGroup = this.fb.group({
    searchStr: [''],
    state: ['']
  });

  public lastModified: Date;
  public tabStripVisible = false;
  public spreadBackColor = 'white';
  public hostStyle = {
    height: '100%',
    width: '100%'
  };
  public data: unknown;
  private unsubscribe = new Subject<void>();

  // Define column indexes
  public propertyDescColumn = 0;
  public propertyNoColumn = 1;
  public propertyNameColumn = 2;
  public stateColumn = 3;
  public countyColumn = 4;
  public operatorColumn = 5;
  public purchaserColumn = 6;
  public blockChainColumn = 7;
  public blockChainTransIdColumn = 8;
  public gridColCount = 9;

  public allProperties: Property[] = [];
  public properties: Property[];

  public propertySearch: PropertySearch = {
    propertyType: 0,
    searchStr: [],
    state: ''
  };

  constructor(
    @Inject(UntypedFormBuilder)
    private fb: UntypedFormBuilder,
    private propertyService: PropertyService,
    private lookupService: LookupService,
    private datePipe: DatePipe,
    private location: Location,
    private router: Router
  ) { }

  ngOnInit() {
    this.getProperties();
    this.propertySearch;
    this.propertySearch.propertyType = 0;
    this.propertySearch.searchStr = [];
    this.propertySearch.state = '';
  }


  public exportToCSV() {
    const csvData = this.convertToCSV(this.properties);
    const blob = new Blob([csvData], { type: 'text/csv' });
    saveAs(blob, 'properties.csv');
  }

  private convertToCSV(data: Property[]): string {
    const header = 'Description,Property,Name,State,County,Operator';
    const rows = data.map(property => {
        const description = property.description ? `"${property.description.replace(/"/g, '""')}"` : '';
        const propertyNo = property.propertyNo ? `"${property.propertyNo.replace(/"/g, '""')}"` : '';
        const name = property.name ? `"${property.name.replace(/"/g, '""')}"` : '';
        const state = property.geographicalData?.county?.state?.name ? `"${property.geographicalData.county.state.name.replace(/"/g, '""')}"` : '';
        const county = property.geographicalData?.county?.name ? `"${property.geographicalData.county.name.replace(/"/g, '""')}"` : '';
        const operator = property.operatorMappings && property.operatorMappings.length > 0 ? `"${property.operatorMappings[0].operator.name.replace(/"/g, '""')}"` : '';
        return `${description},${propertyNo},${name},${state},${county},${operator}`;
    });
    return `${header}\n${rows.join('\n')}`;
}

  public navigateToPropertyDetail(propertyId: number) {
    this.router.navigate(['/propertyDetail', propertyId]);
  }

  get states() {
    return this.properties.reduce((unique, property) => {
      const state = property.geographicalData?.county?.state;
      if (state && unique.findIndex(uniqueState => uniqueState.name === state.name) === -1) {
        unique.push(state);
      }
      return unique;
    }, []);
  }

  getProperties(): void {
    if (!this.properties) {
      this.properties = this.propertyService.getProperties();
      this.lastModified = this.propertyService.getLastUpdated();
      this.allProperties = this.properties;
    }
  }

  transformDate(date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }


  setPropertyValues(filteredProperties: Property[]): Property[] {
    if (Array.isArray(filteredProperties) && filteredProperties.length > 0) {
      filteredProperties.forEach(property => {
        property.geographicalData.county.state.name = property.geographicalData.county ? property.geographicalData.county.state ? property.geographicalData.county.state.name : '' : '';
        property.geographicalData.county.name = property.geographicalData.county ? property.geographicalData.county.name : '';
      });
    }
    return filteredProperties;
  }

  private filter() {
    this.properties = [];

    const tempProperties = [];

    const today = new Date();
    if (Array.isArray(this.allProperties) && this.allProperties.length > 0) {
      this.allProperties.forEach((propertyObject: Property) => {
        if (propertyObject != null && Object.keys(propertyObject).length !== 0) {
          let includeProperty = true;
          if (this.propertySearch.state !== '') {
            includeProperty = (propertyObject.geographicalData.county.state.id === Number(this.propertySearch.state));
          }
          if (includeProperty && this.propertySearch.searchStr.length > 0) {
            this.propertySearch.searchStr.forEach((searchSubStr) => {
              includeProperty = includeProperty
                && ((propertyObject.propertyNo.indexOf(searchSubStr) !== -1)
                  || (propertyObject.name.toUpperCase().indexOf(searchSubStr.toUpperCase()) !== -1)
                  || (propertyObject.description.toUpperCase().indexOf(searchSubStr.toUpperCase()) !== -1)
                  || (propertyObject.geographicalData.county.state.name.toUpperCase().indexOf(searchSubStr.toUpperCase()) !== -1)
                  || (propertyObject.geographicalData.county.name.toUpperCase().indexOf(searchSubStr.toUpperCase()) !== -1));
            });
          }

          if (this.propertySearch.propertyType > 0 && includeProperty) {
            if (this.propertySearch.propertyType === 1) {
              if (propertyObject.effectiveFrom !== null) {
                includeProperty = propertyObject.effectiveFrom >= today;
              } else {
                includeProperty = false;
              }
            } else if (this.propertySearch.propertyType === 3) {
              includeProperty = false;
            } else {
              if (propertyObject.effectiveFrom !== null) {
                if (propertyObject.effectiveFrom <= today) {
                    includeProperty = true;
                } else {
                  includeProperty = false;
                }
              } else {
                includeProperty = true;
              }
            }
          }

          if (includeProperty) {
            tempProperties.push(propertyObject);
          }
        }
      });
    }

    this.properties = [...tempProperties];

    // this.applyDataBinding(this.properties);
  }

  public doSearch(value: string) {
    if (value === '') {
      this.properties = this.allProperties;
    } else {
      const searchInput = this.propertyForm.get('searchStr').value.toLowerCase();

      this.properties = this.properties.filter(property =>
        property.propertyNo.toLowerCase().includes(searchInput) ||
        property.name.toLowerCase().includes(searchInput)
      );
    }
  }

  public onStateChange() {
    this.propertySearch.state = this.propertyForm.get('state').value;
    this.filter();
  }

  public btnAllProperties() {
    this.propertySearch.propertyType = 0;
    this.filter();
  }

  public btnInProgressProperties() {
    this.propertySearch.propertyType = 1;
    this.filter();
  }

  public btnActiveProperties() {
    this.propertySearch.propertyType = 2;
    this.filter();
  }

  public btnInactiveProperties() {
    this.propertySearch.propertyType = 3;
    this.filter();
  }

  public onBack() {
    this.location.back();
  }
}
