<div id="mro_content">
  <div class="mobile_container">
    <div id="mro_recent_payments_inner">
      <h1 class="mro_title">Contact Us</h1>
      <div class="intro_text">
        <div class="fine_print">
        </div>
      </div>

      <!-- <div class="mro_content_inner">
        <div class="drawer_container">
          <div class="drawer_header">
            <button
              type="button"
              class="btn_normal btn_drawer"
              id="faq_1"
              (click)="drawerToggle('faq_1')"
            >
              <span class="btn_icon"
                ><span class="icon_chevron_right"></span
              ></span>
              <span class="btn_text">What to expect after verifications</span>
            </button>
          </div>
          <div class="drawer_content" id="faq_1_container">content</div>
        </div>

        <div class="drawer_container">
          <div class="drawer_header">
            <button
              type="button"
              class="btn_normal btn_drawer"
              id="faq_2"
              (click)="drawerToggle('faq_2')"
            >
              <span class="btn_icon"
                ><span class="icon_chevron_right"></span
              ></span>
              <span class="btn_text">How is the oil valued and sold?</span>
            </button>
          </div>
          <div class="drawer_content" id="faq_2_container">content</div>
        </div>

        <div class="drawer_container">
          <div class="drawer_header">
            <button
              type="button"
              class="btn_normal btn_drawer"
              id="faq_3"
              (click)="drawerToggle('faq_3')"
            >
              <span class="btn_icon"
                ><span class="icon_chevron_right"></span
              ></span>
              <span class="btn_text">How is the oil measured?</span>
            </button>
          </div>
          <div class="drawer_content" id="faq_3_container">content</div>
        </div>

        <div class="drawer_container">
          <div class="drawer_header">
            <button
              type="button"
              class="btn_normal btn_drawer"
              id="faq_4"
              (click)="drawerToggle('faq_4')"
            >
              <span class="btn_icon"
                ><span class="icon_chevron_right"></span
              ></span>
              <span class="btn_text"
                >Are my royalties subject to deductions?</span
              >
            </button>
          </div>
          <div class="drawer_content" id="faq_4_container">content</div>
        </div>

        <div class="drawer_container">
          <div class="drawer_header">
            <button
              type="button"
              class="btn_normal btn_drawer"
              id="faq_5"
              (click)="drawerToggle('faq_5')"
            >
              <span class="btn_icon"
                ><span class="icon_chevron_right"></span
              ></span>
              <span class="btn_text"
                >What about oil and gas price fluctuations?</span
              >
            </button>
          </div>
          <div class="drawer_content" id="faq_5_container">content</div>
        </div>

        <div class="drawer_container">
          <div class="drawer_header">
            <button
              type="button"
              class="btn_normal btn_drawer"
              id="faq_6"
              (click)="drawerToggle('faq_6')"
            >
              <span class="btn_icon"
                ><span class="icon_chevron_right"></span
              ></span>
              <span class="btn_text">What about taxes?</span>
            </button>
          </div>
          <div class="drawer_content" id="faq_6_container">content</div>
        </div>
      </div> -->

      <div class="space_divider extra_padding"></div>

      <h2 class="mro_title">Ask a Question</h2>
      <div class="intro_text">
        <div class="fine_print">
          Our wealth managers will do our best to answer your questions.
        </div>
      </div>
      <div class="mro_content_inner">
        <div class="form_row">
          <div class="form_label">
            <label for="question">Enter your question here:</label>
          </div>
          <div class="form_input">
            <textarea
              placeholder="Enter Question"
              class="inputbox"
              id="question"
              name="question"
              style="height: 500px"
              (focus)="setCursorPosition($event)"
            ></textarea>
          </div>
        </div>

        <div class="mro_actions full">
          <div class="mro_actions_left">
            <ul class="options_list">
              <li></li>

              <li></li>
            </ul>
          </div>
          <div class="mro_actions_right">
            <ul class="options_list">
              <li>
                <button
                  type="button"
                  class="btn_normal btn_purple btn_big btn_wide"
                >
                  <span class="btn_text">Submit</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
