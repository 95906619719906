import { Injectable } from '@angular/core';
import { PerformanceService } from './performance.service';
import { Observable, of } from 'rxjs';
import { performances } from '../../../mockDB/performances';
import { MroPerformance, PropertyPerformance } from '../../../model/performance';
import { propertyPerformanceData } from '../../../mockDB/propertyPerformanceData';

@Injectable({
  providedIn: 'root'
})
export class MockPerformanceService extends PerformanceService {

  constructor() {
    super();
   }

   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   getMROPerformance(periodStart: Date, periodEnd: Date): Observable<MroPerformance[]> {
    return of(performances)
  }

  getPropertyPerformance(propertyId: number): Observable<PropertyPerformance[]> {
    console.info('Property Id: ', propertyId)
    return of(propertyPerformanceData)
  }
}

