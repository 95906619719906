<div id="mro_content">
  <div class="mobile_container">
    <div id="mro_recent_payments_inner">
      <h1 class="mro_title">Smart Division Orders&trade;</h1>

      <div class="mro_content_inner">
        <p>Screen Layout still to be decided upon</p>
        <p>
          <a routerLink="/divisionOrderDetails">View Smart Division Order</a>
        </p>
      </div>
    </div>
  </div>
</div>
