import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { AuthService } from '../../core/services/auth/auth.service';
import { Router } from '@angular/router';
import { PartnerService } from '../../core/services/partner/partner.service';
import { Partner } from '../../model/partner';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public isAdminLoggedIn = false;
  public partnerData: Partner | null = null;
  public menuOpen: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  public globalClickListener: Function;

  constructor(
    private renderer: Renderer2,
    private authService: AuthService,
    private router: Router,
    public partnerService: PartnerService,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    this.globalClickListener = this.renderer.listen('document', 'click', (event) => {
      if (!this.elementRef.nativeElement.contains(event.target)) {
        this.closeMenu();
      }
    });
    this.getPartnerData();
  }

  getPartnerData() {
    this.partnerData = this.partnerService.getPartnerData();
  }

  toggleNavbar() {
    this.menuOpen = !this.menuOpen;
  }

  public openMenu() {
    const menuContainer = document.getElementById('mro_menu_container');
    this.menuOpen = !this.menuOpen;

    if (menuContainer) {
      menuContainer.style.display = this.menuOpen ? 'block' : 'none';
    }
  }

  public closeMenu() {
    const menuContainer = document.getElementById('mro_menu_container');
    this.menuOpen = false;
    if (menuContainer) {
      menuContainer.style.display = 'none';
    }
  }

  public navigateToProfile() {
    this.router.navigate(['/profile']);
  }

  get loggedIn() {
    if (sessionStorage.getItem('user') !== null && this.router.url !== '/login') {
      return true;
    } else {
      return false;
    }
  }

  public logout() {
    this.authService.logout();
  }

}
