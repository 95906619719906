<div class="mobile_container">
  <div id="mro_footer_inner">
    <div id="footer_legal">
      &copy; {{ copyrightYear }} NuArca OG<br />All Rights Reserved
    </div>
    <div id="footer_logo">
      <img src="../../assets/images/powered_by_pipeline@2x.png" alt="Powered by PipelineDL" />
    </div>
  </div>
</div>
