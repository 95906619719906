<div [formGroup]="statementForm">
  <div class="form_row no_padding">
    <div class="form_label hidden_label">
      <label for="operator_list">Operators</label>
    </div>
    <div class="form_input">
      <select
        class="inputbox inputbox_filter"
        id="operator_list"
        name="operator_list"
        formControlName="operatorId"
        (change)="onOperatorChange()"
      >
        <option value="">All Operators</option>
        <option
          *ngFor="let operator of operators | keyvalue"
          value="{{ operator.id }}"
        >
          {{ operator.name }}
        </option>
      </select>
    </div>
  </div>
</div>
