<div class="pagination-carousel">
  <div class="mobile_container">
    <div class="dropdowns">
      <div class="dropdown-container">
        <div class="row mt-2">
          <div class="col-md-3">
            <div class="form-group">
              <label for="fromDate">From Date</label>
              <input
                type="date"
                id="fromDate"
                class="form-control"
                placeholder="From Date"
                [value]="fromDate"
                (change)="onFromDateChange($event)"
                [max]="toDate"
                lang="en-US"
                (keydown)="$event.preventDefault()"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="toDate">To Date</label>
              <input
                type="date"
                id="toDate"
                class="form-control"
                placeholder="To Date"
                [value]="toDate"
                (change)="onToDateChange($event)"
                lang="en-US"
                [max]="today"
                [min]="fromDate"
                (keydown)="$event.preventDefault()"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="propertyName">Property Name</label>
              <select
                class="form-control"
                id="propertyName"
                (change)="onPropertyNameChange($event.target.value)"
              >
                <option value="-1">All</option>
                <option
                  *ngFor="let property of properties"
                  [value]="property.id"
                >
                  {{ property.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="productType">Product Type</label>
              <select
                class="form-control"
                id="productType"
                (change)="onProductTypeChange($event.target.value)"
              >
                <option value="-1">All</option>
                <option *ngFor="let product of products" [value]="product.id">
                  {{ product.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Page 1 -->
  <div class="page" *ngIf="currentPage === 0">
    <div id="mro-dashboard-component">
      <div class="mobile_container">
        <div id="mro-component-inner">
          <div id="mro-content">
            <hr />
            <div class="row mt-1">
              <div class="col-md-6">
                <h5>Summary</h5>
              </div>
              <div class="col-md-6">
                <div class="toggle_group">
                  <div
                    class="toggle_group_option"
                    (click)="updateBreakdown('M')"
                    [ngClass]="{ selected: breakdown === 'M' }"
                  >
                    <label for="charts_monthly">Monthly</label>
                  </div>
                  <div
                    class="toggle_group_option"
                    (click)="updateBreakdown('Q')"
                    [ngClass]="{ selected: breakdown === 'Q' }"
                  >
                    <label for="charts_quarterly">Quarterly</label>
                  </div>
                  <div
                    class="toggle_group_option"
                    (click)="updateBreakdown('A')"
                    [ngClass]="{ selected: breakdown === 'A' }"
                  >
                    <label for="charts_annually">Annually</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <h5>Net Revenue</h5>
                <app-vertical-bar-chart
                  [sectionType]="'payment'"
                ></app-vertical-bar-chart>
              </div>
              <div class="col-md-6" style="margin-top: 40px">
                <app-data-card [sectionType]="'barchart'"></app-data-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Page 2 -->
  <div class="page" *ngIf="currentPage === 1">
    <div id="mro-dashboard-component">
      <div class="mobile_container">
        <div id="mro-component-inner">
          <div id="mro-content">
            <hr />
            <div class="row mt-1">
              <div class="col-md-6">
                <h5>Summary</h5>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="header">
                  <h5>Net Revenue by Property</h5>
                </div>

                <app-pie-chart
                  [sectionType]="'property'"
                  [isDateRange]="isDateRange"
                ></app-pie-chart>
              </div>

              <div class="col-md-6">
                <div>
                  <h5 style="margin-left: 50px">Net Revenue by Product</h5>
                  <div class="col-md-12">
                    <app-pie-chart
                      [sectionType]="'product'"
                      [isDateRange]="isDateRange"
                    ></app-pie-chart>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Page 3 -->
  <div class="page" *ngIf="currentPage === 2">
    <div id="mro-dashboard-component">
      <div class="mobile_container">
        <div id="mro-component-inner">
          <div id="mro-content">
            <hr />
            <div class="row mt-1">
              <div class="col-md-6">
                <h5>Summary</h5>
              </div>
              <div class="col-md-6">
                <div class="toggle_group">
                  <div
                    class="toggle_group_option"
                    (click)="updateBreakdown('M')"
                    [ngClass]="{ selected: breakdown === 'M' }"
                  >
                    <label for="charts_monthly">Monthly</label>
                  </div>
                  <div
                    class="toggle_group_option"
                    (click)="updateBreakdown('Q')"
                    [ngClass]="{ selected: breakdown === 'Q' }"
                  >
                    <label for="charts_quarterly">Quarterly</label>
                  </div>
                  <div
                    class="toggle_group_option"
                    (click)="updateBreakdown('A')"
                    [ngClass]="{ selected: breakdown === 'A' }"
                  >
                    <label for="charts_annually">Annually</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <h5>Net Revenue by Property</h5>
                <app-graph-chart
                  [sectionType]="'revenueByProperty'"
                  [breakdown]="breakdown"
                ></app-graph-chart>
              </div>

              <div class="col-md-6">
                <div>
                  <h5 style="margin-left: 50px">Net Revenue by Product</h5>
                  <div class="col-md-12">
                    <app-graph-chart
                      [sectionType]="'revenueByProduct'"
                      [breakdown]="breakdown"
                    ></app-graph-chart>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Page 4 -->
  <!-- <div class="page" *ngIf="currentPage === 3">
    <div id="mro-dashboard-component">
      <div class="mobile_container">
        <div id="mro-component-inner">
          <div id="mro-content">
            <hr>
            <div class="row mt-1">
              <div class="col-md-6">
                <h5>Summary</h5>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <h5>Payments</h5>
                <app-pie-chart [sectionType]="'payment'" [isDateRange]="isDateRange"></app-pie-chart>
              </div>
              <div class="col-md-6" style=" margin-top: 40px;">
                <app-data-card [sectionType]="'payment'"></app-data-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <!-- Pagination dots -->
  <div class="pagination-dots">
    <span
      class="pagination-arrow left"
      (click)="goToPreviousPage()"
      disabled
      [attr.disabled]="currentPage === 0"
    >
      <i class="arrow-shape"></i>
    </span>
    <span
      class="dot"
      *ngFor="let page of [0, 1, 2]"
      [ngClass]="{ active: currentPage === page }"
      (click)="goToPage(page)"
    ></span>
    <span
      class="pagination-arrow right"
      (click)="goToNextPage()"
      disabled
      [attr.disabled]="currentPage === this.totalPages - 1"
    >
      <i class="arrow-shape"></i>
    </span>
  </div>
</div>
