/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = 'eyJhbGciOiJSUzI1NiIsImtpZCI6Ilg1ZVhrNHh5b2pORnVtMWtsMll0djhkbE5QNC1jNTdkTzZRR1RWQndhTmsiLCJ0eXAiOiJKV1QifQ.eyJ2ZXIiOiIxLjAiLCJpc3MiOiJodHRwczovL3BpcGVsaW5lZGxkZXYuYjJjbG9naW4uY29tLzBlZTdhODVjLWI2NjAtNGYyNi1hYWRlLWRmOTAzMGQwYzIzZi92Mi4wLyIsInN1YiI6ImEzM2ZiMDFhLWEwN2MtNDM1OC04MjU4LTNlYWI3NDJiYjI2OSIsImF1ZCI6IjE1ZjE5OTk2LTMwMmUtNDY3Zi1hMWZhLTc0ZTg3ODY3NjZmNiIsImV4cCI6MTcxMzI4MTQwNiwibm9uY2UiOiIwMThlZTc1MC03Zjc0LTdjODktYjQ2MC1mNmU3Y2JlMDhiMWQiLCJpYXQiOjE3MTMyNzc4MDYsImF1dGhfdGltZSI6MTcxMzI3NzgwMCwib2lkIjoiYTMzZmIwMWEtYTA3Yy00MzU4LTgyNTgtM2VhYjc0MmJiMjY5IiwiZ2l2ZW5fbmFtZSI6Ik93ZW4iLCJmYW1pbHlfbmFtZSI6IlNoZWVoYW4iLCJlbWFpbHMiOlsiT3dlbkBudWFyY2EuY29tIl0sInRmcCI6IkIyQ18xX3NpZ25faW4iLCJuYmYiOjE3MTMyNzc4MDZ9.QDR3FRi4qvRUpDAO8MZSiBUv59L1RidJUFc03AsLPWMbZlOhF5MFHFLvedFAarxvvj1LjhfQ90mj97FbyYXKC_rXf_J6IlMyFF8w4nmqicLbOKXs5wkOOM82orQFb9K2ydKLzGaPWhTWtqkE1Fr2hAyfv_5p5eLl2nLDmM0OstM6yQ5VZOxDIS655xV29SlVMUH8-zpSBDY3Wek8lyQtJ3koMCZUPIT7vCnj-e_dhoRJ--D3kgQl0q-Pw5C9Ffh4MHKm5paJKX4oxJlI1VMAvCak5aWXpk3mDIqQZFYgtuKSfhm8aB5st6-lydjSCVXsKebC6Jzlk3rh9lDvUnDmUg'; // Get token from your service
    if (authToken) {
      const authReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${authToken}`)
      });
      return next.handle(authReq);
    }
    return next.handle(req);
  }
}
