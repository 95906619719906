import { Purchaser } from './../model/purchaser';

export  const purchaser1: Purchaser = {
  id: 1,
  name: 'Purchaser 1'
};

export  const purchaser2: Purchaser = {
  id: 2,
  name: 'Purchaser 2'
};

export  const purchaser3: Purchaser = {
  id: 3,
  name: 'Purchaser 3'
};

export  const purchaser4: Purchaser = {
  id: 4,
  name: 'Purchaser 4'
};

export  const purchaser5: Purchaser = {
  id: 5,
  name: 'Purchaser 5'
};

export  const purchaser6: Purchaser = {
  id: 6,
  name: 'Purchaser 6'
};

export  const purchaser7: Purchaser = {
  id: 7,
  name: 'Purchaser 7'
};

export  const purchaser8: Purchaser = {
  id: 8,
  name: 'Purchaser 8'
};

export  const purchaser9: Purchaser = {
  id: 9,
  name: 'Purchaser 9'
};

export const purchasers: Purchaser[] = [
  purchaser1,
  purchaser2,
  purchaser3,
  purchaser4,
  purchaser5,
  purchaser6,
  purchaser7,
  purchaser8,
  purchaser9
];
